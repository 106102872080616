import { Box, Container, Typography } from '@mui/material';
import CategoryGroup from './CategoryGroup';

export default function ProductCategories({ groupedCategories }) {
  return (
    <Container>
      <Typography component="h1" display="flex" flexDirection="column" mt={4}>
        <Typography
          variant="h4"
          fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
          component="span"
          fontWeight="bold"
          textTransform="uppercase"
          textAlign="center"
          mb={{ xs: 2, md: 2.5 }}
        >
          Top Energy Products
        </Typography>
        <Typography variant="h2" component="span" mb={2} textAlign="center">
          Shop only the most energy efficient products in your price range.
        </Typography>
      </Typography>
      <Typography fontSize={{ xs: '0.875rem', sm: '1.125rem' }} component="p" textAlign="center">
        All products are{' '}
        <Typography
          fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
          component="span"
          fontWeight="bold"
          color="blue.400"
        >
          ENERGY STAR<sup>®</sup>
        </Typography>{' '}
        certified where applicable.
      </Typography>

      <Box mt={3} mb={5}>
        {groupedCategories.map((group) => (
          <CategoryGroup categoryGroup={group} key={group.id} />
        ))}
      </Box>
    </Container>
  );
}
