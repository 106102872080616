import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import Filter from './Filter';
import { removeQueryParam, replaceQueryParam } from '../utils/urlFunctions';
import { generateFiltersUrl, resetFiltersData } from '../utils/filtersFunctions';
import { router, usePage } from '@inertiajs/react';
import Button from './Button';
import { CloseIcon } from '../assets/icons/icons';
import { cloneDeep } from 'lodash';
import { useState } from 'react';
import MobileFiltersButtons from './MobileFiltersButtons';
import useDeepCompareEffect from '../hooks/useDeepCompareEffect';
import VisuallyHiddenText from './VisuallyHiddenText';
import useAriaLiveStatus from '../hooks/useAriaLiveStatus';

function FilterRail({
  filtersData: serverFiltersData,
  isFullScreen,
  onClose,
  standaloneFiltersData = [], // for UI components that look/act like filters, but don't affect the `filters` query param in the standard way
}) {
  const { url } = usePage();

  // local version of server data so UI interactions aren't held up by server delays
  // once the server responds, this state is re-synced with the new server data via useEffect
  const [filtersData, setFiltersData] = useState(cloneDeep(serverFiltersData));

  // visually hidden filter status message for screen readers
  const [ariaLiveStatus, setAriaLiveStatus] = useAriaLiveStatus();

  useDeepCompareEffect(() => {
    setFiltersData(cloneDeep(serverFiltersData));
  }, [serverFiltersData]);

  const applyFilters = (newFiltersData) => {
    const filtersUrl = generateFiltersUrl(newFiltersData);

    const newUrl = filtersUrl
      ? replaceQueryParam(url, 'filters', filtersUrl)
      : removeQueryParam(url, 'filters');

    const newPageUrl = replaceQueryParam(newUrl, 'page', 1);

    router.visit(newPageUrl, {
      preserveScroll: true,
      preserveState: true,
    });
  };

  const handleFilterChange = (newFiltersData) => {
    setFiltersData(newFiltersData);

    setAriaLiveStatus('');

    // if in full screen view, don't apply filters on interaction, only when "Update" button is clicked
    if (!isFullScreen) {
      applyFilters(newFiltersData);
    }
  };

  // only called in full screen filters modal, applies batched changes all at once
  const handleUpdateClick = () => {
    applyFilters(filtersData);
    onClose();
  };

  const handleCheckboxChange = (filterId, itemId, checked) => {
    const filtersDataCopy = cloneDeep(filtersData);

    const filter = filtersDataCopy.find(({ id }) => id === filterId);
    const filterItem = filter.items.find(({ id }) => id === itemId);
    filterItem.checked = checked;

    handleFilterChange(filtersDataCopy);
  };

  const handleSliderChange = (filterId, [min, max]) => {
    const filtersDataCopy = cloneDeep(filtersData);

    const filter = filtersDataCopy.find(({ id }) => id === filterId);
    filter.value_min = min;
    filter.value_max = max;

    handleFilterChange(filtersDataCopy);
  };

  // set all filter items to default
  const handleResetClick = () => {
    const resetFilters = resetFiltersData(filtersData);

    applyFilters(resetFilters);

    setAriaLiveStatus('Resetting filters, showing all results');

    if (onClose) {
      onClose();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      width="100%"
      data-testid="filter-rail"
    >
      <Box
        pb={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px solid"
        borderColor="gray.300"
      >
        <Typography
          variant="h6"
          component="h2"
          fontSize={{ xs: '1.125rem', md: '0.875rem' }}
          fontWeight="bold"
          textTransform="uppercase"
          id={isFullScreen ? 'filter-rail-heading' : ''}
        >
          Filter
        </Typography>
        {isFullScreen ? (
          <Button onClick={onClose} sx={{ minWidth: 'auto' }} aria-label="Close filters">
            <CloseIcon />
          </Button>
        ) : (
          <Button
            onClick={handleResetClick}
            aria-label="Reset all filters"
            sx={{
              fontWeight: 'bold',
              color: 'blue.500',
              p: 0,
            }}
          >
            Reset all
          </Button>
        )}
      </Box>

      <Box mb={{ xs: 14, md: 0 }}>
        {standaloneFiltersData.map((filter) => (
          <Filter
            key={filter.filterData.id}
            filterData={filter.filterData}
            onCheckboxChange={filter.onCheckboxChange}
            onSliderChange={filter.onSliderChange}
            truncatable={filter.truncatable}
          />
        ))}

        {filtersData.map((filter) => (
          <Filter
            key={filter.id}
            filterData={filter}
            onCheckboxChange={handleCheckboxChange}
            onSliderChange={handleSliderChange}
          />
        ))}
      </Box>

      <MobileFiltersButtons handleReset={handleResetClick} handleUpdate={handleUpdateClick} />

      <VisuallyHiddenText aria-live="polite" role="status">
        {ariaLiveStatus}
      </VisuallyHiddenText>
    </Box>
  );
}

FilterRail.propTypes = {
  filtersData: PropTypes.array,
  isFullScreen: PropTypes.bool,
  onClose: PropTypes.func,
  standaloneFiltersData: PropTypes.array,
};

export default FilterRail;
