import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import Container from '../../layout/Container';
import TopProducts from './TopProducts';
import TopResources from './TopResources';
import useDetailsDrawer from '../../hooks/useDetailsDrawer';
import DetailsDrawer from '../../components/DetailsDrawer';

function TopResults({ categories, selectedCategory, resources }) {
  const {
    isOpen: isProductDrawerOpen,
    onOpen: onOpenProductDrawer,
    onClose: onCloseProductDrawer,
    detailItem: product,
  } = useDetailsDrawer();

  const {
    isOpen: isResourceDrawerOpen,
    onOpen: onOpenResourceDrawer,
    onClose: onCloseResourceDrawer,
    detailItem: resource,
  } = useDetailsDrawer('resource', 'resource_id');

  return (
    <>
      <Box backgroundColor="gray.100">
        <Container maxWidth="md">
          <Box py={{ xs: 5, md: 9 }} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h2" fontWeight="bold" mb={2} textAlign="center">
              Top energy saving products
            </Typography>
            <Typography
              fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
              component="span"
              mb={{ xs: 4, md: 5 }}
              textAlign="center"
            >
              All products are{' '}
              <Typography
                component="span"
                fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
                fontWeight="bold"
                color="blue.400"
              >
                ENERGY STAR<sup>®</sup>
              </Typography>{' '}
              certified where applicable.
            </Typography>

            <TopProducts
              categories={categories}
              selectedCategory={selectedCategory}
              onOpenProductDrawer={onOpenProductDrawer}
            />

            <Typography
              variant="h2"
              fontWeight="bold"
              mt={10}
              mb={{ xs: 4, md: 7 }}
              textAlign="center"
            >
              Resources to help you save
            </Typography>

            <TopResources resources={resources} onOpenResourceDrawer={onOpenResourceDrawer} />
          </Box>
        </Container>
      </Box>

      <DetailsDrawer
        isOpen={isProductDrawerOpen}
        onClose={onCloseProductDrawer}
        detailItem={product}
        onShowResource={onOpenResourceDrawer}
      />

      <DetailsDrawer
        isOpen={isResourceDrawerOpen}
        onClose={onCloseResourceDrawer}
        detailItem={resource}
        onShowProduct={onOpenProductDrawer}
        isProduct={false}
      />
    </>
  );
}

TopResults.propTypes = {
  categories: PropTypes.array,
  selectedCategory: PropTypes.object,
  resources: PropTypes.array,
};

export default TopResults;
