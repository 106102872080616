import PropTypes from 'prop-types';
import { Box, Dialog, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import Button from './Button';
import FilterRail from './FilterRail';
import { FilterIcon } from '../assets/icons/icons';

function MobileFilters({ filtersData, standaloneFiltersData, variant = 'sticky' }) {
  const theme = useTheme();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      {variant === 'sticky' && (
        <Button
          onClick={() => setIsDialogOpen(true)}
          sx={{
            position: 'fixed',
            bottom: 20,
            right: 20,
            display: { xs: 'block', md: 'none' },
            height: 56,
            width: 56,
            backgroundColor: 'orange.500',
            borderRadius: '50%',
            minWidth: 'auto',
            textTransform: 'none',
            boxShadow: theme.shadows[10],
            '&:hover': {
              backgroundColor: 'orange.300',
            },
          }}
        >
          <Typography variant="body3" color="common.black" fontWeight="bold">
            Filter
          </Typography>
        </Button>
      )}

      {variant === 'static' && (
        <Button
          sx={{ display: 'flex', alignItems: 'center', minWidth: 'auto', py: 0.5 }}
          onClick={() => setIsDialogOpen(true)}
        >
          <FilterIcon />
          <Typography
            fontSize="0.875rem"
            textTransform="uppercase"
            ml={1.5}
            color="common.black"
            fontWeight="bold"
          >
            Filter
          </Typography>
        </Button>
      )}

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        fullScreen
        aria-labelledby="filter-rail-heading"
      >
        <Box p={4}>
          <FilterRail
            isFullScreen
            onClose={() => setIsDialogOpen(false)}
            filtersData={filtersData}
            standaloneFiltersData={standaloneFiltersData}
          />
        </Box>
      </Dialog>
    </>
  );
}

MobileFilters.propTypes = {
  filtersData: PropTypes.array,
  standaloneFiltersData: PropTypes.array,
  variant: PropTypes.string,
};

export default MobileFilters;
