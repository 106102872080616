import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useCallback } from 'react';
import Imgix, { buildURL } from 'react-imgix';

function Jumbotron({ bgImage, darken, bgHeight = 472, children }) {
  const [isLoading, setIsLoading] = useState(true);
  const imgixParams = {
    auto: 'format',
    dpr: window.devicePixelRatio,
    high: darken ? '-80' : '0',
    exp: darken ? '-20' : '0',
  };
  const blurUrl = buildURL(bgImage, { ...imgixParams, blur: 80, width: 600, q: 45 });

  const onImgixMounted = useCallback((imgRef) => {
    imgRef.addEventListener('load', () => {
      setIsLoading(false);
    });
  }, []);

  return (
    <Box
      width="100%"
      data-testid="jumbotron"
      sx={{
        height: { xs: bgHeight, sm: 'auto' },
        minHeight: { sm: 'calc(100vh - 88px)' },
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          pointerEvents: 'none',
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: '-3',
          objectFit: 'cover',
          background: 'linear-gradient(#042037, #07679B)',
        }}
      />
      <Box
        component={Imgix}
        src={bgImage}
        display="flex"
        alignItems="center"
        sizes="100vw"
        htmlAttributes={{ alt: '' }}
        imgixParams={imgixParams}
        onMounted={onImgixMounted}
        sx={{
          pointerEvents: 'none',
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: '-2',
          objectFit: 'cover',
          opacity: isLoading ? 0 : 1,
        }}
      />
      <Box
        component="img"
        src={blurUrl}
        alt=""
        sx={{
          pointerEvents: 'none',
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: '-1',
          objectFit: 'cover',
          opacity: isLoading ? 1 : 0,
          transition: 'opacity 0.3s linear',
        }}
      />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          height: { xs: bgHeight, sm: 'auto' },
          minHeight: { sm: 'calc(100vh - 88px)' },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

Jumbotron.propTypes = {
  bgImage: PropTypes.string.isRequired,
  darken: PropTypes.bool,
  children: PropTypes.node,
};

export default Jumbotron;
