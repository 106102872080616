import { usePage, router } from '@inertiajs/react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { CloseIcon } from '../../assets/icons/icons';
import Button from '../Button';
import ButtonLink from '../ButtonLink';
import CompareFooterProduct from './CompareFooterProduct';
import MobileCompareFooter from './MobileCompareFooter';
import VisuallyHiddenText from '../VisuallyHiddenText';
import useAriaLiveStatus from '../../hooks/useAriaLiveStatus';

export default function CompareFooter() {
  const {
    props: { compare_products: compareProducts = [] },
  } = usePage();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [ariaLiveStatus, setAriaLiveStatus] = useAriaLiveStatus();

  const handleDelete = (id) => {
    router.delete(`/compared_products/${id}`, {
      preserveScroll: true,
      preserveState: true,
      headers: {
        'X-CSRF-Token': axios.defaults.headers.common['X-CSRF-Token'],
      },
      onSuccess: () => setAriaLiveStatus(`Removed product from compare`),
    });
  };

  const handleClose = () => {
    router.delete('/compare', {
      preserveScroll: true,
      preserveState: true,
      headers: {
        'X-CSRF-Token': axios.defaults.headers.common['X-CSRF-Token'],
      },
    });
  };

  const compareLink =
    '/compare?' + compareProducts.map(({ id }) => `product_ids[]=${id}`).join('&');

  if (compareProducts.length === 0) return null;

  if (isSmallScreen)
    return (
      <MobileCompareFooter
        products={compareProducts}
        onDeleteProduct={handleDelete}
        onReset={handleClose}
        compareLink={compareLink}
      />
    );

  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      width="100%"
      bgcolor="common.white"
      display="flex"
      justifyContent="space-between"
      boxShadow={10}
      py={2}
      px={{ xs: 2, md: 4, lg: 6 }}
    >
      <Button
        onClick={handleClose}
        aria-label="Close comparison menu"
        sx={{
          minWidth: 'auto',
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <CloseIcon />
      </Button>
      <Typography fontWeight={500} display="flex" alignItems="center" mr={3}>
        Compare up to 3 products
      </Typography>
      <Box display="flex">
        <VisuallyHiddenText aria-live="polite" role="status">
          {ariaLiveStatus}
        </VisuallyHiddenText>
        {compareProducts.map((product) => (
          <CompareFooterProduct
            key={product.id}
            product={product}
            onDelete={() => handleDelete(product.id)}
          />
        ))}
      </Box>
      <ButtonLink
        href={compareLink}
        variant="secondaryFilled"
        sx={{ alignSelf: 'center', px: { xs: 2, lg: 4 }, mr: { xs: 2, lg: 4 } }}
        disabled={compareProducts.length < 2}
        aria-label="Compare selected products"
      >
        Compare
      </ButtonLink>
    </Box>
  );
}
