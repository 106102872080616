import { Box, Container, Dialog, Typography, useTheme } from '@mui/material';
import React from 'react';
import { CloseIcon } from '../../assets/icons/icons';
import Button from '../Button';
import ButtonLink from '../ButtonLink';
import CompareFooterProduct from './CompareFooterProduct';

export default function MobileCompareDialog({
  isOpen,
  onClose,
  products,
  onDeleteProduct,
  onReset,
  compareLink,
}) {
  const theme = useTheme();

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen aria-labelledby="compare-dialog-heading">
      <Container sx={{ my: 1, px: 3, display: 'flex', flexDirection: 'column' }}>
        <Button
          onClick={onClose}
          sx={{ minWidth: 'auto', alignSelf: 'flex-end' }}
          aria-label="Close compare"
        >
          <CloseIcon />
        </Button>
        <Typography variant="h4" fontSize="1.125rem" id="compare-dialog-heading" fontWeight="bold">
          Compare up to 3 products
        </Typography>
        <Box mt={5} display="flex" flexDirection="column">
          {products.map((product) => (
            <Box key={product.id} alignSelf="flex-start" mb={3}>
              <CompareFooterProduct
                product={product}
                onDelete={() => onDeleteProduct(product.id)}
              />
            </Box>
          ))}
        </Box>
      </Container>

      <Box
        display="flex"
        gap={3}
        position="fixed"
        bottom={0}
        width="100%"
        backgroundColor="common.white"
        justifyContent="center"
        px={2}
        py={3}
        boxShadow={theme.shadows[4]}
      >
        <Button
          sx={{
            flex: 1,
            border: '1px solid',
            borderColor: 'blue.500',
            borderRadius: 0,
            textTransform: 'none',
            fontWeight: 700,
            py: 1,
          }}
          aria-label="Reset compare selections"
          onClick={onReset}
        >
          Reset
        </Button>
        <ButtonLink
          variant="secondaryFilled"
          href={compareLink}
          sx={{ flex: 1, textTransform: 'none', py: 1 }}
          onClick={onClose}
          disabled={products.length < 2}
        >
          Compare
        </ButtonLink>
      </Box>
    </Dialog>
  );
}
