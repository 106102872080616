import { Box, CircularProgress, Drawer } from '@mui/material';
import PropTypes from 'prop-types';
import ProductDetails from './ProductDetails/ProductDetails';
import ResourceDetails from './ResourceDetails/ResourceDetails';

function DetailsDrawer({
  detailItem,
  isOpen,
  onClose,
  isProduct = true,
  onShowResource,
  onShowProduct,
}) {
  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        role: 'dialog',
        'aria-labelledby': isProduct ? 'product-drawer-header' : 'resource-drawer-header',
      }}
      sx={{
        '& .MuiDrawer-paper': {
          width: { xs: '100%', md: 'auto' },
        },
      }}
    >
      <Box width={{ xs: '100%', md: 645 }} position="relative">
        {!detailItem && (
          <Box
            width="100%"
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={100} />
          </Box>
        )}

        {detailItem && isProduct && (
          <ProductDetails
            product={detailItem}
            onDrawerClose={onClose}
            onShowResource={onShowResource}
          />
        )}

        {detailItem && !isProduct && (
          <ResourceDetails
            resource={detailItem}
            onDrawerClose={onClose}
            onShowProduct={onShowProduct}
          />
        )}
      </Box>
    </Drawer>
  );
}

DetailsDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  detailItem: PropTypes.object,
  isProduct: PropTypes.bool,
  onShowResource: PropTypes.func,
  onShowProduct: PropTypes.func,
};

export default DetailsDrawer;
