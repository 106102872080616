import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import Jumbotron from '../../components/Jumbotron';
import Container from '../../layout/Container';
import TopResults from './TopResults';
import ContentLinks from './ContentLinks';

function Resiliency({ resources, products, jumboImage }) {
  return (
    <>
      <Box>
        <Jumbotron bgImage={jumboImage} bgHeight={208}>
          <Container maxWidth="md">
            <Box display="flex" flexDirection="column" height="100%" py={8}>
              <Typography variant="h1" display="flex" flexDirection="column">
                <Typography
                  variant="h4"
                  component="span"
                  fontWeight="bold"
                  fontSize="1.125rem"
                  textAlign="center"
                  color="common.white"
                  textTransform="uppercase"
                  mb={1}
                >
                  Prepare for Anything
                </Typography>
                <Typography variant="h1" component="span" color="common.white" textAlign="center">
                  Keep the lights on during a power outage
                </Typography>
              </Typography>
            </Box>
          </Container>
        </Jumbotron>

        <Box mb={{ xs: 2, md: 7 }} mt={{ xs: 0, md: 7 }} mx={{ xs: 0, md: 7 }}>
          <TopResults resources={resources} products={products} />
        </Box>

        <ContentLinks />
      </Box>
    </>
  );
}

Resiliency.propTypes = {
  products: PropTypes.array,
  resources: PropTypes.array,
  jumboImage: PropTypes.string,
};

export default Resiliency;
