import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { PriceTagIcon, StarIconFilled } from '../assets/icons/icons';
import { formatAsThousands } from '../utils/formatters';
import Button from './Button';
import ProductBadge from './ProductBadge';
import ProductCompareButton from './Compare/ProductCompareButton';
import VisuallyHiddenText from './VisuallyHiddenText';

function MobileProductCard({ product, onClick }) {
  const {
    name,
    imageUrl,
    badges,
    regularPrice,
    salePrice,
    reviewAverage,
    reviewCount,
    isRebateEligible,
    rebate,
  } = product;

  return (
    <Box
      onClick={onClick}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        px: 2,
        pt: 5,
        pb: 2,
        color: 'inherit',
        textAlign: 'initial',
        textTransform: 'none',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        border: '1px solid',
        borderColor: 'gray.200',
        transition: 'all 200ms ease',
        '&:hover': {
          boxShadow: 3,
        },
      }}
    >
      <ProductCompareButton product={product} alignment="right" variant="mobile" />
      <Box display="flex" width="100%" mb={3}>
        <Box width={82} mr={2} flexShrink={0} display="flex" justifyContent="center">
          <Box
            component="img"
            src={imageUrl}
            alt=""
            flexShrink={0}
            maxWidth={82}
            maxHeight={82}
            sx={{ objectFit: 'cover' }}
          />
        </Box>

        <Box>
          <Box display="flex" flexDirection="column" alignSelf="flex-end" mb={1}>
            {!!badges.length &&
              badges.map((badge, idx) => (
                <Box key={badge} alignSelf="flex-start" mb={idx === badges.length - 1 ? 0 : 0.5}>
                  <ProductBadge text={badge} />
                </Box>
              ))}
          </Box>
          <Typography component="h3" sx={{ flexGrow: 1 }}>
            <Button
              onClick={onClick}
              aria-label={`${name} - ${salePrice}`}
              sx={{
                flexDirection: 'column',
                textAlign: 'left',
                textTransform: 'none',
                alignItems: 'flex-start',
                color: 'gray.900',
                p: 0,
              }}
            >
              <Typography variant="h5" fontSize="1rem" component="span" fontWeight="bold">
                {name}
              </Typography>
            </Button>
          </Typography>
          {reviewAverage && (
            <Typography variant="h6" fontSize="0.875rem" display="flex" alignItems="center" mt={1}>
              <StarIconFilled aria-label="stars" /> {reviewAverage}
              <Typography component="span" fontSize="0.875rem" ml={0.5}>
                ({formatAsThousands(reviewCount)} {reviewCount === 1 ? 'review' : 'reviews'})
              </Typography>
            </Typography>
          )}
        </Box>
      </Box>
      <Box display="flex" width="100%" alignItems="flex-end" justifyContent="space-between">
        <Box>
          {isRebateEligible && (
            <Box display="flex" alignItems="center">
              <PriceTagIcon />
              <Typography fontSize="0.875rem" color="gray.500" fontWeight={700} ml={0.5}>
                {!rebate && 'Rebate Eligible'}
                {rebate && `${rebate} Rebate`}
              </Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          {!!regularPrice && regularPrice !== salePrice && (
            <>
              <VisuallyHiddenText>Regular price:</VisuallyHiddenText>
              <Typography fontSize="1rem" color="gray.500" component="del">
                {regularPrice} full price
              </Typography>
            </>
          )}
          <VisuallyHiddenText>Sale price:</VisuallyHiddenText>
          <Typography variant="h2" fontSize="2.25rem" component="span" fontWeight="bold">
            {salePrice}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

MobileProductCard.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    modelNumber: PropTypes.string,
    imageUrl: PropTypes.string,
    badges: PropTypes.arrayOf(PropTypes.string),
    regularPrice: PropTypes.string,
    salePrice: PropTypes.string,
    reviewAverage: PropTypes.number,
    reviewCount: PropTypes.number,
    isRebateEligible: PropTypes.bool,
    rebate: PropTypes.string,
    priceRankNum: PropTypes.number,
    priceRankText: PropTypes.string,
    isEnergyStarMostEfficient: PropTypes.bool,
  }),
  onClick: PropTypes.func,
};

export default MobileProductCard;
