import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import Link from '../../components/Link';

const Column = ({ header, linkUrl, handleLinkClick, showLink, children }) => (
  <Grid item xs={12} md={6} display="flex" flexDirection="column" mb={{ xs: 2, md: 0 }}>
    <Typography variant="h4" color="gray.900" component="h2" textTransform="uppercase" mb={5}>
      {header}
    </Typography>
    <Box flexGrow={1}>{children}</Box>
    {showLink && (
      <Box
        display={{ xs: 'none', md: 'block' }}
        borderTop="1px solid"
        borderColor="gray.300"
        pt={3}
      >
        <Link
          href={linkUrl}
          textTransform="uppercase"
          fontSize="0.875rem"
          onClick={handleLinkClick}
          aria-label={`View more ${header}`}
        >
          View More
        </Link>
      </Box>
    )}
  </Grid>
);

Column.propTypes = {
  header: PropTypes.string.isRequired,
  children: PropTypes.node,
  handleLinkClick: PropTypes.func.isRequired,
  linkUrl: PropTypes.string.isRequired,
  showLink: PropTypes.bool,
};

export default Column;
