import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import Button from '../../components/Button';

export default function MobileCompareContent({ products, onOpenProductDrawer }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // list of all feature labels, each one gets a row in the table
  const allFeatures = [
    ...new Set(
      products
        .map(({ features }) => features)
        .flat()
        .map(({ label }) => label),
    ),
  ];

  // for each row, display a cell for every product's value for that feature label
  const featuresByProduct = allFeatures.reduce((accum, featureLabel) => {
    const featuresByProduct = products.map(
      (product) => product.features.find(({ label }) => label === featureLabel)?.value || '',
    );
    accum[featureLabel] = featuresByProduct;
    return accum;
  }, {});

  const truncationThreshold = isSmallScreen ? 35 : 50;
  const getTruncatedName = (name) =>
    name.length <= truncationThreshold ? name : `${name.slice(0, truncationThreshold)}...`;

  // text alignment varies based on number of products
  const textAlignByIndex = products.length === 2 ? ['left', 'right'] : ['left', 'center', 'right'];

  return (
    <Box
      sx={{
        px: 2,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Table
        sx={{
          tableLayout: 'fixed',
          height: 1, // hack to make <td> height 100% work within table
        }}
      >
        <colgroup>
          {products.map((idx) => (
            <col key={idx} />
          ))}
        </colgroup>
        <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'common.white' }}>
          <TableRow>
            {products.map((product) => (
              <TableCell
                key={`product-${product.id}`}
                component="th"
                sx={{ border: 'none', px: 1, pt: 1.5, pb: 1.5 }}
              >
                <Box
                  border="1px solid"
                  borderColor="gray.200"
                  backgroundColor="common.white"
                  pt={1.5}
                  pb={1}
                  px={0.5}
                  width="100%"
                  height="100%"
                >
                  <Box
                    width="100%"
                    flexShrink={0}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box height={70} display="flex" alignItems="center" aria-hidden="true">
                      <Box
                        component="img"
                        src={product.imageUrl}
                        alt=""
                        flexShrink={0}
                        maxWidth={70}
                        maxHeight={70}
                        sx={{ objectFit: 'cover' }}
                      />
                    </Box>
                    <Typography
                      aria-label={product.name}
                      mt={0.5}
                      title={product.name}
                      sx={{
                        textWrap: 'wrap',
                        textAlign: 'center',
                        fontSize: '0.75rem',
                        flexGrow: 1,
                      }}
                    >
                      {getTruncatedName(product.name)}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {/* 5 year energy savings */}
          <TableRow sx={{ backgroundColor: 'green.100' }}>
            <TableCell
              component="th"
              colSpan={products.length}
              scope="colgroup"
              sx={{ border: 'none', px: 0, pb: 0 }}
            >
              <Box
                sx={{
                  alignSelf: 'center',
                  fontWeight: 500,
                  fontSize: '1rem',
                  textWrap: 'wrap',
                  textAlign: 'center',
                }}
              >
                Estimated Energy Cost Savings Over 5 Years
              </Box>
            </TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: 'green.100' }}>
            {products.map((product, idx) => (
              <TableCell
                key={`savings-${product.id}`}
                sx={{
                  textAlign: textAlignByIndex[idx],
                  fontSize: '1.5rem',
                  fontWeight: 700,
                  color: '#6A9122',
                }}
              >
                {product.estimatedEnergySavings5Years}
              </TableCell>
            ))}
          </TableRow>

          {/* price */}
          <TableRow>
            <TableCell
              component="th"
              colSpan={products.length}
              scope="colgroup"
              sx={{ border: 'none', px: 0, pt: 0.5, pb: 0.5, backgroundColor: 'grey.100' }}
            >
              <Box
                sx={{
                  fontWeight: 500,
                  fontSize: '1rem',
                  textWrap: 'wrap',
                  textAlign: 'center',
                }}
              >
                Price
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            {products.map((product, idx) => (
              <TableCell
                key={`price-${product.id}`}
                sx={{
                  textAlign: textAlignByIndex[idx],
                  fontSize: '1.5rem',
                  fontWeight: 700,
                }}
              >
                {product.salePrice}
              </TableCell>
            ))}
          </TableRow>

          {/* features */}
          {Object.keys(featuresByProduct).map((feature) => (
            <React.Fragment key={feature}>
              <TableRow>
                <TableCell
                  component="th"
                  colSpan={products.length}
                  scope="colgroup"
                  sx={{ border: 'none', px: 0, pt: 0.5, pb: 0.5, backgroundColor: 'grey.100' }}
                >
                  <Box
                    sx={{
                      fontWeight: 500,
                      fontSize: '1rem',
                      textWrap: 'wrap',
                      textAlign: 'center',
                    }}
                  >
                    {feature}
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                {featuresByProduct[feature].map((productFeature, idx) => (
                  <TableCell
                    key={feature + idx}
                    sx={{ textAlign: textAlignByIndex[idx], fontSize: '0.875rem' }}
                  >
                    {productFeature || '—'}
                  </TableCell>
                ))}
              </TableRow>
            </React.Fragment>
          ))}

          <TableRow>
            {products.map((product) => (
              <TableCell key={`offer-${product.id}`} sx={{ pt: 3, px: 1.25, borderBottom: 'none' }}>
                <Button
                  onClick={onOpenProductDrawer(product.id)}
                  aria-label={`See details for ${product.name}`}
                  sx={{
                    width: '100%',
                    borderRadius: 0,
                    backgroundColor: 'orange.500',
                    py: 1.5,
                    color: 'common.black',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    '&:hover, &:focus': {
                      backgroundColor: 'orange.700',
                    },
                  }}
                >
                  View
                </Button>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}
