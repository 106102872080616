import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import ButtonLink from '../ButtonLink';
import EmbeddedVideo from '../EmbeddedVideo';
import Link from '../Link';

function ResourceDetailsLinks({ resource }) {
  const { infoUrls, learnMoreUrl, learnMoreCta, actionCta, actionUrl, visualGuideUrl, name } =
    resource;

  return (
    <Box>
      {infoUrls.map(({ url, cta }) => (
        <Link key={url} external href={url} mb={2}>
          {cta}
        </Link>
      ))}

      {visualGuideUrl && (
        <Box mt={4} mb={5}>
          <EmbeddedVideo videoUrl={visualGuideUrl} title={name} width="567" height="285" />
        </Box>
      )}

      <Grid container spacing={3} mt={visualGuideUrl ? 0 : 5}>
        <Grid item xs={6}>
          <ButtonLink
            variant="secondaryInverted"
            external
            href={learnMoreUrl}
            fullWidth
            aria-label={`${learnMoreCta} about ${name}`}
          >
            {learnMoreCta}
          </ButtonLink>
        </Grid>
        <Grid item xs={6}>
          <ButtonLink
            variant="primary"
            external
            href={actionUrl}
            fullWidth
            aria-label={`${name} - ${actionCta}`}
          >
            {actionCta}
          </ButtonLink>
        </Grid>
      </Grid>
    </Box>
  );
}

ResourceDetailsLinks.propTypes = {
  resource: PropTypes.shape({
    infoUrls: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        cta: PropTypes.string,
      }),
    ),
    learnMoreCta: PropTypes.string,
    learnMoreUrl: PropTypes.string,
    actionCta: PropTypes.string,
    actionUrl: PropTypes.string,
    visualGuideUrl: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default ResourceDetailsLinks;
