import { Box, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import Button from '../../components/Button';
import { router } from '@inertiajs/react';
import axios from 'axios';

export default function EmailForm({ isEmailSubmitted }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };

  const handleSubmit = () => {
    if (!validateEmail(email)) {
      setError('Invalid email');
    } else {
      router.post(
        '/email-recommendations',
        {
          email,
          recommendations_url: window.location.href,
        },
        {
          headers: {
            'X-CSRF-Token': axios.defaults.headers.common['X-CSRF-Token'],
          },
          preserveScroll: true,
          preserveState: true,
        },
      );
    }
  };

  return (
    <Box py={3} px={{ xs: 1, sm: 4 }} bgcolor="gray.100" mb={4} mt={2}>
      {isEmailSubmitted && (
        <>
          <Typography variant="h4" fontWeight="bold" mb={1} textAlign="center">
            Thanks for submitting your email
          </Typography>
          <Typography variant="h6" fontWeight={500} textAlign="center">
            A recommendation email will be arriving in your inbox shortly.
          </Typography>
        </>
      )}

      {!isEmailSubmitted && (
        <>
          <Typography variant="h4" fontWeight="bold" mb={1} textAlign="center">
            Email me recommendations
          </Typography>
          <Typography variant="h6" fontWeight={500} textAlign="center">
            Send me a tailored recap of energy goals, recommended energy actions and next steps.
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            alignItems={{ xs: 'center', sm: 'flex-start' }}
            flexDirection={{ xs: 'column', sm: 'row' }}
            mt={3}
          >
            <TextField
              error={!!error}
              label="Email address"
              variant="outlined"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setError(null);
              }}
              helperText={error}
              sx={{ width: { xs: '100%', sm: 360 } }}
              InputProps={{
                sx: { borderRadius: 0, backgroundColor: 'common.white' },
              }}
              FormHelperTextProps={{
                sx: { backgroundColor: 'gray.100' },
              }}
            />
            <Button
              aria-label="Submit"
              onClick={handleSubmit}
              sx={{
                backgroundColor: 'orange.500',
                py: { xs: 1.5, sm: 2 },
                color: 'common.black',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                borderRadius: 0,
                px: 5,
                ml: { xs: 0, sm: 2 },
                mt: { xs: 2, sm: 0 },
                width: { xs: '100%', sm: 'auto' },
                '&:hover, &:focus': {
                  backgroundColor: 'orange.700',
                },
              }}
            >
              Submit
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
