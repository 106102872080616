import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CondensedProductCard from '../CondensedProductCard';

function ResourceDetailsApplicableProducts({ resource, onDrawerClose, onShowProduct }) {
  const { applicableProducts } = resource;

  const handleProductClick = (e, productId) => {
    // close currently open drawer in favor of a new one to feature the resource
    onDrawerClose();

    const drawerHandler = onShowProduct(productId);
    drawerHandler(e);
  };

  return (
    <Box>
      <Typography variant="h4" fontWeight="bold" mb={2.5}>
        Applicable products
      </Typography>
      <Grid container spacing={1}>
        {applicableProducts.map((product) => (
          <Grid item xs={12} sm={6} key={product.id}>
            {/* TODO: open product details */}
            <CondensedProductCard
              product={product}
              onClick={(e) => handleProductClick(e, product.id)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

ResourceDetailsApplicableProducts.propTypes = {
  resource: PropTypes.shape({
    applicableProducts: PropTypes.array,
  }),
  onDrawerClose: PropTypes.func,
  onShowProduct: PropTypes.func,
};

export default ResourceDetailsApplicableProducts;
