import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import ProductBadge from './ProductBadge';
import Button from './Button';
import { PriceTagIcon, StarIconFilled } from '../assets/icons/icons';
import VisuallyHiddenText from './VisuallyHiddenText';
import { formatAsThousands } from '../utils/formatters';
function CondensedProductCard({ product, onClick, noPadding }) {
  const {
    name,
    imageUrl,
    badges,
    salePrice,
    regularPrice,
    reviewAverage,
    reviewCount,
    isRebateEligible,
    rebate,
  } = product;

  return (
    <Box
      position="relative"
      onClick={onClick}
      p={noPadding ? 0 : 1}
      sx={{
        cursor: 'pointer',
        color: 'inherit',
        textAlign: 'initial',
        textTransform: 'none',
        height: '100%',
        width: '100%',
        outlineOffset: -1,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        backgroundColor="common.white"
        p={2}
        width="100%"
        height="100%"
        sx={{
          cursor: 'pointer',
          transition: 'all 200ms ease',
          boxShadow: 3,
          '&:hover, &:focus': {
            boxShadow: 5,
          },
        }}
      >
        <Box display="flex" justifyContent="center" mb={4}>
          <Box component="img" src={imageUrl} alt="" height={115} width="auto" textAlign="center" />
        </Box>

        <Box display="flex" flexShrink={0} flexWrap="wrap" mb={1}>
          {!!badges.length &&
            badges.map((badge, idx) => (
              <Box
                key={badge}
                alignSelf="flex-start"
                mr={idx === badges.length - 1 ? 0 : 0.5}
                mb={idx === badges.length - 1 ? 0 : 0.5}
              >
                <ProductBadge text={badge} />
              </Box>
            ))}
        </Box>

        <Typography component="h3" mb={1}>
          <Button
            onClick={onClick}
            sx={{
              flexDirection: 'column',
              textAlign: 'left',
              textTransform: 'none',
              alignItems: 'flex-start',
              color: 'gray.900',
              p: 0,
            }}
          >
            <Typography variant="h5" fontSize="1rem" component="span" fontWeight="bold">
              {name}
            </Typography>
          </Button>
        </Typography>

        <Box sx={{ flexGrow: 1 }} mb={2}>
          {reviewAverage && (
            <Typography variant="h6" fontSize="0.875rem" display="flex" alignItems="center">
              <StarIconFilled aria-label="stars" /> {reviewAverage}
              <Typography component="span" variant="h6" fontSize="0.875rem" ml={0.5}>
                ({formatAsThousands(reviewCount)} {reviewCount === 1 ? 'review' : 'reviews'})
              </Typography>
            </Typography>
          )}
        </Box>

        <Box display="flex" alignItems="flex-end" justifyContent="space-between">
          <Box>
            {isRebateEligible && (
              <Box display="flex" alignItems="center">
                <PriceTagIcon />
                <Typography
                  variant="h6"
                  fontSize="0.875rem"
                  component="span"
                  color="gray.500"
                  fontWeight={700}
                  ml={0.5}
                >
                  {!rebate && 'Rebate Eligible'}
                  {rebate && `${rebate} Rebate`}
                </Typography>
              </Box>
            )}
          </Box>
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            {!!regularPrice && regularPrice !== salePrice && (
              <>
                <VisuallyHiddenText>Regular price:</VisuallyHiddenText>
                <Typography variant="h5" fontSize="1rem" component="del" color="gray.500">
                  {regularPrice} full price
                </Typography>
              </>
            )}
            <VisuallyHiddenText>Sale price:</VisuallyHiddenText>
            <Typography variant="h2" fontSize="2.25rem" component="span" fontWeight="bold">
              {salePrice}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

CondensedProductCard.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    modelNumber: PropTypes.string,
    imageUrl: PropTypes.string,
    badges: PropTypes.arrayOf(PropTypes.string),
    salePrice: PropTypes.string,
    regularPrice: PropTypes.string,
    reviewAverage: PropTypes.number,
    priceRankNum: PropTypes.number,
    isRebateEligible: PropTypes.bool,
    rebate: PropTypes.string,
    isEnergyStarMostEfficient: PropTypes.bool,
  }),
  onClick: PropTypes.func,
};

export default CondensedProductCard;
