import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import MobileOffersButton from './MobileOffersButton';
import ProductDetailsAccolades from './ProductDetailsAccolades';
import ProductDetailsFeatures from './ProductDetailsFeatures';
import ProductDetailsHeader from './ProductDetailsHeader';
import ProductDetailsOffers from './ProductDetailsOffers';
import ProductDetailsResource from './ProductDetailsResource';
import ProductDetailsSavings from './ProductDetailsSavings';
import ProductDetailsSimilarProducts from './ProductDetailsSimilarProducts';
import PostActionModal from './PostActionModal';
import useSessionStorage from '../../hooks/useSessionStorage';

function ProductDetails({ product, onDrawerClose, onShowResource }) {
  const { offers, salePrice, resource, secondaryResource } = product;

  const [isPostActionModalOpen, setIsPostActionModalOpen] = useState(false);
  const [hasSeenPostActionModal, setHasSeenPostActionModal] = useSessionStorage(
    'hasSeenPostActionModal',
    '',
  );

  const wrapperRef = useRef(null);
  const offersRef = useRef(null);

  const offersCtaText = `${offers.length} ${
    offers.length === 1 ? 'offer' : 'offers'
  } from ${salePrice}`;

  const handleMobileOffersClick = () => {
    if (offersRef.current) {
      offersRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleOfferClick = () => {
    if (!hasSeenPostActionModal) {
      setIsPostActionModalOpen(true);
      setHasSeenPostActionModal('true');
    }
  };

  return (
    <>
      <Box p={{ xs: 2, sm: 5.5 }} ref={wrapperRef}>
        <ProductDetailsHeader product={product} onDrawerClose={onDrawerClose} />
        <ProductDetailsAccolades product={product} />
        <ProductDetailsSavings product={product} />

        {resource && (
          <Box mb={{ xs: 3, sm: 6 }}>
            <ProductDetailsResource
              resource={resource}
              onDrawerClose={onDrawerClose}
              onShowResource={onShowResource}
            />
          </Box>
        )}

        <ProductDetailsOffers product={product} ref={offersRef} onOfferClick={handleOfferClick} />
        {secondaryResource && (
          <Box mb={4}>
            <ProductDetailsResource
              resource={secondaryResource}
              onDrawerClose={onDrawerClose}
              onShowResource={onShowResource}
            />
          </Box>
        )}
        <ProductDetailsFeatures product={product} />
      </Box>
      <Box
        p={{ xs: 2, sm: 5.5 }}
        pt={{ xs: 5, sm: 5.5 }}
        backgroundColor="gray.100"
        mb={{ xs: 11, sm: 0 }}
      >
        <ProductDetailsSimilarProducts product={product} wrapperRef={wrapperRef} />
      </Box>

      <MobileOffersButton ctaText={offersCtaText} onClick={handleMobileOffersClick} />

      <PostActionModal
        isOpen={isPostActionModalOpen}
        onClose={() => setIsPostActionModalOpen(false)}
        product={product}
        onDrawerClose={onDrawerClose}
        onShowResource={onShowResource}
      />
    </>
  );
}

ProductDetails.propTypes = {
  product: PropTypes.shape({
    offers: PropTypes.array,
    salePrice: PropTypes.string,
    resource: PropTypes.object,
  }),
  onDrawerClose: PropTypes.func.isRequired,
  onShowResource: PropTypes.func,
};

export default ProductDetails;
