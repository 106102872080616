import { Box, Typography } from '@mui/material';
import React from 'react';
import ToggleGroup from './ToggleGroup';

export default function TechInputs({ tech, selectedTech, setSelectedTech }) {
  const handleChange = (e, newTech) => {
    setSelectedTech(newTech);
  };

  return (
    <Box>
      <Box display="flex" alignItems="flex-start" sx={{ flexDirection: 'column' }}>
        <Typography
          variant="h3"
          fontSize={{ xs: '1rem', sm: '1.5rem' }}
          fontWeight="bold"
          mb={{ xs: 0, md: 1 }}
        >
          Select energy technologies of interest.
        </Typography>
        <Typography
          variant="h5"
          fontSize={{ xs: '0.75rem', sm: '1rem' }}
          component="span"
          color="gray.500"
          mb={{ xs: 2, md: 4 }}
        >
          We&apos;ll show results that align with the energy goals you&apos;ve selected above.
        </Typography>
      </Box>
      <ToggleGroup options={tech} selectedOptions={selectedTech} handleChange={handleChange} />
    </Box>
  );
}
