import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import Container from '../../layout/Container';
import { ClockIcon, CloseIcon } from '../../assets/icons/icons';
import { usePage } from '@inertiajs/react';
import ButtonLink from '../ButtonLink';
import EventModal from './EventModal';
import useLocalStorage from '../../hooks/useLocalStorage';

const MOCK_BANNER_DATA = [
  {
    id: 1,
    title: 'Flex Day Alert',
    dateString: 'Tuesday, 7/21 • 4pm-9pm PT',
    description:
      'Reduce strain on the electric grid and carbon emissions by raising your thermostat to 78 degrees and avoid using major appliances during the event.',
    ctaText: 'Learn More',
    ctaUrl: 'https://pge.com',
    startDisplayTime: '2024-01-23 10:00:00 -0800',
    endDisplayTime: '2024-02-24 6:00:00 -0800',
  },
];

export default function EventBanner() {
  const {
    props: { eventBannerData },
  } = usePage();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [dismissedEvents, setDismissedEvents] = useLocalStorage('dismissedEvents', []);

  const now = new Date();
  const activeEvents = (eventBannerData || []).filter((event) => {
    // check if event has already been dismissed
    const isDismissed = dismissedEvents.includes(event.id);

    // check if event is currently active
    const start = new Date(event.startDisplayTime);
    const end = new Date(event.endDisplayTime);

    return !isDismissed && start <= now && now <= end;
  });

  const handleDismiss = () => {
    const activeEventIds = activeEvents.map((event) => event.id);
    setDismissedEvents([...new Set([...dismissedEvents, ...activeEventIds])]);
  };

  if (!activeEvents.length) return null;

  const ariaProps = {
    'aria-hidden': 'true',
    focusable: 'false',
  };

  return isSmallScreen ? (
    <EventModal events={activeEvents} onClose={handleDismiss} />
  ) : (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        boxShadow: 3,
      }}
    >
      <Box
        sx={{
          width: '5px',
          backgroundColor: 'orange.500',
          position: 'absolute',
          left: 0,
          height: '100%',
        }}
      />
      <Container maxWidth="lg">
        {activeEvents.map((event) => (
          <Box
            key={event.id}
            sx={{ py: 2, display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <Box flexShrink={0}>
              <ClockIcon {...ariaProps} />
            </Box>
            <Box sx={{ ml: 2, mr: 4, flexGrow: 1 }}>
              <Typography color="blue.500" fontWeight="bold">
                {event.title} | {event.dateString}
              </Typography>
              <Typography fontSize="0.875rem">{event.description}</Typography>
            </Box>
            <ButtonLink
              href={event.ctaUrl}
              variant="primary"
              external
              sx={{
                mr: { xs: 3, xl: 0 },
                fontSize: '0.875rem',
                flexShrink: 0,
                alignSelf: 'center',
                borderRadius: 0,
                backgroundColor: 'orange.500',
                py: 1.5,
                px: 4,
                color: 'common.black',
                fontWeight: '600',
                textTransform: 'uppercase',
                '&:hover, &:focus': {
                  backgroundColor: 'orange.700',
                },
              }}
            >
              {event.ctaText}
            </ButtonLink>
          </Box>
        ))}
      </Container>
      <Button
        onClick={handleDismiss}
        sx={{ minWidth: 'auto', position: 'absolute', right: '4px', top: '4px' }}
        aria-label="Close event banner"
      >
        <CloseIcon />
      </Button>
    </Box>
  );
}
