import PropTypes from 'prop-types';
import { router, usePage } from '@inertiajs/react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import DropdownInput from '../../components/DropdownInput';
import { replaceQueryParam } from '../../utils/urlFunctions';
import { PriceTagIcon } from '../../assets/icons/icons';

const RebateEligible = () => {
  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box display="flex" alignItems="center" mr={4}>
      <PriceTagIcon height={isXSmallScreen ? 11.25 : 17} width={isXSmallScreen ? 11.25 : 16} />
      <Typography
        fontSize={{ xs: '0.75rem', sm: '1rem' }}
        fontWeight={500}
        ml={{ xs: 0.5, sm: 1 }}
        color="grey.600"
      >
        Rebate Eligible
      </Typography>
    </Box>
  );
};

function SortBar({ sortType, category, sortOptions, countLabel }) {
  const { url } = usePage();

  const handleSortChange = (e) => {
    const newSortUrl = replaceQueryParam(url, 'sortType', e.target.value);
    const newPageUrl = replaceQueryParam(newSortUrl, 'page', 1);
    router.visit(newPageUrl, {
      preserveScroll: true,
      preserveState: true,
    });
  };

  return (
    <Box display="flex" alignItems={{ xs: 'flex-start', sm: 'center' }} mb={{ xs: 1, sm: 2 }}>
      <Box>
        <Typography
          variant="h6"
          fontSize="0.875rem"
          component="h2"
          fontWeight="bold"
          textTransform="uppercase"
          mr={3}
          mt={0.5}
        >
          {countLabel}
        </Typography>
        {category && category.hasRebates && (
          <Box display={{ xs: 'block', sm: 'none' }}>
            <RebateEligible />
          </Box>
        )}
      </Box>

      <Box ml="auto" display="flex" alignItems="center" flexDirection={{ xs: 'column', sm: 'row' }}>
        {category && category.hasRebates && (
          <Box display={{ xs: 'none', sm: 'block' }}>
            <RebateEligible />
          </Box>
        )}
        <DropdownInput
          id="sort-type"
          variant="standard"
          ariaLabel="Sort products"
          value={sortType}
          options={sortOptions}
          onChange={handleSortChange}
        />
      </Box>
    </Box>
  );
}

SortBar.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    hasRebates: PropTypes.bool,
  }),
  sortType: PropTypes.string,
  countLabel: PropTypes.string,
  sortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      children: PropTypes.node,
    }),
  ),
};

export default SortBar;
