import { Link as InertiaLink } from '@inertiajs/react';
import { Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const FooterLink = ({ href, text, variant = 'h5', color, external, ...props }) => (
  <Link
    component={external ? 'a' : InertiaLink}
    target={external ? '_blank' : '_self'}
    href={href}
    color={color || 'common.white'}
    sx={{
      textDecoration: 'none',
    }}
  >
    <Typography variant={variant} component="span" display="block" {...props}>
      {text}
    </Typography>
  </Link>
);

FooterLink.propTypes = {
  color: PropTypes.string,
  external: PropTypes.bool,
  href: PropTypes.string,
  text: PropTypes.string,
  variant: PropTypes.string,
};

export default FooterLink;
