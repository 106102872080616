import { Box, Dialog, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { CloseIcon, LeafIcon, MoneyLightbulbIcon } from '../../assets/icons/icons';
import ProductDetailsResource from './ProductDetailsResource';
import Button from '../Button';
import Link from '../Link';

const SavingsInsight = ({ icon, text, noMargin }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    borderRadius="4px"
    border="1px solid"
    borderColor="grey.300"
    backgroundColor="common.white"
    p={2}
    maxWidth={{ xs: 'auto', sm: 200 }}
    width={{ xs: '100%', sm: 'auto' }}
    mr={noMargin ? 0 : { xs: 0, sm: 4 }}
    mb={noMargin ? 0 : { xs: 2, sm: 0 }}
  >
    {icon}
    <Typography mt={2} color="grey.700" fontSize="0.875rem" textAlign="center">
      {text}
    </Typography>
  </Box>
);

export default function PostActionModal({
  isOpen,
  onClose,
  product,
  onDrawerClose,
  onShowResource,
}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [isUserBoughtProduct, setIsUserBoughtProduct] = useState(false);

  const lbsCo2Avoided = Math.round(product.estimatedKwhAvoided5Years * 0.206);
  const treesPlanted = Math.round((product.estimatedKwhAvoided5Years * 0.206) / 23);

  const energyInsight =
    treesPlanted >= 5 ? (
      <>
        planting <b>{treesPlanted} trees</b>
      </>
    ) : (
      <b>
        {lbsCo2Avoided} lbs of CO<sub>2</sub>
      </b>
    );

  return (
    <Dialog
      fullScreen={isSmallScreen}
      open={isOpen}
      onClose={onClose}
      aria-labelledby="post-action-modal-heading"
      BackdropProps={{ sx: { backgroundColor: 'rgba(0, 0, 0, 0.65)' } }}
    >
      <Box width={650} maxWidth="100%" position="relative">
        <Box position="absolute" top={0} right={0}>
          <Button
            onClick={onClose}
            sx={{ minWidth: 'auto', marginLeft: 'auto' }}
            aria-label="Close feedback dialog"
          >
            <CloseIcon />
          </Button>
        </Box>
        <Box p={{ xs: 2, md: 4 }} px={{ md: 12 }}>
          <Box
            p={{ xs: 2 }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ border: '1px solid', borderColor: 'gray.200', boxShadow: 3 }}
          >
            <Box
              height={130}
              width={130}
              mt={1}
              mb={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img
                src={product.imageUrl}
                alt=""
                height="100%"
                width="auto"
                style={{ maxWidth: '100%', objectFit: 'contain' }}
              />
            </Box>
            <Typography fontSize="1.25rem" fontWeight="500" textAlign="center">
              {product.name}
            </Typography>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          bgcolor="grey.100"
          px={{ xs: 2, md: 4 }}
          py={2}
        >
          {product.estimatedEnergySavings5Years && (
            <Box mb={5}>
              <Typography variant="h4" fontWeight="500" mb={2} textAlign="center">
                This product can help you save:
              </Typography>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                justifyContent="center"
              >
                <SavingsInsight
                  icon={<MoneyLightbulbIcon alt="" role="presentation" aria-hidden="true" />}
                  text={
                    <>
                      An estimated <b>{product.estimatedEnergySavings5Years} over five years</b> of
                      usage.
                    </>
                  }
                />
                <SavingsInsight
                  icon={<LeafIcon alt="" role="presentation" aria-hidden="true" />}
                  text={
                    <>
                      An estimated <b>{Math.round(product.estimatedKwhAvoided5Years)} kWh</b> in
                      five years, the equivalent of {energyInsight}.
                    </>
                  }
                  noMargin
                />
              </Box>
            </Box>
          )}

          {product.resource && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography variant="h4" fontWeight="500" mb={2}>
                Make sure you&apos;re getting the most out of your purchase:
              </Typography>
              <ProductDetailsResource
                resource={product.resource}
                onDrawerClose={onDrawerClose}
                onShowResource={onShowResource}
              />
            </Box>
          )}
          <Link href={product.resourcesLink} sx={{ alignSelf: 'flex-end', my: 2 }}>
            Next Steps
          </Link>
        </Box>
      </Box>
    </Dialog>
  );
}
