import React from 'react';
import Button from './Button';

const getScoreAriaLabel = (score, maxScore, lowLabel, highLabel) => {
  if (score === 1) {
    return `Feedback score: 1 - ${lowLabel}`;
  }
  if (score === maxScore) {
    return `Feedback score: ${maxScore} - ${highLabel}`;
  }
  return `Feedback score: ${score}`;
};

export default function FeedbackScoreButton({
  score,
  onChangeScore,
  isSelected,
  maxScore,
  lowLabel,
  highLabel,
}) {
  return (
    <Button
      onClick={() => onChangeScore(score)}
      aria-label={getScoreAriaLabel(score, maxScore, lowLabel, highLabel)}
      sx={{
        height: { xs: 30, lg: 40 },
        width: { xs: 25, lg: 30 },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid',
        borderColor: isSelected ? 'blue.500' : 'grey.400',
        backgroundColor: isSelected ? 'blue.50' : 'common.white',
        color: 'common.black',
        minWidth: 'auto',
        fontWeight: 400,
        '&:hover': {
          backgroundColor: isSelected ? 'blue.50' : 'common.white',
        },
      }}
    >
      {score}
    </Button>
  );
}
