import { useEffect, useRef } from 'react';

// use effect, but it only runs after the initial render
const useAfterRenderEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export default useAfterRenderEffect;
