import { Box, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import Container from './Container';
import ButtonLink from '../components/ButtonLink';
import logo from '../assets/images/logo.svg';
import { Link as InertiaLink } from '@inertiajs/react';
import MobileFooter from './MobileFooter';
import FooterLink from '../components/FooterLink';

function Footer() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const showCookiePrefs = () => {
    window.Optanon && window.Optanon.ToggleInfoDisplay();
    return false;
  };

  if (isSmallScreen) return <MobileFooter />;

  return (
    <Box component="footer" py={4} backgroundColor="blue.700" color="common.white">
      <Container>
        <Box display="flex" flexDirection="column">
          <Box display="flex">
            <InertiaLink href="/">
              <Box
                component="img"
                src={logo}
                alt="PG&amp;E Logo"
                sx={{ mr: 5 }}
                height={49}
                width={49}
                flexShrink={0}
              />
            </InertiaLink>
            <Box display="flex" flexDirection="column" flexWrap="wrap" maxHeight={110}>
              <FooterLink href="/browse" text="Residential Products" mb={1} mr={4} />
              <FooterLink href="/resources" text="Residential Resources" mb={1} mr={4} />
              <FooterLink href="/resiliency" text="Prepare for outages" mb={1} mr={4} />
              <FooterLink
                external
                href="https://www.pge.com/en_US/residential/customer-service/help/contact-pge-landing/contact-us.page"
                text="Customer Support"
                mb={1}
                mr={4}
              />
              <FooterLink href="/business" text="Business Resources" mb={1} mr={4} />
            </Box>
            <Box ml="auto">
              <ButtonLink
                href="https://forms.office.com/pages/responsepage.aspx?id=GmauRObsqkG8lnwshaCJQWChvp5M-n1BiJf9j4xSRslUOUdSUzVDQlUzM1UxMFkzV1VEREVKUDNUMy4u"
                variant="secondary"
                external
              >
                Feedback
              </ButtonLink>
            </Box>
          </Box>

          <Box mt={4} ml="auto">
            <FooterLink
              href="https://www.bellawatt.com/"
              text="Powered by Bellawatt"
              external
              sx={{ textAlign: 'right' }}
            />
          </Box>
          <Box display="flex" mt={1}>
            <FooterLink
              href="/privacy-policy"
              text="Privacy Policy"
              color="gray.300"
              variant="body2"
              mr={3}
            />

            <FooterLink
              href="https://www.pge.com/en_US/about-pge/company-information/disclosure/disclosure.page"
              text="Terms &amp; Conditions"
              color="gray.300"
              variant="body2"
              mr={3}
              external
            />

            <Link
              component="a"
              href="#"
              target="_self"
              color="gray.300"
              id="ot-sdk-btn"
              onClick={showCookiePrefs}
              mr={3}
              sx={{
                textDecoration: 'none',
              }}
            >
              <Typography variant="body2" component="span" display="block">
                Do Not Sell My Personal Data
              </Typography>
            </Link>

            <Typography variant="body2" color="gray.300" ml="auto">
              ©{new Date().getFullYear()} Pacific Gas and Electric Company. All rights reserved.
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
