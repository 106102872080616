import { Link as InertiaLink, usePage } from '@inertiajs/react';
import { Box, Popover, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CloseIcon } from '../assets/icons/icons';
import Button from '../components/Button';
import Container from './Container';
import { PriceTagIcon } from '../assets/icons/icons';

function ProductCategoryMenu({ anchorEl, onClose, open }) {
  const {
    props: { groupedCategories },
  } = usePage();

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      id="product-category-menu"
      marginThreshold={0}
      PaperProps={{
        sx: {
          borderRadius: 0,
          width: '100%',
          maxWidth: '100%',
          left: 0,
          right: 0,
        },
      }}
    >
      <Box pt={2} pr={2} display="flex" justifyContent="flex-end">
        <Button onClick={onClose} aria-label="Close product category menu">
          <CloseIcon />
        </Button>
      </Box>
      <Container>
        <Box display="flex" alignItems="center">
          <PriceTagIcon />
          <Typography ml={1} color="gray.600">
            Rebate Eligible
          </Typography>
        </Box>
        <Box mt={2} mb={4} display="flex" justifyContent="space-between">
          {groupedCategories.map((group) => (
            <Box
              key={group.id}
              flex={1}
              component="dl"
              ml={0}
              mr={4}
              p={0}
              sx={{ listStyle: 'none' }}
            >
              <Typography
                variant="h4"
                component="dt"
                fontWeight="bold"
                mb={4}
                sx={{ minHeight: 45 }}
              >
                {group.name}
              </Typography>
              {group.categories.map((category) => (
                <Box component="dd" key={category.handle} mb={2} ml={0}>
                  <Box
                    component={InertiaLink}
                    onClick={onClose}
                    href={category.link}
                    sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
                  >
                    {category.hasRebates && (
                      <Box flexShrink={0} mr={1} display="flex" alignItems="center">
                        <PriceTagIcon />
                      </Box>
                    )}
                    <Typography color="blue.500">{category.name}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Container>
    </Popover>
  );
}

ProductCategoryMenu.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default ProductCategoryMenu;
