import PropTypes from 'prop-types';
import { Box, Pagination } from '@mui/material';
import ResourceCard from '../../components/ResourceCard';
import useDetailsDrawer from '../../hooks/useDetailsDrawer';
import DetailsDrawer from '../../components/DetailsDrawer';
import { router } from '@inertiajs/react';
import NoResults from '../../components/NoResults';

function ResourceList({ resources, pagination }) {
  const {
    isOpen: isResourceDrawerOpen,
    onOpen: onOpenResourceDrawer,
    onClose: onCloseResourceDrawer,
    detailItem: resource,
  } = useDetailsDrawer('resource', 'resource_id');

  const {
    isOpen: isProductDrawerOpen,
    onOpen: onOpenProductDrawer,
    onClose: onCloseProductDrawer,
    detailItem: product,
  } = useDetailsDrawer();

  const { page, pages, scaffold_url: scaffoldUrl } = pagination;

  const handleNewPage = (page) => {
    const newPageUrl = scaffoldUrl.replace('__pagy_page__', page);
    router.visit(newPageUrl, {
      preserveScroll: false,
      preserveState: true,
    });
  };

  if (!resources.length) return <NoResults />;

  return (
    <>
      <Box display="flex" flexDirection="column">
        {resources.map((resource) => (
          <Box key={resource.id} mb={2}>
            <ResourceCard resource={resource} onClick={onOpenResourceDrawer(resource.id)} />
          </Box>
        ))}

        <Box display="flex" justifyContent="center" mt={2}>
          <Pagination
            page={page}
            count={pages}
            onChange={(_, num) => handleNewPage(num)}
            color="primary"
            sx={{
              '& .MuiPaginationItem-root': {
                '&:hover, &:focus-visible': {
                  backgroundColor: 'gray.450',
                  color: 'common.white',
                },
              },
            }}
          />
        </Box>
      </Box>

      <DetailsDrawer
        isOpen={isResourceDrawerOpen}
        onClose={onCloseResourceDrawer}
        detailItem={resource}
        isProduct={false}
        onShowProduct={onOpenProductDrawer}
      />

      <DetailsDrawer
        isOpen={isProductDrawerOpen}
        onClose={onCloseProductDrawer}
        detailItem={product}
        onShowResource={onOpenResourceDrawer}
      />
    </>
  );
}

ResourceList.propTypes = {
  resources: PropTypes.array,
  pagination: PropTypes.shape({
    page: PropTypes.number,
    pages: PropTypes.number,
    next: PropTypes.number,
    scaffold_url: PropTypes.string,
  }),
};

export default ResourceList;
