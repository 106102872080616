import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

function ProductBadge({ text }) {
  return (
    <Typography
      variant="body1"
      fontSize="0.75rem"
      sx={{
        color: 'gray.900',
        backgroundColor: 'green.300',
        py: 0.75,
        px: 1.25,
        borderRadius: 100,
        fontSize: '0.75rem',
        fontWeight: 'bold',
      }}
    >
      {text}
    </Typography>
  );
}

ProductBadge.propTypes = {
  text: PropTypes.string,
};

export default ProductBadge;
