import { Box, Grid, Typography } from '@mui/material';
import FilterRail from '../../components/FilterRail';
import Jumbotron from '../../components/Jumbotron';
import MobileFilters from '../../components/MobileFilters';
import Container from '../../layout/Container';
import ResourceList from '../Resources/ResourceList';
import BusinessLayout from '../../layout/BusinessLayout';
import PropTypes from 'prop-types';
import ScrollToTop from '../../components/ScrollToTop';

function Businesses({ resources, pagination, filters_data: filtersData, jumboImage }) {
  return (
    <>
      <Box>
        <Jumbotron bgImage={jumboImage} darken bgHeight={208}>
          <Container maxWidth="md">
            <Box display="flex" flexDirection="column" height="100%" pb={{ xs: 0, sm: 8 }}>
              <Typography variant="h1" component="span" color="common.white" textAlign="center">
                Discover ways to reduce your energy usage for your business
              </Typography>
            </Box>
          </Container>
        </Jumbotron>
        <Container>
          <Box display="flex" flexDirection="column" pt={{ xs: 4, sm: 6 }} pb={10}>
            <Box display="flex" flexDirection="column" alignItems="center" mb={{ xs: 4, sm: 10 }}>
              <Typography variant="h1" display="flex" flexDirection="column">
                <Typography
                  variant="h4"
                  fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
                  component="span"
                  mb={{ xs: 2.5, sm: 2 }}
                  textTransform="uppercase"
                  fontWeight="bold"
                  textAlign="center"
                >
                  PG&amp;E Business Resources
                </Typography>
                <Typography variant="h2" component="span" mb={2} textAlign="center">
                  Explore helpful resources for PG&amp;E Businesses
                </Typography>
              </Typography>
              <Typography
                variant="h4"
                fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
                component="span"
                textAlign="center"
              >
                Everything you need to reduce costs and maximize energy savings.
              </Typography>
            </Box>

            <Grid container columnSpacing={4}>
              <Grid item xs={12} md={3} display={{ xs: 'none', md: 'flex' }}>
                <FilterRail filtersData={filtersData} />
              </Grid>

              <Grid item xs={12} md={9}>
                <ResourceList resources={resources} pagination={pagination} />
              </Grid>
            </Grid>
          </Box>

          <MobileFilters filtersData={filtersData} />

          <ScrollToTop />
        </Container>
      </Box>
    </>
  );
}

Businesses.propTypes = {
  resources: PropTypes.array,
  pagination: PropTypes.object,
  filters_data: PropTypes.array,
  jumboImage: PropTypes.string,
};

Businesses.layout = BusinessLayout;

export default Businesses;
