import { Box, Button, Dialog, Typography } from '@mui/material';
import ButtonLink from '../ButtonLink';

export default function EventModal({ events, onClose }) {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="event-modal-heading"
      BackdropProps={{
        sx: { backgroundColor: 'rgba(0, 0, 0, 0.65)', backdropFilter: 'blur(3px)' },
      }}
      PaperProps={{
        sx: { borderRadius: '12px', maxWidth: 450 },
      }}
    >
      <Box pt={2.5} pb={2} px={2}>
        {events.map((event) => (
          <Box key={event.id} sx={{ '&:not(:first-of-type)': { mt: 4 } }}>
            <Typography
              fontWeight="bold"
              fontSize="1.125rem"
              textAlign="center"
              color="blue.500"
              mb={1}
              id="event-modal-heading"
            >
              {event.title} | {event.dateString}
            </Typography>
            <Typography fontSize="0.875rem" textAlign="center" mb={3}>
              {event.description}
            </Typography>
            <ButtonLink
              href={event.ctaUrl}
              variant="primary"
              external
              sx={{
                fontSize: '0.875rem',
                flexShrink: 0,
                alignSelf: 'center',
                borderRadius: 0,
                backgroundColor: 'orange.500',
                py: 1.5,
                px: 4,
                color: 'common.black',
                fontWeight: '600',
                textTransform: 'uppercase',
                '&:hover, &:focus': {
                  backgroundColor: 'orange.700',
                },
              }}
            >
              {event.ctaText}
            </ButtonLink>
          </Box>
        ))}
        <Button variant="text" onClick={onClose} sx={{ width: '100%', py: 2, mt: 1.5 }}>
          Close
        </Button>
      </Box>
    </Dialog>
  );
}
