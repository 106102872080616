import { Box, Grid, Typography } from '@mui/material';
import Container from '../../layout/Container';
import PropTypes from 'prop-types';
import CategoryTabs from './CategoryTabs';
import ProductResults from './ProductResults';
import ResourceResults from './ResourceResults';
import FilterRail from '../../components/FilterRail';
import MobileFilters from '../../components/MobileFilters';
import useDetailsDrawer from '../../hooks/useDetailsDrawer';
import DetailsDrawer from '../../components/DetailsDrawer';
import CompareFooter from '../../components/Compare/CompareFooter';
import Link from '../../components/Link';
import ScrollToTop from '../../components/ScrollToTop';

function Browse({
  category,
  categories,
  products,
  resources,
  goGreen,
  moreProductsLink,
  moreResourcesLink,
  filters_data: filtersData,
}) {
  const {
    isOpen: isResourceDrawerOpen,
    onOpen: onOpenResourceDrawer,
    onClose: onCloseResourceDrawer,
    detailItem: resource,
  } = useDetailsDrawer('resource', 'resource_id');

  const {
    isOpen: isProductDrawerOpen,
    onOpen: onOpenProductDrawer,
    onClose: onCloseProductDrawer,
    detailItem: product,
  } = useDetailsDrawer();

  return (
    <>
      {/* mobile top bar */}
      <Box
        display={{ xs: 'flex', md: 'none' }}
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="gray.200"
        py={2}
        px={{ xs: 2, sm: 3 }}
      >
        <Link
          href="/product-categories"
          arrowDirection="left"
          textTransform="uppercase"
          color="common.black"
        >
          <Typography variant="h4" component="p" fontWeight="bold" fontSize="0.875rem">
            Back
          </Typography>
        </Link>

        <MobileFilters filtersData={filtersData} variant="static" />
      </Box>

      <Container>
        <Box pt={{ xs: 4, sm: 6 }} display="flex" flexDirection="column" alignItems="center">
          <Typography component="h1" display="flex" flexDirection="column">
            <Typography
              variant="h4"
              component="span"
              fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
              fontWeight="bold"
              textTransform="uppercase"
              textAlign="center"
              mb={2.5}
            >
              Top Energy Products
            </Typography>
            <Typography variant="h2" component="span" mb={2} textAlign="center">
              Shop only the most energy efficient products in your price range.
            </Typography>
          </Typography>
          <Typography
            fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
            component="span"
            textAlign="center"
          >
            All products are{' '}
            <Typography
              component="span"
              fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
              fontWeight="bold"
              color="blue.400"
            >
              ENERGY STAR<sup>®</sup>
            </Typography>{' '}
            certified where applicable.
          </Typography>

          <Box width="100%" my={{ xs: 4, sm: 7 }}>
            <CategoryTabs selectedCategory={category} categories={categories} />
          </Box>
        </Box>

        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={3} display={{ xs: 'none', md: 'flex' }}>
            <FilterRail filtersData={filtersData} />
          </Grid>

          <Grid item xs={12} md={9}>
            <ProductResults
              products={products}
              category={category}
              goGreen={goGreen}
              moreProductsLink={moreProductsLink}
              onOpenProductDrawer={onOpenProductDrawer}
              onOpenResourceDrawer={onOpenResourceDrawer}
            />
            <ResourceResults
              resources={resources}
              moreResourcesLink={moreResourcesLink}
              onOpenResourceDrawer={onOpenResourceDrawer}
            />
          </Grid>
        </Grid>

        <ScrollToTop />
      </Container>

      <CompareFooter />

      <DetailsDrawer
        isOpen={isResourceDrawerOpen}
        onClose={onCloseResourceDrawer}
        detailItem={resource}
        isProduct={false}
        onShowProduct={onOpenProductDrawer}
      />

      <DetailsDrawer
        isOpen={isProductDrawerOpen}
        onClose={onCloseProductDrawer}
        detailItem={product}
        onShowResource={onOpenResourceDrawer}
      />
    </>
  );
}

Browse.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    hasRebates: PropTypes.bool,
    showGogreen: PropTypes.bool,
  }),
  categories: PropTypes.array,
  products: PropTypes.array,
  resources: PropTypes.array,
  goGreen: PropTypes.shape({
    showGoGreen: PropTypes.bool,
    props: PropTypes.object,
  }),
  moreProductsLink: PropTypes.string,
  moreResourcesLink: PropTypes.string,
  filters_data: PropTypes.array,
};

export default Browse;
