const generateCheckboxFilterUrl = ({ id, items }) => {
  // don't include filters that are unchanged
  if (items.every(({ checked }) => !checked)) return null;
  // special case where all items are checked, needs to be differentiated from all unchecked
  if (items.length > 1 && (items.every(({ checked }) => checked))) return `${id}=-`;

  // optimization so that URL has fewest possible items (either all checked or unchecked items)
  const useExclusiveApproach =
    items.length != 1 &&
    items.filter(({ checked }) => checked).length >= Math.ceil(items.length / 2);

  const urlItems = items.filter(({ checked }) => (useExclusiveApproach ? !checked : checked));
  const urlItemsString = urlItems.map(({ id }) => id).join(',');
  return `${id}=${useExclusiveApproach ? '-' : ''}${urlItemsString}`;
};

const generateSliderFilterUrl = ({ id, max, min, value_max: valueMax, value_min: valueMin }) => {
  // don't include if values are default min and max
  if (max === valueMax && min === valueMin) return null;
  return `${id}=${valueMin},${valueMax}`;
};

export const generateFiltersUrl = (filters) => {
  const filterStrings = filters
    .map((filter) => {
      if (filter.type === 'checkbox') {
        return generateCheckboxFilterUrl(filter);
      } else if (filter.type === 'slider') {
        return generateSliderFilterUrl(filter);
      }
    })
    .filter((url) => url);

  return filterStrings.join(';');
};

export const resetFiltersData = (filtersData) => {
  const checkboxFilters = filtersData.filter(({ type }) => type === 'checkbox');
  const sliderFilters = filtersData.filter(({ type }) => type === 'slider');

  const newSliderFilters = sliderFilters.map((filter) => ({
    ...filter,
    value_max: filter.max,
    value_min: filter.min,
  }));

  const newCheckboxFilters = checkboxFilters.map((filter) => ({
    ...filter,
    items: filter.items.map((filterItem) => ({
      ...filterItem,
      checked: false,
    })),
  }));

  return [...newSliderFilters, ...newCheckboxFilters];
};
