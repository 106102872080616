import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import ResourceCard from '../../components/ResourceCard';
import Link from '../../components/Link';

function ResourceResults({ resources, moreResourcesLink, onOpenResourceDrawer }) {
  if (!resources.length) return null;

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Typography
          fontSize={{ xs: '1.5rem', sm: '0.875rem' }}
          component="h2"
          fontWeight={{ xs: 500, sm: 'bold' }}
          textTransform={{ xs: 'none', sm: 'uppercase' }}
          mb={{ xs: 2.5, sm: 3 }}
        >
          Applicable Resources
        </Typography>

        {resources.map((resource) => (
          <Box key={resource.id} mb={2}>
            <ResourceCard resource={resource} onClick={onOpenResourceDrawer(resource.id)} />
          </Box>
        ))}

        <Link href={moreResourcesLink} ml="auto" mb={4} fontSize={{ xs: '1rem', sm: '1.125rem' }}>
          More resources
        </Link>
      </Box>
    </>
  );
}

ResourceResults.propTypes = {
  moreResourcesLink: PropTypes.string,
  resources: PropTypes.array,
  onOpenResourceDrawer: PropTypes.func,
};

export default ResourceResults;
