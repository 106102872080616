import PropTypes from 'prop-types';
import { Box, Typography, Link as MuiLink } from '@mui/material';
import { Link as InertiaLink } from '@inertiajs/react';
import logo from '../assets/images/logo.svg';

const Link = ({ href, sx = {}, children }) => (
  <MuiLink
    component={InertiaLink}
    href={href}
    sx={{
      color: 'common.white',
      textDecoration: 'none',
      ...sx,
    }}
  >
    {children}
  </MuiLink>
);

Link.propTypes = {
  highlighted: PropTypes.bool,
  href: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.node,
};

function ErrorPageHeader() {
  return (
    <Box position="relative">
      <Box component="header" py={2} px={{ xs: 2, sm: 4 }} bgcolor="blue.700" color="common.white">
        <Box component="nav" display="flex" alignItems="center" aria-label="primary">
          <Box
            component="a"
            href="#main-content"
            sx={{
              marginRight: '1rem',
              position: 'fixed',
              transform: 'translateX(-200%)',
              transition: 'transform 0.3s',
              color: 'common.white',
              '&:focus': {
                position: 'static',
                transform: 'translateX(0)',
              },
            }}
          >
            Skip to main content
          </Box>

          <Box component={InertiaLink} href="/" display="flex">
            <Box
              component="img"
              height={49}
              width={49}
              src={logo}
              alt="PG&amp;E Logo - Energy Action Guide Home"
              sx={{ mr: 3 }}
            />
          </Box>
          <Link
            href="/"
            sx={{
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            <Typography
              variant="h4"
              component="p"
              fontWeight="bold"
              fontSize="1.125rem"
              textTransform="uppercase"
              maxWidth="146px"
              mr={4}
            >
              PG&amp;E Energy Action Guide
            </Typography>
          </Link>

          <Box
            component="ul"
            ml="auto"
            display={{ xs: 'none', md: 'flex' }}
            alignItems="center"
            sx={{
              listStyle: 'none',
            }}
          >
            <Box component="li" ml={5}>
              <Link href="/products">
                <Typography textTransform="uppercase">Products</Typography>
              </Link>
            </Box>
            <Box component="li" ml={5}>
              <Link href="/resources">
                <Typography textTransform="uppercase">Resources</Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ErrorPageHeader;
