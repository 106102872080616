import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import Link from '../../components/Link';

export default function CompareHeader({ heading, backUrl }) {
  return (
    <Container maxWidth="xl">
      <Link href={backUrl} arrowDirection="left" textTransform="uppercase" color="common.black">
        <Typography variant="h4" fontSize={{ xs: '0.875rem', sm: '1rem' }} fontWeight="bold">
          Back
        </Typography>
      </Link>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mb={{ xs: 1, md: 6 }}
        mt={{ xs: 2, md: 0 }}
      >
        <Typography display="flex" variant="h1" flexDirection="column">
          <Typography
            variant="h2"
            component="span"
            mb={{ xs: 2.5, md: 2 }}
            textAlign="center"
            fontWeight={500}
          >
            {heading}
          </Typography>
          <Typography
            fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
            component="span"
            mb={{ xs: 0, md: 5 }}
            textAlign="center"
            fontWeight={500}
          >
            All products are{' '}
            <Typography
              component="span"
              fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
              fontWeight="bold"
              color="blue.400"
            >
              ENERGY STAR<sup>®</sup>
            </Typography>{' '}
            certified where applicable.
          </Typography>
        </Typography>
      </Box>
    </Container>
  );
}
