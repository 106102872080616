import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { StarIconFilled } from '../../assets/icons/icons';
import energyStarMostEfficient from '../../assets/images/energy-star-most-efficient.png';
import { formatAsThousands } from '../../utils/formatters';
import PriceRankDollars from '../PriceRankDollars';

function ProductDetailsAccolades({ product }) {
  const { priceRankNum, priceRankText, reviewAverage, reviewCount, isEnergyStarMostEfficient } =
    product;

  return (
    <Box
      py={1.5}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      borderTop="1px solid"
      borderBottom="1px solid"
      borderColor="gray.200"
      flexWrap="wrap"
    >
      <Box display="flex" alignItems="center">
        <PriceRankDollars priceRank={priceRankNum} />
        <Typography variant="h4" component="span" ml={0.5}>
          {priceRankText} price
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography>{reviewAverage || 0}/5</Typography>
        <StarIconFilled aria-label="stars" />

        <Typography ml={1}>
          ({formatAsThousands(reviewCount)} {reviewCount === 1 ? 'review' : 'reviews'})
        </Typography>
      </Box>
      {isEnergyStarMostEfficient && (
        <Box
          component="img"
          src={energyStarMostEfficient}
          alt="Energy Star Most Efficient 2022"
          height={60}
        />
      )}
    </Box>
  );
}

ProductDetailsAccolades.propTypes = {
  product: PropTypes.shape({
    priceRankNum: PropTypes.number,
    priceRankText: PropTypes.string,
    reviewAverage: PropTypes.number,
    reviewCount: PropTypes.number,
    isEnergyStarMostEfficient: PropTypes.bool,
  }),
};

export default ProductDetailsAccolades;
