import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import DetailsDrawer from '../../components/DetailsDrawer';
import useDetailsDrawer from '../../hooks/useDetailsDrawer';
import CompareHeader from './CompareHeader';
import FeaturesTable from './FeaturesTable';
import MobileCompareContent from './MobileCompareContent';
import OfferButtons from './OfferButtons';
import ProductsGrid from './ProductsGrid';

export default function Compare({ products, heading, backUrl }) {
  const {
    isOpen: isProductDrawerOpen,
    onOpen: onOpenProductDrawer,
    onClose: onCloseProductDrawer,
    detailItem: product,
  } = useDetailsDrawer();

  const {
    isOpen: isResourceDrawerOpen,
    onOpen: onOpenResourceDrawer,
    onClose: onCloseResourceDrawer,
    detailItem: resource,
  } = useDetailsDrawer('resource', 'resource_id');

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box my={{ xs: 2, md: 4 }}>
      <CompareHeader backUrl={backUrl} heading={heading} />

      {isSmallScreen ? (
        <MobileCompareContent products={products} onOpenProductDrawer={onOpenProductDrawer} />
      ) : (
        <Box px={2}>
          <ProductsGrid products={products} onOpenProductDrawer={onOpenProductDrawer} />
          <FeaturesTable products={products} />
          <OfferButtons products={products} onOpenProductDrawer={onOpenProductDrawer} />
        </Box>
      )}

      <DetailsDrawer
        isOpen={isProductDrawerOpen}
        onClose={onCloseProductDrawer}
        detailItem={product}
        onShowResource={onOpenResourceDrawer}
      />

      {/* second drawer for displaying a featured resource */}
      <DetailsDrawer
        isOpen={isResourceDrawerOpen}
        onClose={onCloseResourceDrawer}
        detailItem={resource}
        isProduct={false}
        onShowProduct={onOpenProductDrawer}
      />
    </Box>
  );
}
