import PropTypes from 'prop-types';
import { Box, Link as MuiLink, Typography } from '@mui/material';
import CondensedProductCard from '../../components/CondensedProductCard';
import Link from '../../components/Link';
import CategoryTabs from '../Browse/CategoryTabs';
import { PriceTagIcon } from '../../assets/icons/icons';
import { PRODUCT_CATEGORY_ICONS } from '../../assets/icons/productCategoryIcons';

function TopProducts({ categories, selectedCategory, onOpenProductDrawer }) {
  return (
    <Box width="100%" display="flex" flexDirection="column">
      <CategoryTabs categories={categories} selectedCategory={selectedCategory} />

      <Box mt={{ xs: 4, md: 7 }} mb={{ xs: 4, md: 5 }}>
        <Box display="flex" justifyContent="space-between" overflow={{ xs: 'scroll', md: 'auto' }}>
          {selectedCategory.products.map((product) => (
            <Box key={product.id} minWidth={{ xs: 255, md: 'auto' }} maxWidth={400} flex={1}>
              <CondensedProductCard product={product} onClick={onOpenProductDrawer(product.id)} />
            </Box>
          ))}
          <Box
            display={{ xs: 'flex', md: 'none' }}
            minWidth={{ xs: 255, md: 'auto' }}
            maxWidth={400}
            flex={1}
            sx={{
              m: 1,
              backgroundColor: 'common.white',
              boxShadow: 3,
              '&:hover, &:focus': {
                boxShadow: 5,
              },
            }}
          >
            <MuiLink
              href={selectedCategory.link}
              aria-label={`See all ${selectedCategory.name}`}
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                textDecoration: 'none',
                px: 2,
              }}
            >
              <Box height={60} width={60}>
                {PRODUCT_CATEGORY_ICONS[selectedCategory.handle]}
              </Box>
              <Typography color="common.black" fontSize="0.875rem" fontWeight={500} mt={1} mb={0.5}>
                See all
              </Typography>
              <Typography
                color="common.black"
                fontSize="1.75rem"
                fontWeight={500}
                textAlign="center"
              >
                {selectedCategory.name}
              </Typography>
            </MuiLink>
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection={{ xs: 'column-reverse', sm: 'row' }}
        justifyContent={{ xs: 'center', md: 'space-between' }}
        alignItems="center"
        pb={2}
      >
        <Box display="flex" alignItems="center" px={2} py={1} bgcolor="gray.200" borderRadius="4px">
          <PriceTagIcon />
          <Typography ml={1} fontSize={{ xs: '0.875rem', sm: '1rem' }} fontWeight="500">
            Rebate Eligible
          </Typography>
        </Box>
        <Link
          href={selectedCategory.link}
          ml={{ xs: 0, sm: 'auto' }}
          sx={{ display: { xs: 'none', md: 'flex' } }}
          fontSize="1.125rem"
          flexShrink={0}
        >
          See More {selectedCategory.name}
        </Link>
      </Box>
      <Box borderTop="1px solid" borderColor="gray.300" pt={2}>
        <Typography
          variant="body2"
          fontSize={{ xs: '0.75rem', sm: '0.875rem' }}
          color="gray.500"
          textAlign={{ xs: 'center', sm: 'left' }}
          mr={4}
          mt={{ xs: 1, sm: 0 }}
        >
          * PG&amp;E does not profit from the retail sales revenue of the products on this site.
          <br />
          ** Portable generators meet California Air Resources Board compliance.
        </Typography>
      </Box>
    </Box>
  );
}

TopProducts.propTypes = {
  categories: PropTypes.array,
  selectedCategory: PropTypes.shape({
    products: PropTypes.array,
    name: PropTypes.string,
    link: PropTypes.string,
  }),
  onOpenProductDrawer: PropTypes.func,
};

export default TopProducts;
