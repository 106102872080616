import { Box } from '@mui/material';
import ProductCard from '../../components/ProductCard';
import Link from '../../components/Link';

function ProductCategory({ productCategory, onOpenProductDrawer }) {
  const { products, link, name } = productCategory;

  if (!products.length) return null;

  return (
    <Box display="flex" flexDirection="column">
      {products.map((product) => (
        <Box key={product.id} mb={2}>
          <ProductCard product={product} onClick={onOpenProductDrawer(product.id)} />
        </Box>
      ))}
      <Link href={link} ml="auto" mb={4} fontSize={{ xs: '1rem', sm: '1.125rem' }}>
        More {name}
      </Link>
    </Box>
  );
}

export default function ProductResults({ productCategories, onOpenProductDrawer }) {
  return productCategories.map((category) => (
    <ProductCategory
      key={category.id}
      productCategory={category}
      onOpenProductDrawer={onOpenProductDrawer}
    />
  ));
}
