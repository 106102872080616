import React from 'react';
import { Box, Grid } from '@mui/material';
import CondensedProductCard from '../../components/CondensedProductCard';
import CompareProductSavings from './CompareProductSavings';

export default function ProductsGrid({ products, onOpenProductDrawer }) {
  return (
    <Grid container spacing={4} justifyContent="center">
      <Grid item xs={2} />
      {products.map((product) => (
        <Grid
          item
          key={product.id}
          xs={products.length === 2 ? 4 : 2.66}
          display="flex"
          justifyContent="center"
        >
          <Box minWidth={{ xs: 255, md: 'auto' }} maxWidth={310} flex={1} height="100%">
            <CondensedProductCard
              product={product}
              onClick={onOpenProductDrawer(product.id)}
              noPadding
            />
          </Box>
        </Grid>
      ))}
      <Grid item xs={2} />

      {/* New row */}

      <Grid item xs={2} />
      {products.map((product) => (
        <Grid
          item
          xs={products.length === 2 ? 4 : 2.66}
          key={product.id}
          display="flex"
          justifyContent="center"
        >
          <CompareProductSavings product={product} />
        </Grid>
      ))}
      <Grid item xs={2} />
    </Grid>
  );
}
