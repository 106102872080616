import { Box, Typography, Link, useMediaQuery, useTheme } from '@mui/material';
import Imgix from 'react-imgix';
import { RightArrowIcon } from '../assets/icons/icons';

function GoGreenBanner(props) {
  const { onOpenResourceDrawer, resourceId, imageUrl } = props;
  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      position="relative"
      onClick={onOpenResourceDrawer(resourceId)}
      backgroundColor="#627C41"
      pl={2}
      pr={2}
      py={{ xs: 2, sm: 0 }}
      mb={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        cursor: 'pointer',
      }}
    >
      <Imgix
        src={imageUrl}
        alt="Go Green Financing"
        width={isXSmallScreen ? 76 : 150}
        height={isXSmallScreen ? 38 : 80}
      />
      <Box color={'common.white'}>
        <Typography
          component="h3"
          flexGrow={1}
          fontWeight="bold"
          fontSize={{ xs: '0.8125rem', sm: '1.125rem' }}
          textAlign={{ xs: 'left', sm: 'center' }}
          ml={{ xs: 1, sm: 0 }}
          pr={{ xs: 0.75, sm: 2 }}
        >
          Finance home upgrades and installation for projects over $2,500.
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Link
          color={'common.white'}
          onClick={onOpenResourceDrawer(resourceId)}
          aria-label={`Go Green Financing - Learn More (opens details dialog)`}
          fontWeight="bold"
          component="button"
          href="#"
          tabIndex="0"
          sx={{ display: { xs: 'none', lg: 'block' }, mr: 0.5 }}
        >
          Learn More
        </Link>
        <RightArrowIcon fill="#ffffff" role="presentation" />
      </Box>
    </Box>
  );
}

export default GoGreenBanner;
