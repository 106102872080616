import PropTypes from 'prop-types';
import { Link as InertiaLink } from '@inertiajs/react';
import { Link } from '@mui/material';

function ButtonLink({
  href,
  fullWidth,
  variant = 'primary',
  external,
  disabled,
  sx = {},
  children,
  ...props
}) {
  const getVariants = ({ disabled }) => ({
    primary: {
      backgroundColor: 'orange.500',
      color: 'gray.700',
      '&:hover, &:focus': {
        backgroundColor: 'orange.700',
      },
      '&:active': {
        backgroundColor: 'orange.900',
      },
    },
    secondary: {
      backgroundColor: 'transparent',
      color: 'common.white',
      border: '1px solid',
      borderColor: 'common.white',
      '&:hover, &:focus': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
      },
      '&:active': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
      },
    },
    secondaryInverted: {
      backgroundColor: 'common.white',
      color: 'blue.500',
      border: '1px solid',
      borderColor: 'blue.500',
      '&:hover, &:focus': {
        backgroundColor: 'gray.200',
      },
      '&:active': {
        backgroundColor: 'gray.300',
      },
    },
    secondaryFilled: {
      backgroundColor: disabled ? 'grey.300' : 'blue.500',
      color: disabled ? 'grey.600' : 'common.white',
      border: '1px solid',
      borderColor: disabled ? 'grey.300' : 'blue.500',
      cursor: disabled ? 'auto' : 'pointer',
      '&:hover, &:focus': {
        backgroundColor: disabled ? 'grey.300' : 'blue.600',
      },
      '&:active': {
        backgroundColor: disabled ? 'grey.300' : 'blue.700',
      },
    },
  });

  return (
    <Link
      component={external ? 'a' : InertiaLink}
      href={href}
      target={external ? '_blank' : '_self'}
      disabled={disabled}
      {...props}
      sx={{
        display: 'block',
        textAlign: 'center',
        width: fullWidth ? '100%' : 'auto',
        px: { xs: fullWidth ? 0 : 2.5, sm: fullWidth ? 0 : 9 },
        py: 2,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        textDecoration: 'none',
        fontSize: { xs: '0.875rem', sm: '1rem' },
        pointerEvents: disabled ? 'none' : 'auto',
        ...getVariants({ disabled })[variant],
        ...sx,
      }}
    >
      {children}
    </Link>
  );
}

ButtonLink.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  href: PropTypes.string.isRequired,
  external: PropTypes.bool,
  sx: PropTypes.object,
  variant: PropTypes.string,
};

export default ButtonLink;
