import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@mui/material';
import Link from '../../components/Link';
import Container from '../../layout/Container';

const ContentLink = ({ text, href, external = true }) => (
  <Grid
    item
    xs={12}
    md={6}
    mt={{ xs: 3, md: 0 }}
    pb={{ xs: 3, md: 0 }}
    borderBottom={{ xs: '1px solid', md: 'none' }}
    borderColor={{ xs: 'gray.200' }}
  >
    <Typography variant="h2" mb={3}>
      {text}
    </Typography>
    <Link
      href={href}
      sx={{ fontSize: { xs: '0.875rem', sm: '1rem' } }}
      textTransform="uppercase"
      external={external}
    >
      Learn More
    </Link>
  </Grid>
);

ContentLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  external: PropTypes.bool,
};

function ContentLinks() {
  return (
    <Container>
      <Box mb={{ xs: 6, sm: 14 }}>
        <Box borderTop={{ xs: 'none', md: '1px solid' }} borderColor={{ md: 'gray.200' }}>
          <Grid container columnSpacing={4} py={{ xs: 0, md: 5 }}>
            <ContentLink
              text="Learn more about backup power"
              href="https://www.pge.com/pge_global/common/pdfs/safety/electrical-safety/electric-generator-safety/FINAL-CWSP-Backup-Power-Fact-Sheet-20211028.pdf"
            />
            <ContentLink
              text="Sign up for alerts"
              href="https://www.pge.com/en_US/residential/outages/public-safety-power-shuttoff/psps-updates-and-alerts.page"
            />
          </Grid>
        </Box>

        <Box borderTop={{ xs: 'none', md: '1px solid' }} borderColor={{ md: 'gray.200' }}>
          <Grid
            container
            columnSpacing={4}
            py={{ xs: 0, md: 5 }}
            borderTop={{ xs: 'none', md: '1px solid' }}
            borderColor={{ md: 'gray.200' }}
          >
            <ContentLink
              text="Learn more about safety"
              href="https://www.pge.com/en_US/safety/electrical-safety/electric-generator-safety/electric-generator-safety.page"
            />
            <ContentLink
              text="Learn more about planned outages"
              href="https://www.pge.com/en_US/residential/outages/planning-and-preparedness/planned-maintenance-outages/planned-maintenance-outages.page"
            />
          </Grid>
        </Box>

        <Box borderTop={{ xs: 'none', md: '1px solid' }} borderColor={{ md: 'gray.200' }}>
          <Grid
            container
            columnSpacing={4}
            py={{ xs: 0, md: 5 }}
            borderTop={{ xs: 'none', md: '1px solid' }}
            borderBottom={{ xs: 'none', md: '1px solid' }}
            borderColor={{ md: 'gray.200' }}
          >
            <ContentLink
              text="Shop backup power options"
              href="/products/generators"
              external={false}
            />
            <ContentLink
              text="Apply for rebates"
              href="https://energyinsight.secure.force.com/eRebateApplication/?_ga=2.28931324.1711679880.1620965527-551285483.1611253744
"
            />
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

export default ContentLinks;
