import { Box, Grid, Typography } from '@mui/material';
import FilterRail from '../../components/FilterRail';
import MobileFilters from '../../components/MobileFilters';
import Container from '../../layout/Container';
import ResourceList from './ResourceList';
import PropTypes from 'prop-types';
import Link from '../../components/Link';
import ScrollToTop from '../../components/ScrollToTop';

function Resources({ resources, pagination, filters_data: filtersData }) {
  return (
    <>
      {/* mobile top bar */}
      <Box
        display={{ xs: 'flex', md: 'none' }}
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="gray.200"
        py={2}
        px={{ xs: 2, sm: 3 }}
      >
        <Link href="/" arrowDirection="left" textTransform="uppercase" color="common.black">
          <Typography variant="h4" component="p" fontWeight="bold" fontSize="0.875rem">
            Back
          </Typography>
        </Link>

        <MobileFilters filtersData={filtersData} variant="static" />
      </Box>
      <Container>
        <Box display="flex" flexDirection="column" pt={{ xs: 4, md: 6 }} pb={10}>
          <Box display="flex" flexDirection="column" alignItems="center" mb={{ xs: 4, md: 10 }}>
            <Typography variant="h1" display="flex" flexDirection="column">
              <Typography
                variant="h4"
                fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
                component="span"
                mb={2}
                textTransform="uppercase"
                fontWeight="bold"
                textAlign="center"
              >
                PG&amp;E Resources
              </Typography>
              <Typography variant="h2" component="span" mb={2} textAlign="center">
                Explore resources for PG&amp;E Customers
              </Typography>
            </Typography>
            <Typography
              variant="h4"
              fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
              component="span"
              textAlign="center"
            >
              Everything you need to reduce costs and maximize savings
            </Typography>
          </Box>

          <Grid container columnSpacing={4}>
            <Grid item xs={12} md={3} display={{ xs: 'none', md: 'flex' }}>
              <FilterRail filtersData={filtersData} />
            </Grid>

            <Grid item xs={12} md={9}>
              <ResourceList resources={resources} pagination={pagination} />
            </Grid>
          </Grid>
        </Box>

        <ScrollToTop />
      </Container>
    </>
  );
}

Resources.propTypes = {
  resources: PropTypes.array,
  pagination: PropTypes.object,
  filters_data: PropTypes.array,
};

export default Resources;
