import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Link from '../Link';

function ProductCategories({ categories, handleLinkClick }) {
  return (
    <>
      {categories.map((category) => (
        <Box key={category.id} mb="18px" display="flex" alignItems="center">
          <Box
            height={72}
            width={72}
            flexShrink={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              component="img"
              alt={category.name}
              src={category.imageUrl}
              maxHeight={72}
              maxWidth={72}
              mr={2}
            />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Link href={category.link} arrowIcon={false} onClick={handleLinkClick}>
              <Typography variant="h4" component="span" color="blue.500" fontWeight="medium">
                {category.name}
              </Typography>
            </Link>
          </Box>
        </Box>
      ))}
    </>
  );
}

ProductCategories.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
      imageUrl: PropTypes.string,
    }),
  ),
  handleLinkClick: PropTypes.func,
};

export default ProductCategories;
