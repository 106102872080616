import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Link from '../Link';
import NoResults from './NoResults';

function Resources({ resources, handleLinkClick }) {
  if (!resources.length) {
    return <NoResults handleLinkClick={handleLinkClick} />;
  }

  return (
    <>
      {resources.map((resource) => (
        <Box key={resource.id} mb="18px" display="flex" alignItems="center">
          <Box
            height={72}
            width={72}
            flexShrink={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              component="img"
              alt={resource.name}
              src={resource.imageUrl}
              maxHeight={72}
              maxWidth={72}
              mr={2}
            />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Link
              href={`/resources?resource_id=${resource.id}`}
              arrowIcon={false}
              onClick={handleLinkClick}
            >
              <Typography variant="h4" component="span" color="blue.500" fontWeight="medium">
                {resource.name}
              </Typography>
            </Link>
            <Typography color="gray.500">{resource.description}</Typography>
          </Box>
        </Box>
      ))}
    </>
  );
}

Resources.propTypes = {
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      imageUrl: PropTypes.string,
      description: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  handleLinkClick: PropTypes.func,
};

export default Resources;
