import { Box, Typography } from '@mui/material';
import React from 'react';
import ToggleGroup from './ToggleGroup';

export default function GoalsInputs({ goals, selectedGoals, setSelectedGoals }) {
  const handleChange = (e, newGoals) => {
    setSelectedGoals(newGoals);
  };

  return (
    <Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        mb={{ xs: 2, md: 5 }}
        alignItems={{ xs: 'flex-start', md: 'flex-end' }}
      >
        <Typography variant="h3" fontSize={{ xs: '1rem', sm: '1.5rem' }} fontWeight="bold">
          What energy goals do you have?
        </Typography>
        <Typography
          variant="h5"
          fontSize={{ xs: '0.75rem', sm: '1rem' }}
          component="span"
          color="gray.500"
          ml={{ xs: 0, md: 2 }}
        >
          Select your top choice(s).
        </Typography>
      </Box>
      <ToggleGroup options={goals} selectedOptions={selectedGoals} handleChange={handleChange} />
    </Box>
  );
}
