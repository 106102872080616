import { Link as InertiaLink } from '@inertiajs/react';
import { Link as MuiLink, Popover, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const Link = ({ href, onClose, children }) => (
  <MuiLink
    component={InertiaLink}
    href={href}
    onClick={onClose}
    sx={{
      textDecoration: 'none',
      textTransform: 'uppercase',
      color: 'common.black',
    }}
  >
    <Typography variant="h5" component="span" display="block" mt={2.5} mb={5}>
      {children}
    </Typography>
  </MuiLink>
);

Link.propTypes = {
  href: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

function MobileNavMenu({ anchorEl, onClose, open }) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      id="mobile-nav-menu"
      marginThreshold={0}
      PaperProps={{
        sx: {
          borderRadius: 0,
          width: '100%',
          maxWidth: '100%',
          left: 0,
          right: 0,
          px: 5,
          py: 4,
        },
      }}
    >
      <Link href="/product-categories" onClose={onClose}>
        Products
      </Link>
      <Link href="/resources" onClose={onClose}>
        Resources
      </Link>
      <Link href="/business" onClose={onClose}>
        Businesses
      </Link>
      <Link href="/resiliency" onClose={onClose}>
        Prepare for Outages
      </Link>
    </Popover>
  );
}

MobileNavMenu.propTypes = {
  anchorEl: PropTypes.instanceOf(Element),
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default MobileNavMenu;
