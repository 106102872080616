import { Box } from '@mui/material';
import ResourceCard from '../../components/ResourceCard';
import EmailForm from './EmailForm';

export default function ResourceResults({ resources, onOpenResourceDrawer, isEmailSubmitted }) {
  if (!resources.length) return null;

  const firstTwoResources = resources.slice(0, 2);
  const remainingResources = resources.slice(2);

  return (
    <Box display="flex" flexDirection="column">
      {firstTwoResources.map((resource) => (
        <Box key={resource.id} mb={2}>
          <ResourceCard resource={resource} onClick={onOpenResourceDrawer(resource.id)} />
        </Box>
      ))}
      <EmailForm isEmailSubmitted={isEmailSubmitted} />
      {remainingResources.map((resource) => (
        <Box key={resource.id} mb={2}>
          <ResourceCard resource={resource} onClick={onOpenResourceDrawer(resource.id)} />
        </Box>
      ))}
    </Box>
  );
}
