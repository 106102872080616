import PropTypes from 'prop-types';
import { Container as MuiContainer } from '@mui/material';

function Container({ children, maxWidth = 'lg', ...props }) {
  return (
    <MuiContainer {...props} maxWidth={maxWidth}>
      {children}
    </MuiContainer>
  );
}

Container.propTypes = {
  children: PropTypes.node,
  maxWidth: PropTypes.string,
};

export default Container;
