import { Box, Grid, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import ButtonLink from '../ButtonLink';

function MobileCtaLinks({ resource }) {
  const { learnMoreUrl, learnMoreCta, actionCta, actionUrl } = resource;

  const theme = useTheme();

  return (
    <Box
      display={{ xs: 'flex', sm: 'none' }}
      position="fixed"
      bottom={0}
      width="100%"
      backgroundColor="common.white"
      justifyContent="center"
      px={2}
      pb={2}
      py={3}
      boxShadow={theme.shadows[4]}
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Box>
            <ButtonLink
              variant="secondaryInverted"
              external
              href={learnMoreUrl}
              fullWidth
              sx={{ py: 1.25 }}
            >
              {learnMoreCta}
            </ButtonLink>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <ButtonLink variant="primary" external href={actionUrl} fullWidth sx={{ py: 1.25 }}>
            {actionCta}
          </ButtonLink>
        </Grid>
      </Grid>
    </Box>
  );
}

MobileCtaLinks.propTypes = {
  resource: PropTypes.shape({
    learnMoreCta: PropTypes.string,
    learnMoreUrl: PropTypes.string,
    actionCta: PropTypes.string,
    actionUrl: PropTypes.string,
  }),
};

export default MobileCtaLinks;
