import PropTypes from 'prop-types';
import { Box, Link, Typography, useTheme } from '@mui/material';
import ButtonLink from '../../components/ButtonLink';
import Container from '../../layout/Container';
import Jumbotron from '../../components/Jumbotron';
import StartingPoints from './StartingPoints';
import TopResults from './TopResults';

function Homepage({ categories, category, resources, jumboImage }) {
  const theme = useTheme();

  const smallScreenLinks = (
    <>
      <ButtonLink href="/product-categories" variant="primary" mr={0} mb={2}>
        Shop Now
      </ButtonLink>
      <ButtonLink href="/my-home" variant="secondaryInverted">
        Get Recommendations
      </ButtonLink>
      <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
        <Typography fontWeight={700} color="common.white">
          Programs to help you save.
        </Typography>
        <Link
          href="/resources"
          sx={{
            color: 'common.white',
            fontWeight: 700,
            textDecorationColor: theme.palette.common.white,
          }}
        >
          View Resources
        </Link>
      </Box>
    </>
  );

  const largeScreenLinks = (
    <>
      <ButtonLink href="/my-home" variant="primary" mr={3} mb={0}>
        Get Recommendations
      </ButtonLink>
      <ButtonLink href="/browse/thermostats" variant="secondaryInverted" mr={3} mb={0}>
        See Products
      </ButtonLink>
      <ButtonLink href="/resources" variant="secondaryInverted">
        See Resources
      </ButtonLink>
    </>
  );

  return (
    <Box>
      <Jumbotron bgImage={jumboImage} darken bgHeight={472}>
        <Container>
          <Box display="flex" flexDirection="column" height="100%" py={8}>
            <Typography variant="h1" color="common.white" textAlign="center" mb={5}>
              Product and program guidance to meet your unique energy needs.
            </Typography>
            <Box
              display={{ xs: 'flex', md: 'none' }}
              justifyContent="center"
              flexDirection="column"
            >
              {smallScreenLinks}
            </Box>
            <Box display={{ xs: 'none', md: 'flex' }} justifyContent="center" flexDirection="row">
              {largeScreenLinks}
            </Box>
          </Box>
        </Container>
      </Jumbotron>

      <Box my={{ xs: 2, md: 7 }} mx={{ xs: 2, md: 9 }}>
        <StartingPoints />
      </Box>

      <Box mb={{ xs: 0, md: 7 }} mx={{ xs: 0, md: 7 }}>
        <TopResults categories={categories} selectedCategory={category} resources={resources} />
      </Box>
    </Box>
  );
}

Homepage.propTypes = {
  product_categories: PropTypes.array,
  categories: PropTypes.array,
  category: PropTypes.object,
  resources: PropTypes.array,
  jumboImage: PropTypes.string,
};

export default Homepage;
