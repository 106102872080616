import { Box, Grid, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Button from './Button';

function MobileFiltersButtons({ handleReset, handleUpdate }) {
  const theme = useTheme();

  return (
    <Box
      display={{ xs: 'flex', md: 'none' }}
      position="fixed"
      bottom={0}
      left={0}
      width="100%"
      px={2}
      py={3}
      backgroundColor="common.white"
      boxShadow={theme.shadows[4]}
    >
      <Grid container columnSpacing={2.5}>
        <Grid item xs={6}>
          <Button
            sx={{
              width: '100%',
              backgroundColor: 'common.white',
              border: '1px solid',
              borderColor: 'blue.500',
              borderRadius: 0,
              py: 1,
              fontWeight: 'bold',
              '&:hover, &:focus': {
                backgroundColor: 'gray.100',
              },
            }}
            onClick={handleReset}
          >
            Reset
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            sx={{
              width: '100%',
              backgroundColor: 'orange.500',
              color: 'common.black',
              borderRadius: 0,
              border: '1px solid',
              borderColor: 'orange.500',
              py: 1,
              fontWeight: 'bold',
              '&:hover, &:focus': {
                backgroundColor: 'orange.700',
                borderColor: 'orange.700',
              },
            }}
            onClick={handleUpdate}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

MobileFiltersButtons.propTypes = {
  handleReset: PropTypes.func,
  handleUpdate: PropTypes.func,
};

export default MobileFiltersButtons;
