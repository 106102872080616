import PropTypes from 'prop-types';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import ProductCard from '../../components/ProductCard';
import GoGreenBanner from '../../components/GoGreenBanner';
import Link from '../../components/Link';
import NoResults from '../../components/NoResults';
import { PriceTagIcon } from '../../assets/icons/icons';

function ProductResults({
  products,
  category,
  goGreen,
  moreProductsLink,
  onOpenProductDrawer,
  onOpenResourceDrawer,
}) {
  if (!products.length) return <NoResults />;

  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { name: categoryName } = category;
  const [firstProduct, ...remainderProducts] = products;
  const { showGoGreen = false, props: goGreenProps = {} } = goGreen;

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            display={{ xs: 'none', sm: 'block' }}
            variant="h6"
            component="h2"
            fontWeight="bold"
            textTransform="uppercase"
            mb={3}
          >
            Top {categoryName}
          </Typography>
          {category.hasRebates && (
            <Box display="flex" alignItems="center" mb={{ xs: 2, sm: 3 }}>
              <PriceTagIcon
                height={isXSmallScreen ? 11.25 : 17}
                width={isXSmallScreen ? 11.25 : 16}
              />
              <Typography
                fontSize={{ xs: '0.75rem', sm: '1rem' }}
                fontWeight={500}
                ml={{ xs: 0.5, sm: 1 }}
                color="grey.600"
              >
                Rebate Eligible
              </Typography>
            </Box>
          )}
        </Box>

        <Box key={firstProduct.id} mb={2}>
          <ProductCard product={firstProduct} onClick={onOpenProductDrawer(firstProduct.id)} />
        </Box>

        {showGoGreen && (
          <GoGreenBanner onOpenResourceDrawer={onOpenResourceDrawer} {...goGreenProps} />
        )}

        {remainderProducts.map((product) => (
          <Box key={product.id} mb={2}>
            <ProductCard product={product} onClick={onOpenProductDrawer(product.id)} />
          </Box>
        ))}

        <Link href={moreProductsLink} ml="auto" mb={4} fontSize={{ xs: '1rem', sm: '1.125rem' }}>
          More {categoryName}
        </Link>
      </Box>
    </>
  );
}

ProductResults.propTypes = {
  categoryName: PropTypes.string,
  category: PropTypes.shape({
    name: PropTypes.string,
    hasRebates: PropTypes.bool,
    showGoGreen: PropTypes.bool,
  }),
  moreProductsLink: PropTypes.string,
  products: PropTypes.array,
  goGreen: PropTypes.shape({
    showGoGreen: PropTypes.bool,
    props: PropTypes.object,
  }),
  onOpenProductDrawer: PropTypes.func,
  onOpenResourceDrawer: PropTypes.func,
};

export default ProductResults;
