import React from 'react';
import { Typography } from '@mui/material';
import Container from '../../layout/Container';

const H2 = ({ children }) => (
  <Typography variant="h2" fontWeight="500" mt={5} mb={2}>
    {children}
  </Typography>
);

const H3 = ({ children }) => (
  <Typography variant="h3" mt={3} mb={2}>
    {children}
  </Typography>
);

export default function PrivacyPolicy() {
  return (
    <Container sx={{ my: 10 }}>
      <Typography textTransform="uppercase" fontWeight="bold">
        Energy Action Guide
      </Typography>
      <Typography variant="h1" fontWeight="500" mt={1} mb={4}>
        Bellawatt Privacy Policy
      </Typography>
      <Typography>
        This Privacy Policy outlines Bellawatt&apos;s framework for maintaining customer privacy
        while visiting and interacting with Energy Action Guide. We are committed to protecting your
        privacy and safeguarding your personal information. This Privacy Policy addresses how
        information is obtained, used, and shared when you use our Energy Action Guide. By using our
        Energy Action Guide, you agree to this Privacy Policy.
      </Typography>

      <H2>Definitions</H2>
      <Typography>
        &quot;You&quot; means any California consumer, Pacific Gas and Electric
        (&quot;PG&amp;E&quot;) customer, website visitor, or mobile application user.
      </Typography>
      <Typography>We may collect the following information from you:</Typography>
      <ul>
        <li>
          <Typography>
            Personal information: This includes your email address and any other information you
            provide to us when you submit a form or communicate with us.
          </Typography>
        </li>
        <li>
          <Typography>
            Usage information: This includes information about how you use our Energy Action Guide,
            such as the pages you visit, the features you use, and the searches you conduct.
          </Typography>
        </li>
        <li>
          <Typography>
            Device information: This includes information about the device you use to access our
            Energy Action Guide, such as the type of device, its operating system, and browser type.
          </Typography>
        </li>
      </ul>

      <H2>How We Collect Your Information</H2>
      <H3>What are Cookies?</H3>
      <Typography>
        Cookies are small text files that are placed on your device (such as your computer or mobile
        device) when you visit a website. Cookies are used by websites for various purposes, such as
        to remember your preferences, to personalize your experience, to analyze how the website is
        used, and maintain session information.
      </Typography>
      <H3>Energy Action Guide Cookies</H3>
      <Typography>
        We use cookies on our website to provide basic functionality to the website for purposes
        such as logging into the system, maintaining session information, and enhancing access to
        the features of this website.
      </Typography>
      <H3>Google Analytics</H3>
      <Typography>
        Our website uses Google Analytics to help us analyze how users use the website and target
        users with appropriate advertisements. The information generated by the cookie about your
        use of the website (including your IP address) will be transmitted to and stored by Google.{' '}
        <a href="https://policies.google.com/privacy?hl=en" target="_blank" rel="noreferrer">
          Read about Google&apos;s privacy practices.
        </a>
      </Typography>

      <H2>How We Use Your Information</H2>
      <Typography>We use your personal information to:</Typography>
      <ul>
        <li>
          <Typography>Provide our Energy Action Guide services and/or products to you</Typography>
        </li>
        <li>
          <Typography>Process your orders and payments</Typography>
        </li>
        <li>
          <Typography>
            Communicate with you about your orders, our services, and our policies
          </Typography>
        </li>
        <li>
          <Typography>Respond to your customer service inquiries and support needs</Typography>
        </li>
        <li>
          <Typography>Improve our Energy Action Guide website and services</Typography>
        </li>
        <li>
          <Typography>
            Send you promotional offers and marketing communications, if you have opted in to
            receive them
          </Typography>
        </li>
      </ul>
      <H3>Sale of Customer Data</H3>
      <Typography>
        Bellawatt does not currently sell personal customer data to any third party.
      </Typography>

      <H2>How We Share Your Information</H2>
      <Typography>We may share your personal information with:</Typography>
      <ul>
        <li>
          <Typography>
            <b>Service providers</b>: We may share your information with third-party service
            providers who perform services on our behalf, such as payment processing, shipping, and
            customer service.
          </Typography>
        </li>
        <li>
          <Typography>
            <b>Third-party analytics</b>: We use selected third parties to collect data about how
            you interact with our website and apps. This information may be used to, among other
            things, improve the functionality of our website and services.
          </Typography>
        </li>
        <li>
          <Typography>
            <b>Legal authorities</b>: We may also use your information to comply with legal
            obligations, enforce our policies, and protect our rights and the rights of our users.
          </Typography>
        </li>
      </ul>

      <H2>Your Choices</H2>
      <Typography>You have certain choices regarding your personal information:</Typography>
      <ul>
        <li>
          <Typography>
            You can choose not to provide us with certain information, but this may limit your
            ability to use certain features of our Energy Action Guide.
          </Typography>
        </li>
        <li>
          <Typography>
            You can opt-out of receiving marketing communications from us by clicking the
            &quot;unsubscribe&quot; link in the email you receive from us.
          </Typography>
        </li>
        <li>
          <Typography>
            To manage your cookie preferences, please use the &quot;Do Not Sell My Personal
            Information&quot; link in the footer of Energy Action Guide.
          </Typography>
          <ul>
            <li>
              <Typography>
                Additionally, you may disable our usage of cookies through your browser settings.
              </Typography>
            </li>
          </ul>
        </li>
      </ul>
      <Typography>
        We take reasonable measures to protect your personal information from unauthorized access,
        use, or disclosure. However, no method of transmission over the Internet, or method of
        electronic storage, is 100% secure. Therefore, we cannot guarantee its absolute security.
      </Typography>

      <H2>California Resident Information</H2>
      <Typography>
        California Civil Code Section 1798.83, also known as the &quot;Shine The Light&quot; law,
        permits our users who are California residents to request and obtain from us, once a year
        and free of charge, information about categories of personal information (if any) we
        disclosed to third parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the immediately preceding
        calendar year.
      </Typography>
      <Typography>
        If you are a California resident, you are entitled to certain privacy rights relating to
        your personal information. This section describes those rights and how you can exercise
        those rights:
      </Typography>
      <H3>Right to Know and Access Personal Information</H3>
      <Typography>
        You have the right to access and know what personal information Bellawatt collects about
        you, and how it uses, discloses, sells such personal information. You also have the right to
        request, to the extent technically feasible, a portable set of your personal information.
      </Typography>
      <H3>Right to Request Deletion of your Personal Information </H3>
      <Typography>
        You have the right to request that Bellawatt delete the personal information it has about
        you subject to certain limitations. If we do not delete your personal information for
        reasons permitted under applicable law, we will let you know.
      </Typography>
      <H3>Right to Correct Inaccurate Personal Information</H3>
      <Typography>
        You have the right to request Bellawatt to correct inaccurate personal information that we
        maintain about you.
      </Typography>
      <H3>Right to Opt Out of Sale of your Personal Information</H3>
      <Typography>
        You may have the right to opt out of the sale or sharing of your Personal Data.
      </Typography>

      <H2>Non-Discrimination</H2>
      <Typography>
        We will not discriminate or retaliate against you for exercising any of your privacy rights
        identified in this privacy policy.
      </Typography>

      <H2>Changes to this Privacy Policy</H2>
      <Typography>
        We may update this Privacy Policy from time to time by posting a new version on our Energy
        Action Guide. We encourage you to review this Privacy Policy periodically.
      </Typography>

      <H2>Contact Us</H2>
      <Typography>
        If you have any questions or concerns about this Privacy Policy, contact us at
        contact@bellawatt.com.
      </Typography>
    </Container>
  );
}
