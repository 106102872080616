import { router } from '@inertiajs/react';
import PropTypes from 'prop-types';
import Tabs from '../../components/Tabs';
import VisuallyHiddenText from '../../components/VisuallyHiddenText';
import useAriaLiveStatus from '../../hooks/useAriaLiveStatus';

function CategoryTabs({ categories, selectedCategory }) {
  const [ariaLiveStatus, setAriaLiveStatus] = useAriaLiveStatus();

  const handleCategoryChange = (categoryIdx) => {
    setAriaLiveStatus(`Showing top ${categories[categoryIdx].name}`);

    const newCategory = categories[categoryIdx];
    router.visit(newCategory.link, {
      preserveScroll: true,
      preserveState: true,
    });
  };

  return (
    <>
      <VisuallyHiddenText aria-live="polite" role="status">
        {ariaLiveStatus}
      </VisuallyHiddenText>
      <Tabs
        items={categories}
        activeItemIdx={categories.findIndex(({ id }) => id === selectedCategory.id)}
        onChange={handleCategoryChange}
        ariaLabel="Product categories"
        nextAriaLabel="Next category"
        prevAriaLabel="Previous category"
      />
    </>
  );
}

CategoryTabs.propTypes = {
  categories: PropTypes.array,
  selectedCategory: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};

export default CategoryTabs;
