import { useEffect, useState } from 'react';
import { Box, Typography, styled, Slider as MuiSlider } from '@mui/material';
import PropTypes from 'prop-types';
import { formatAsDollars, formatAsThousands } from '../utils/formatters';

const StyledSlider = styled(MuiSlider)(({ theme }) => ({
  color: theme.palette.blue[500],
  height: 8,
  padding: '72px 0 3px 0',
  marginBottom: 0,
  '& .MuiSlider-rail': {
    height: 3,
  },
  '& .MuiSlider-track': {
    border: 'none',
    height: 3,
  },
  '& .MuiSlider-thumb': {
    height: 14,
    width: 14,
    backgroundColor: theme.palette.blue[500],
    '&.Mui-focusVisible': {
      boxShadow: 'inherit',
      outline: `1px solid ${theme.palette.blue[300]}`,
      outlineOffset: 2,
    },
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-markLabel': {
    top: 55,
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: '0.75rem',
    background: 'unset',
    padding: 0,
    width: 35,
    height: 35,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: theme.palette.blue[500],
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    top: '-3px',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
}));

const calculateValue = (value, min, max, step) => {
  if (value === min || value === max) return value;
  return Math.round(value / step) * step;
};

function SliderFilter({ title, value, max, min, onChange, step, useDollars, ariaLabels }) {
  const [workingValue, setWorkingValue] = useState(value);

  useEffect(() => {
    setWorkingValue(value);
  }, [value[0], value[1]]);

  const marks = [
    {
      value: min,
      label: useDollars ? formatAsDollars(min) : min,
    },
    {
      value: max,
      label: useDollars ? formatAsDollars(max) : max,
    },
  ];

  return (
    <Box mb={3} mt={title ? 0 : 3}>
      {title && (
        <Typography fontSize="1rem" component="h3" fontWeight="bold" pb={5} pt={4}>
          {title}
        </Typography>
      )}
      <StyledSlider
        value={workingValue}
        max={max}
        min={min}
        step={step}
        scale={(value) => calculateValue(value, min, max, step)}
        valueLabelFormat={formatAsThousands}
        valueLabelDisplay="on"
        onChange={(e, newValue) => {
          setWorkingValue(newValue);
          if (!(e instanceof MouseEvent)) {
            onChange(newValue);
          }
        }}
        onChangeCommitted={(e) => {
          if (e instanceof MouseEvent) {
            onChange(workingValue);
          }
        }}
        getAriaLabel={(idx) => ariaLabels[idx]}
        marks={marks}
      />
    </Box>
  );
}

SliderFilter.propTypes = {
  title: PropTypes.string.isRequired,
  ariaLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
  useDollars: PropTypes.bool,
};

export default SliderFilter;
