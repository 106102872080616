import PropTypes from 'prop-types';
import { Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

function DropdownInput({
  id,
  variant = 'outlined',
  label,
  ariaLabel,
  value,
  onChange,
  options,
  fullWidth,
  size = 'medium',
  renderValue,
  ...rest
}) {
  const variants = {
    outlined: {
      formControlVariant: 'outlined',
      selectStyles: {},
    },
    standard: {
      formControlVariant: 'standard',
      selectStyles: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '0.875rem',
        color: 'gray.500',
      },
    },
  };

  const { formControlVariant, selectStyles } = variants[variant];

  return (
    <Box {...rest} width={fullWidth ? '100%' : 'auto'}>
      <FormControl
        variant={formControlVariant}
        fullWidth
        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        size={size}
      >
        <Box flexGrow={1}>
          {label && (
            <InputLabel
              hidden
              id={id}
              sx={{
                backgroundColor: '#FFF',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
              }}
            >
              {label}
            </InputLabel>
          )}
          <Select
            labelId={id}
            value={value}
            onChange={onChange}
            fullWidth
            aria-label={ariaLabel}
            renderValue={renderValue}
            sx={{
              backgroundColor: 'common.white',
              borderRadius: '4px',
              '&::after, &::before': {
                borderBottom: 'none',
              },
              ...selectStyles,
            }}
          >
            {options.map(({ value: val, children }) => (
              <MenuItem key={val} value={val}>
                {children}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </FormControl>
    </Box>
  );
}

DropdownInput.propTypes = {
  id: PropTypes.string.isRequired,
  variant: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string.isRequired,
  size: PropTypes.string,
  renderValue: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      children: PropTypes.node.isRequired,
    }),
  ),
};

export default DropdownInput;
