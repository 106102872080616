import { usePage, router } from '@inertiajs/react';
import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { MinusIcon, PlusIcon } from '../../assets/icons/icons';
import Button from '../Button';
import useAriaLiveStatus from '../../hooks/useAriaLiveStatus';
import VisuallyHiddenText from '../VisuallyHiddenText';

const MobileCompareButton = ({ product, isSelected, alignment, handleClick, handleDelete }) => {
  return (
    <Box
      position="absolute"
      top={-1}
      left={alignment === 'left' ? -1 : 'auto'}
      right={alignment === 'right' ? -1 : 'auto'}
      bgcolor={isSelected ? 'blue.500' : 'transparent'}
      border="1px solid"
      borderColor={isSelected ? 'blue.500' : 'gray.500'}
      color="common.white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        '&:hover': {
          backgroundColor: isSelected ? 'blue.500' : 'gray.100',
        },
      }}
    >
      <Button
        sx={{ minWidth: 'auto', p: 0, textTransform: 'none' }}
        aria-label={
          isSelected
            ? `Remove the ${product.name} from product comparison.`
            : `Compare the ${product.name} to other similar products.`
        }
        onClick={isSelected ? handleDelete : handleClick}
      >
        <Typography
          color={isSelected ? 'common.white' : 'gray.500'}
          fontSize="0.75rem"
          fontWeight={700}
          px={1}
        >
          Compare
        </Typography>
        <Box
          bgcolor={isSelected ? 'blue.500' : 'gray.500'}
          height={24}
          width={24}
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          {isSelected ? <MinusIcon /> : <PlusIcon />}
        </Box>
      </Button>
    </Box>
  );
};

const CompareButton = ({ product, isSelected, alignment, handleClick, handleDelete }) => {
  const theme = useTheme();

  return (
    <Box
      position="absolute"
      top={-1}
      left={alignment === 'left' ? -1 : 'auto'}
      right={alignment === 'right' ? -1 : 'auto'}
      height={24}
      width={24}
      bgcolor={isSelected ? 'blue.500' : 'transparent'}
      border="1px solid"
      borderColor="blue.500"
      color="common.white"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        '&:hover': {
          backgroundColor: isSelected ? 'blue.500' : 'gray.100',
        },
      }}
    >
      <Tooltip title="Compare" arrow placement="right">
        <Button
          sx={{ minWidth: 'auto' }}
          aria-label={
            isSelected
              ? `Remove the ${product.name} from product comparison.`
              : `Compare the ${product.name} to other similar products.`
          }
          onClick={isSelected ? handleDelete : handleClick}
        >
          {isSelected ? <MinusIcon /> : <PlusIcon fill={theme.palette.blue[500]} />}
        </Button>
      </Tooltip>
    </Box>
  );
};

const CompareText = ({ product, isSelected, handleClick, handleDelete }) => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center">
      <Button
        variant="text"
        onClick={isSelected ? handleDelete : handleClick}
        sx={{ textTransform: 'none', p: 0, minWidth: 'auto' }}
        aria-label={
          isSelected
            ? `Remove the ${product.name} from product comparison.`
            : `Compare the ${product.name} to other similar products.`
        }
      >
        Compare
        <Box
          component="span"
          height={15}
          width={15}
          border="1px solid"
          borderColor="blue.500"
          display="flex"
          alignItems="center"
          justifyContent="center"
          p="2px"
          ml={1}
        >
          {isSelected ? (
            <MinusIcon fill={theme.palette.blue[500]} />
          ) : (
            <PlusIcon fill={theme.palette.blue[500]} />
          )}
        </Box>
      </Button>
    </Box>
  );
};

export default function ProductCompareButton({ product, variant = 'button', alignment = 'left' }) {
  const {
    props: { compare_products: compareProducts = [] },
  } = usePage();

  const { id, categoryId } = product;
  const [ariaLiveStatus, setAriaLiveStatus] = useAriaLiveStatus();

  const handleClick = (e) => {
    e.stopPropagation();
    router.post(
      `/compared_products`,
      { product_id: id },
      {
        preserveScroll: true,
        preserveState: true,
        headers: {
          'X-CSRF-Token': axios.defaults.headers.common['X-CSRF-Token'],
        },
        onSuccess: () => setAriaLiveStatus(`Added ${product.name} to compare`),
      },
    );
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    router.delete(`/compared_products/${id}`, {
      preserveScroll: true,
      preserveState: true,
      headers: {
        'X-CSRF-Token': axios.defaults.headers.common['X-CSRF-Token'],
      },
      onSuccess: () => setAriaLiveStatus(`Removed ${product.name} from compare`),
    });
  };

  const isSelected = compareProducts.find(({ id: product_id }) => product_id === id);
  const isDisabled =
    (compareProducts.length >= 3 && !isSelected) ||
    compareProducts.some(({ categoryId: compareCategoryId }) => categoryId !== compareCategoryId);

  if (isDisabled) return null;

  return (
    <>
      {variant === 'mobile' && (
        <MobileCompareButton
          product={product}
          isSelected={isSelected}
          alignment={alignment}
          handleClick={handleClick}
          handleDelete={handleDelete}
        />
      )}

      {variant === 'button' && (
        <CompareButton
          product={product}
          isSelected={isSelected}
          alignment={alignment}
          handleClick={handleClick}
          handleDelete={handleDelete}
        />
      )}

      {variant === 'text' && (
        <CompareText
          product={product}
          isSelected={isSelected}
          handleClick={handleClick}
          handleDelete={handleDelete}
        />
      )}

      <VisuallyHiddenText aria-live="polite" role="status">
        {ariaLiveStatus}
      </VisuallyHiddenText>
    </>
  );
}
