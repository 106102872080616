import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Link from '../Link';
import NoResults from './NoResults';

function Products({ products, handleLinkClick }) {
  if (!products.length) {
    return <NoResults handleLinkClick={handleLinkClick} />;
  }

  return (
    <>
      {products.map((product) => (
        <Box key={product.id} mb="18px" display="flex" alignItems="center">
          <Box
            height={72}
            width={72}
            flexShrink={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              component="img"
              alt={product.name}
              src={product.imageUrl}
              maxHeight={72}
              maxWidth={72}
              mr={2}
            />
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Link
              href={`/browse?product_id=${product.id}`}
              arrowIcon={false}
              onClick={handleLinkClick}
            >
              <Typography variant="h4" component="span" color="blue.500" fontWeight="medium">
                {product.name}
              </Typography>
            </Link>
            <Typography color="gray.500" component="span">
              {product.modelNumber}
            </Typography>
            <Typography component="span">{product.salePrice}</Typography>
          </Box>
        </Box>
      ))}
    </>
  );
}

Products.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      imageUrl: PropTypes.string,
      salePrice: PropTypes.string,
      modelNumber: PropTypes.string,
    }),
  ),
  handleLinkClick: PropTypes.func,
};

export default Products;
