import { Box, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { Background } from 'react-imgix';
import { CloseIconHighContrast } from '../../assets/icons/icons';
import Button from '../Button';
import MobileCtaLinks from './MobileCtaLinks';
import ResourceDetailsApplicableProducts from './ResourceDetailsApplicableProducts';
import ResourceDetailsHeader from './ResourceDetailsHeader';
import ResourceDetailsLinks from './ResourceDetailsLinks';
import GoGreenCalculator from '../GoGreenCalculator';

function ResourceDetails({ resource, onDrawerClose, onShowProduct }) {
  const { applicableProducts, baseImgixUrl, imgixOpts, financing = false } = resource;

  const theme = useTheme();
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const closeButtonRef = useRef(null);

  // send focus to the close button when the drawer opens
  useEffect(() => {
    if (closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, []);

  return (
    <>
      <Box mb={{ xs: 14, sm: 0 }}>
        <Box
          component={Background}
          src={baseImgixUrl}
          imgixParams={{
            ...imgixOpts,
            h: isXSmallScreen ? 200 : 348,
            dpr: window.devicePixelRatio,
          }}
          sx={{ height: { xs: 200, sm: 348 }, display: 'flex' }}
        >
          <Button
            ref={closeButtonRef}
            onClick={onDrawerClose}
            sx={{ alignSelf: 'flex-start', ml: 'auto', zIndex: 100 }}
            aria-label="Close Resource Details"
          >
            <CloseIconHighContrast />
          </Button>
        </Box>
        <Box p={{ xs: 2, sm: 5.5 }} pt={{ xs: 4, sm: 5.5 }}>
          <ResourceDetailsHeader resource={resource} />
          {financing && <GoGreenCalculator financing={financing} />}
          <Box display={{ xs: 'none', sm: 'block' }}>
            <ResourceDetailsLinks resource={resource} />
          </Box>
        </Box>

        {applicableProducts && !!applicableProducts.length && (
          <Box p={{ xs: 2, sm: 5.5 }} backgroundColor="gray.100">
            <ResourceDetailsApplicableProducts
              onDrawerClose={onDrawerClose}
              resource={resource}
              onShowProduct={onShowProduct}
            />
          </Box>
        )}
      </Box>
      <MobileCtaLinks resource={resource} />
    </>
  );
}

ResourceDetails.propTypes = {
  resource: PropTypes.shape({
    applicableProducts: PropTypes.array,
    baseImgixUrl: PropTypes.string,
    imgixOpts: PropTypes.object,
  }).isRequired,
  onDrawerClose: PropTypes.func.isRequired,
  onShowProduct: PropTypes.func,
};

export default ResourceDetails;
