import { useState } from 'react';
import { router, usePage } from '@inertiajs/react';
import { removeQueryParam, removeQueryParams, replaceQueryParam } from '../utils/urlFunctions';

export default function useDetailsDrawer(propsKey = 'product', queryParamKey = 'product_id') {
  const {
    url,
    props: { [propsKey]: detailItem },
  } = usePage();

  const [isOpen, setIsOpen] = useState(!!detailItem);

  const onOpen = (detailItemId) => {
    return (e) => {
      e.preventDefault();
      setIsOpen(true);

      const newUrl = replaceQueryParam(
        removeQueryParams(url, ['product_id', 'resource_id']),
        queryParamKey,
        detailItemId,
      );
      router.visit(newUrl, {
        preserveScroll: true,
        preserveState: true,
        only: [propsKey, 'title', 'meta'],
      });
    };
  };

  const onClose = () => {
    setIsOpen(false);

    const newUrl = removeQueryParam(url, queryParamKey);
    router.visit(newUrl, {
      preserveScroll: true,
      preserveState: true,
    });
  };

  return {
    isOpen,
    onOpen,
    onClose,
    detailItem,
  };
}
