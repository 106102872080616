import { useState } from 'react';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { ExclamationCircleIcon } from '../assets/icons/icons';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import { usePage, router } from '@inertiajs/react';
import { cloneDeep } from 'lodash';
import useDeepCompareEffect from '../hooks/useDeepCompareEffect';
// import GoGreenProductsTable from './GoGreenProductsTable';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

const goGreenGreen = '#638d3c';

function productMenuStyle(product, prods, theme) {
  return {
    fontWeight:
      prods.indexOf(product) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightBold,
  };
}

function namesToHandles(financeableProducts, names) {
  // iterate names instead of filtering financeableProducts to preserve order
  const products = names.map((name) => {
    return financeableProducts.find((financeableProduct) => name == financeableProduct.name);
  });

  const handles = products.map(({ handle }) => handle);

  return handles;
}

function extractNames(categoryList) {
  return categoryList.map((category) => {
    return category.name;
  });
}

function GoGreenCalculator({ financing }) {
  const {
    rate,
    payment,
    financeableProducts,
    availableTerms,
    selectedProducts,
    financedTotal,
    rawFinancedTotal,
    minimumAlert,
  } = financing;

  const { url } = usePage();
  const theme = useTheme();
  const serverFinancingData = {
    term: rate.term,
    products: extractNames(selectedProducts),
  };
  const showAlert = minimumAlert && selectedProducts.length > 0;

  const [financingData, setFinancingData] = useState(cloneDeep(serverFinancingData));

  useDeepCompareEffect(() => {
    setFinancingData(cloneDeep(serverFinancingData));
  }, [serverFinancingData]);

  const updateUrlWithFinancingParams = (newFinancingData) => {
    const [baseUrl, paramStr] = url.split('?');
    let params = new URLSearchParams(paramStr);

    params.set('financing[term]', newFinancingData.term);
    params.delete('financing[products][]');
    const productHandles = namesToHandles(financeableProducts, newFinancingData.products);
    productHandles.forEach((product) => {
      product && params.append('financing[products][]', product);
    });

    return `${baseUrl}?${params.toString()}`;
  };

  const applyFinancing = (newFinancingData) => {
    const newUrl = updateUrlWithFinancingParams(newFinancingData);
    router.visit(newUrl, {
      preserveScroll: true,
      preserveState: true,
    });
  };

  const handleFinancingChange = (newFinancingData) => {
    setFinancingData(newFinancingData);
    applyFinancing(newFinancingData);
  };

  const handleProductChange = (event) => {
    const financingDataCopy = cloneDeep(financingData);
    const {
      target: { value },
    } = event;
    financingDataCopy.products = value;

    handleFinancingChange(financingDataCopy);
  };

  const handleTermChange = (event) => {
    const financingDataCopy = cloneDeep(financingData);
    const {
      target: { value },
    } = event;

    financingDataCopy.term = value;
    handleFinancingChange(financingDataCopy);
  };

  return (
    <Box backgroundColor="green.100" p={{ xs: 2, sm: 3 }} mb={4}>
      <Typography variant="h3" fontWeight="bold" mb={4}>
        Financing Calculator: Product + Estimated Installation Costs
      </Typography>
      <Grid
        container
        py={1}
        pr={1}
        spacing={1}
        alignItems="stretch"
        bgcolor="#fff"
        sx={{ borderRadius: '4px' }}
      >
        <Grid item xs={12} sm={8}>
          <FormControl fullWidth={true} sx={{ height: '100%' }}>
            <InputLabel id="financing-product-select-label">Products</InputLabel>
            <Select
              id="financing-product-select"
              labelId="financing-product-select-label"
              multiple
              displayEmpty
              value={financingData.products}
              onChange={handleProductChange}
              input={
                <OutlinedInput
                  id="select-multiple-chip"
                  label="Products"
                  fullWidth={true}
                  type="text"
                  sx={{ backgroundColor: '#fff' }}
                />
              }
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
            >
              <MenuItem disabled value="">
                <em>Choose Products</em>
              </MenuItem>
              {financeableProducts.map((category) => (
                <MenuItem key={category.id} value={category.name}>
                  <Typography
                    component="span"
                    style={productMenuStyle(category.name, financingData.products, theme)}
                  >
                    {category.name}{' '}
                  </Typography>
                  <Typography component="span" pl={1} color="gray.500" fontSize="0.75rem">
                    {' '}
                    (Avg: {category.medianTotalCost})
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl variant="outlined" fullWidth={true} sx={{ height: '100%' }}>
            <InputLabel id="financing-term-select-label" sx={{}}>
              Term
            </InputLabel>
            <Select
              labelId="financing-term-select-label"
              id="financing-term-select"
              label="Term"
              onChange={handleTermChange}
              value={financingData.term}
              sx={{ height: '100%', backgroundColor: '#fff' }}
            >
              {availableTerms.map((term) => (
                <MenuItem key={term.id} value={term.term}>
                  {term.term} years
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* term select */}
        </Grid>
      </Grid>

      {showAlert && (
        <Stack pt={2} direction="row" spacing={1} alignItems="center">
          <Box pt="5px">
            <ExclamationCircleIcon
              stroke="#6B0C06"
              aria-label="Financing Calculator Error"
              role="img"
            />
          </Box>
          <Typography variant="h5" component="p" fontWeight="bold" color="#6B0C06">
            Add product(s) to reach the $2,500 loan minimum
          </Typography>
        </Stack>
      )}
      {payment && !minimumAlert && (
        <Grid container pt={4} spacing={1} alignItems="center">
          <Grid item xs={12} sm={8} justifyContent="center">
            <Typography
              variant="h4"
              fontWeight="bold"
              component="p"
              mb={1.5}
              sx={{ marginBottom: 0 }}
            >
              Estimated monthly payment with a {financedTotal} loan
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="h2" component="p" fontWeight="bold" align="right" color="#49682C">
              ~{payment}
            </Typography>
          </Grid>
        </Grid>
      )}

      {/* <GoGreenProductsTable products={selectedProducts} financedTotal={rawFinancedTotal} /> */}

      <Box pt={3}>
        <Typography variant="h6" component="p">
          • Product categories reflect the average cost of a product including average installation
          costs in California. Electrical panel upgrades are not included in installation averages,
          but may be financed.
        </Typography>
        <Typography variant="h6" component="p">
          • Estimated monthly payments are calculated using a rate of {rate.rate}% for a{' '}
          {rate.term * 12}
          -month term loan, based on average rates.
        </Typography>
        <Typography variant="h6" component="p">
          • This is not an offer of a loan, and the interest rates displayed above are estimates
          based on known averages and are not guarantees of specific rates and terms.
        </Typography>
      </Box>
    </Box>
  );
}

GoGreenCalculator.propTypes = {
  financing: PropTypes.shape({
    rate: PropTypes.object,
    payment: PropTypes.string,
    financeableProducts: PropTypes.arrayOf(PropTypes.object),
    selectedProducts: PropTypes.arrayOf(PropTypes.object),
    financedTotal: PropTypes.string,
    rawFinancedTotal: PropTypes.string,
    minimumAlert: PropTypes.bool,
  }),
};

export default GoGreenCalculator;
