import PropTypes from 'prop-types';
import { Box, Chip } from '@mui/material';
import { Head, usePage } from '@inertiajs/react';
import Header from './Header';
import Footer from './Footer';
import ErrorBoundary from '../components/ErrorBoundary';
import FeedbackBanner from '../components/FeedbackBanner';
import EventBanner from '../components/EventBanner/EventBanner';

const Layout = ({ children }) => {
  const {
    props: { deployed_branch_name: deployedBranchName, title, meta = [] },
  } = usePage();

  return (
    <>
      {deployedBranchName && (
        <Box
          sx={{
            position: 'fixed',
            right: '50%',
            bottom: '10px',
            transform: 'translateX(50%)',
            zIndex: 1,
          }}
        >
          <Chip
            label={`Deployed branch: ${deployedBranchName}`}
            size="small"
            sx={{
              backgroundColor: '#6893D4',
            }}
          />
        </Box>
      )}
      <Head>
        {[
          title && <title key={title}>{title}</title>,
          ...meta.map(({ ...attrs }) => <meta key={attrs.name || attrs.property} {...attrs} />),
        ].filter((tag) => tag)}
      </Head>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Header />
        <Box component="main" id="main-content" tabIndex="-1" flexGrow={1}>
          <EventBanner />
          <ErrorBoundary>{children}</ErrorBoundary>
        </Box>
        <Footer />
      </Box>

      <FeedbackBanner />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

// eslint-disable-next-line react/display-name
export default (page) => <Layout>{page}</Layout>;
