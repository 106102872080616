import { Box, Tab, Tabs as MuiTabs, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { RightArrowIcon } from '../assets/icons/icons';
import Button from './Button';

const a11yProps = (index) => ({
  id: `tab-${index}`,
  'aria-controls': `tabpanel-${index}`,
});

function Tabs({
  items,
  activeItemIdx,
  onChange,
  variant = 'fullWidth',
  ariaLabel,
  nextAriaLabel,
  prevAriaLabel,
}) {
  const theme = useTheme();

  const handleLeftArrowClick = () => {
    onChange(activeItemIdx === 0 ? items.length - 1 : activeItemIdx - 1);
  };

  const handleRightArrowClick = () => {
    onChange(activeItemIdx === items.length - 1 ? 0 : activeItemIdx + 1);
  };

  return (
    <Box display="flex">
      <Button
        onClick={handleLeftArrowClick}
        sx={{
          pr: { xs: 0, sm: 2 },
          minWidth: 'auto',
        }}
        aria-label={prevAriaLabel || 'Next tab'}
      >
        <Box sx={{ transform: 'rotate(180deg)' }}>
          <RightArrowIcon fill={theme.palette.common.black} aria-hidden="true" focusable="false" />
        </Box>
      </Button>

      <MuiTabs
        value={activeItemIdx}
        onChange={(_, newValue) => onChange(newValue)}
        variant={variant}
        aria-label={ariaLabel}
        sx={{
          flexGrow: 1,
          '& .MuiTabs-scroller': {
            overflowX: 'scroll !important',
          },
        }}
      >
        {items.map(({ id, handle, name }, idx) => (
          <Tab
            key={id || handle}
            label={name}
            {...a11yProps(idx)}
            sx={{
              minWidth: { xs: 130, sm: 180 },
              fontSize: { xs: '0.875rem', sm: '1rem' },
              py: { xs: 0, md: 1.5 },
              '&:focus-visible': {
                outline: '1px solid',
                outlineColor: 'primary.main',
                outlineOffset: -1,
              },
            }}
          />
        ))}
      </MuiTabs>

      <Button
        onClick={handleRightArrowClick}
        sx={{
          pl: { xs: 0, sm: 2 },
          minWidth: 'auto',
        }}
        aria-label={nextAriaLabel || 'Next tab'}
      >
        <RightArrowIcon fill={theme.palette.common.black} aria-hidden="true" focusable="false" />
      </Button>
    </Box>
  );
}

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string.isRequired,
    }),
  ),
  activeItemIdx: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.string,
  ariaLabel: PropTypes.string.isRequired,
  nextAriaLabel: PropTypes.string,
  prevAriaLabel: PropTypes.string,
};

export default Tabs;
