import { Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DropdownInput from '../../components/DropdownInput';

export default function HomeInputs({
  zipcode,
  setZipcode,
  selectedHomeType,
  onChangeHomeType,
  homeTypes,
}) {
  const [workingZipcode, setWorkingZipcode] = useState(zipcode);

  // update local working state when external state changes
  useEffect(() => {
    setWorkingZipcode(zipcode);
  }, [zipcode]);

  return (
    <Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        mb={{ xs: 2, md: 5 }}
        alignItems={{ xs: 'flex-start', md: 'flex-end' }}
      >
        <Typography variant="h3" fontSize={{ xs: '1rem', sm: '1.5rem' }} fontWeight="bold">
          First, enter a few details about your home
        </Typography>
        <Typography
          variant="h5"
          fontSize={{ xs: '0.75rem', sm: '1rem' }}
          component="span"
          color="gray.500"
          ml={{ xs: 0, md: 2 }}
        >
          We&apos;ll use this to customize your recommendations.
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" flexDirection={{ xs: 'column', md: 'row' }}>
        <TextField
          name="zipcode"
          value={workingZipcode}
          onChange={(e) => setWorkingZipcode(e.target.value)}
          onBlur={() => setZipcode(workingZipcode)}
          label="My Zip Code"
          sx={{ width: { xs: '100%', md: '40%' }, mr: { xs: 0, md: 2.5 }, mb: { xs: 1, md: 0 } }}
        />
        <DropdownInput
          id="home-type"
          label="Home Type"
          ariaLabel="Select Home Type"
          value={selectedHomeType}
          onChange={(e) => onChangeHomeType(e.target.value)}
          options={homeTypes.map(({ handle, name }) => ({ value: handle, children: name }))}
          sx={{
            width: { xs: '100%', md: '40%' },
            borderRadius: '5px',
          }}
        />
      </Box>
    </Box>
  );
}
