import { Grid } from '@mui/material';
import React from 'react';
import Button from '../../components/Button';

const getOfferText = (product) =>
  `${product.offers.length} ${product.offers.length === 1 ? 'offer' : 'offers'} from ${
    product.salePrice
  }`;

export default function OfferButtons({ products, onOpenProductDrawer }) {
  return (
    <Grid container spacing={4} mt={5}>
      <Grid item xs={2} />
      {products.map((product) => (
        <Grid key={product.id} item xs={products.length === 2 ? 4 : 2.66}>
          <Button
            onClick={onOpenProductDrawer(product.id)}
            aria-label={`See details for ${product.name}`}
            sx={{
              width: '100%',
              borderRadius: 0,
              backgroundColor: 'orange.500',
              py: 2,
              color: 'common.black',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              '&:hover, &:focus': {
                backgroundColor: 'orange.700',
              },
            }}
          >
            {getOfferText(product)}
          </Button>
        </Grid>
      ))}
      <Grid item xs={2} />
    </Grid>
  );
}
