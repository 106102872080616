import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { UpArrowIcon } from '../../assets/icons/icons';
import { PRODUCT_CATEGORY_ICONS } from '../../assets/icons/productCategoryIcons';

export default function CategoryGroup({ categoryGroup }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => setIsExpanded((prev) => !prev)}
      sx={{
        borderTop: '1px solid',
        borderColor: 'gray.300',
        boxShadow: 'none',
        '&:last-child': {
          borderBottom: '1px solid',
          borderColor: 'gray.300',
        },
        '&.Mui-expanded': { m: 0 },
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        aria-controls={`panel-${categoryGroup.id}-content`}
        expandIcon={<UpArrowIcon />}
        id={`panel-${categoryGroup.id}-header`}
        sx={{
          pl: 0,
          py: 2.5,
          '&.Mui-focusVisible': {
            backgroundColor: 'common.white',
            outline: '1px solid',
          },
          '& .MuiAccordionSummary-content': {
            m: 0,
            '&.Mui-expanded': {
              m: 0,
            },
          },
        }}
      >
        <Typography fontWeight={500}>{categoryGroup.name}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <Box component="ul" sx={{ p: 0, m: 0, listStyleType: 'none' }}>
          {categoryGroup.categories.map((category) => (
            <Box
              component="li"
              key={category.id}
              sx={{
                py: 3,
                borderBottom: '1px solid',
                borderColor: 'gray.300',
                '&:first-of-type': {
                  pt: 0.5,
                },
                '&:last-child': {
                  borderBottom: 'none',
                },
              }}
            >
              <Link
                href={category.link}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
              >
                <Box height={41} width={41} mr={1.25} aria-hidden="true">
                  {PRODUCT_CATEGORY_ICONS[category.handle]}
                </Box>
                <Typography color="gray.900">{category.name}</Typography>
              </Link>
            </Box>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
