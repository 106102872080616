import PropTypes from 'prop-types';
import { Box, Pagination } from '@mui/material';
import ProductCard from '../../components/ProductCard';
import SortBar from './SortBar';
import useDetailsDrawer from '../../hooks/useDetailsDrawer';
import DetailsDrawer from '../../components/DetailsDrawer';
import { router } from '@inertiajs/react';
import NoResults from '../../components/NoResults';
import GoGreenBanner from '../../components/GoGreenBanner';

function ProductList({ products, category, sortType, sortOptions, pagination, countLabel, goGreen }) {
  const {
    isOpen: isProductDrawerOpen,
    onOpen: onOpenProductDrawer,
    onClose: onCloseProductDrawer,
    detailItem: product,
  } = useDetailsDrawer();

  const {
    isOpen: isResourceDrawerOpen,
    onOpen: onOpenResourceDrawer,
    onClose: onCloseResourceDrawer,
    detailItem: resource,
  } = useDetailsDrawer('resource', 'resource_id');

  const { page, pages, scaffold_url: scaffoldUrl } = pagination;

  const handleNewPage = (page) => {
    const newPageUrl = scaffoldUrl.replace('__pagy_page__', page);
    router.visit(newPageUrl, {
      preserveScroll: false,
      preserveState: true,
    });
  };

  if (!products.length) return <NoResults />;

  const [firstProduct, ...remainderProducts] = products;
  const { showGoGreen = false, props: goGreenProps = {} } = goGreen;

  return (
    <>
      <Box display="flex" flexDirection="column">
        <SortBar
          sortType={sortType}
          sortOptions={sortOptions}
          countLabel={countLabel}
          category={category}
        />

        <Box key={firstProduct.id} mb={2}>
          <ProductCard product={firstProduct} onClick={onOpenProductDrawer(firstProduct.id)} />
        </Box>

        {showGoGreen && (
          <GoGreenBanner onOpenResourceDrawer={onOpenResourceDrawer} {...goGreenProps} />
        )}

        {remainderProducts.map((product) => (
          <Box key={product.id} mb={2}>
            <ProductCard product={product} onClick={onOpenProductDrawer(product.id)} />
          </Box>
        ))}

        <Box display="flex" justifyContent="center" mt={2}>
          <Pagination
            page={page}
            count={pages}
            onChange={(_, num) => handleNewPage(num)}
            color="primary"
            sx={{
              '& .MuiPaginationItem-root:focus-visible': {
                outline: '1px solid #000',
                '&.Mui-selected': {
                  outlineOffset: '1px',
                },
              },
            }}
          />
        </Box>
      </Box>

      <DetailsDrawer
        isOpen={isProductDrawerOpen}
        onClose={onCloseProductDrawer}
        detailItem={product}
        onShowResource={onOpenResourceDrawer}
      />

      {/* second drawer for displaying a featured resource */}
      <DetailsDrawer
        isOpen={isResourceDrawerOpen}
        onClose={onCloseResourceDrawer}
        detailItem={resource}
        isProduct={false}
        onShowProduct={onOpenProductDrawer}
      />
    </>
  );
}

ProductList.propTypes = {
  products: PropTypes.array,
  category: PropTypes.shape({
    name: PropTypes.string,
    hasRebates: PropTypes.bool,
  }),
  pagination: PropTypes.shape({
    page: PropTypes.number,
    pages: PropTypes.number,
    next: PropTypes.number,
    scaffold_url: PropTypes.string,
  }),
  sortType: PropTypes.string,
  sortOptions: PropTypes.array,
  countLabel: PropTypes.string,
  goGreen: PropTypes.shape({
    showGoGreen: PropTypes.bool,
    props: PropTypes.object,
  }),
};

export default ProductList;
