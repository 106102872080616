import { Box, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import ButtonLink from '../../components/ButtonLink';
import Container from '../../layout/Container';
import { addQueryParams } from '../../utils/urlFunctions';
import GoalsInputs from './GoalsInputs';
import HomeInputs from './HomeInputs';
import TechInputs from './TechInputs';

function WizardInput({
  allGoals,
  allTech,
  homeTypes,
  selectedGoals: serverSelectedGoals,
  selectedTech: serverSelectedTech,
  selectedHomeType: serverSelectedHomeType,
  zipcode: serverZipcode,
}) {
  const theme = useTheme();

  const [selectedGoals, setSelectedGoals] = useState(
    serverSelectedGoals.map(({ handle }) => handle) || [],
  );
  const [selectedTech, setSelectedTech] = useState(
    serverSelectedTech.map(({ handle }) => handle) || [],
  );
  const [selectedHomeType, setSelectedHomeType] = useState(serverSelectedHomeType || '');
  const [zipcode, setZipcode] = useState(serverZipcode || '');

  const handleChangeHomeType = (newHomeTypeHandle) => {
    setSelectedHomeType(newHomeTypeHandle);
    const newHomeType =
      homeTypes.find(({ handle }) => handle === newHomeTypeHandle) || homeTypes[0];
    setSelectedTech(newHomeType.selects || []);
  };

  const generateUrlFromInputs = (baseUrl) =>
    addQueryParams(baseUrl, [
      { param: 'goals', value: selectedGoals.join(',') },
      { param: 'tech', value: selectedTech.join(',') },
      { param: 'zip', value: zipcode },
      { param: 'homeType', value: selectedHomeType },
    ]);

  // update current page URL when inputs change, so that the user's choices persist between page refreshes/navigation
  const wizardUrl = generateUrlFromInputs('/my-home');
  useEffect(() => {
    if (window.history) {
      window.history.replaceState(null, '', wizardUrl);
    }
  }, [wizardUrl]);

  return (
    <Box>
      <Container maxWidth="md" sx={{ my: { xs: 4, sm: 10 } }}>
        <Box mb={{ xs: 4, sm: 8 }}>
          <HomeInputs
            zipcode={zipcode}
            setZipcode={setZipcode}
            selectedHomeType={selectedHomeType}
            onChangeHomeType={handleChangeHomeType}
            homeTypes={homeTypes}
          />
        </Box>
        <Box mb={{ xs: 4, sm: 8 }}>
          <GoalsInputs
            goals={allGoals}
            selectedGoals={selectedGoals}
            setSelectedGoals={setSelectedGoals}
          />
        </Box>
        <Box mb={6}>
          <TechInputs
            tech={allTech}
            selectedTech={selectedTech}
            setSelectedTech={setSelectedTech}
          />
        </Box>

        <Box display={{ xs: 'none', md: 'block' }}>
          <ButtonLink
            href={generateUrlFromInputs('/recommendations')}
            variant="primary"
            sx={{ width: 230, px: 4, fontSize: '0.875rem' }}
          >
            Get Recommendations
          </ButtonLink>
        </Box>
      </Container>
      <Box
        display={{ xs: 'flex', md: 'none' }}
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          backgroundColor: 'common.white',
          alignItems: 'center',
          justifyContent: 'center',
          px: 2,
          py: { xs: 3, md: 2 },
          boxShadow: theme.shadows[7],
        }}
      >
        <ButtonLink
          href={generateUrlFromInputs('/recommendations')}
          variant="primary"
          sx={{
            width: { xs: '100%', md: 230 },
            px: 4,
            py: { xs: 1.5, md: 2 },
            fontSize: '0.875rem',
          }}
        >
          Get Recommendations
        </ButtonLink>
      </Box>
    </Box>
  );
}

export default WizardInput;
