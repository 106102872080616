import { Link as InertiaLink } from '@inertiajs/react';
import { Box, Grid, Link, Typography } from '@mui/material';
import ButtonLink from '../components/ButtonLink';
import FooterLink from '../components/FooterLink';
import Container from './Container';
import logo from '../assets/images/logo.svg';

function MobileFooter() {
  const showCookiePrefs = () => {
    window.Optanon && window.Optanon.ToggleInfoDisplay();
    return false;
  };

  return (
    <Box component="footer" py={4} backgroundColor="blue.700" color="common.white">
      <Container>
        <Box>
          <Grid container columnSpacing={2}>
            <Grid item xs={5} mb={4}>
              <InertiaLink href="/">
                <Box
                  component="img"
                  src={logo}
                  alt="PG&amp;E Logo"
                  sx={{ mr: 5 }}
                  height={49}
                  width={49}
                  flexShrink={0}
                />
              </InertiaLink>
            </Grid>

            <Grid item xs={7} mb={4}>
              <ButtonLink
                href="https://forms.office.com/pages/responsepage.aspx?id=GmauRObsqkG8lnwshaCJQWChvp5M-n1BiJf9j4xSRslUOUdSUzVDQlUzM1UxMFkzV1VEREVKUDNUMy4u"
                variant="secondary"
                external
              >
                Feedback
              </ButtonLink>
            </Grid>

            <Grid item xs={5}>
              <Box>
                <FooterLink href="/browse" text="Products" mb={1} variant="h4" />
                <FooterLink href="/resources" text="Resources" mb={1} variant="h4" />
              </Box>
            </Grid>

            <Grid item xs={7}>
              <Box>
                <FooterLink href="/resiliency" text="Prepare for outages" mb={1} variant="h4" />
                <FooterLink
                  external
                  href="https://www.pge.com/en_US/residential/customer-service/help/contact-pge-landing/contact-us.page"
                  text="Customer Support"
                  mb={1}
                  variant="h4"
                />
              </Box>
            </Grid>

            <Grid item xs={5} mt={3}>
              <FooterLink
                href="/privacy-policy"
                text="Privacy Policy"
                color="gray.300"
                variant="body2"
                mr={3}
              />
            </Grid>

            <Grid item xs={7} mt={3}>
              <FooterLink
                href="https://www.pge.com/en_US/about-pge/company-information/disclosure/disclosure.page"
                text="Terms &amp; Conditions"
                color="gray.300"
                variant="body2"
                external
              />
            </Grid>

            <Grid item xs={12} mt={1}>
              <Link
                component="a"
                href="#"
                target="_self"
                color="gray.300"
                id="ot-sdk-btn"
                onClick={showCookiePrefs}
                mr={3}
                sx={{
                  textDecoration: 'none',
                }}
              >
                <Typography variant="body2" component="span" display="block">
                  Do Not Sell My Personal Data
                </Typography>
              </Link>
            </Grid>

            <Grid item xs={12}>
              <Box borderTop="1px solid" borderColor="gray.500" mt={2} pt={2}>
                <FooterLink
                  href="https://www.bellawatt.com/"
                  text="Powered by Bellawatt"
                  external
                />
                <Typography variant="body2" color="gray.300" ml="auto" mt={1}>
                  ©{new Date().getFullYear()} Pacific Gas and Electric Company. All rights reserved.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default MobileFooter;
