import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function ResourceDetailsHeader({ resource }) {
  const { name, longDescription } = resource;

  return (
    <Box>
      <Typography
        id="resource-drawer-header"
        variant="h2"
        fontWeight="bold"
        mb={{ xs: 2, sm: 2.5 }}
      >
        {name}
      </Typography>
      <Typography
        variant="h5"
        component="p"
        mb={{ xs: 0, sm: 2.5 }}
        color="gray.500"
        sx={{ whiteSpace: 'pre-line' }}
      >
        {longDescription}
      </Typography>
    </Box>
  );
}

ResourceDetailsHeader.propTypes = {
  resource: PropTypes.shape({
    name: PropTypes.string,
    longDescription: PropTypes.string,
  }),
};

export default ResourceDetailsHeader;
