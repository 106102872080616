import PropTypes from 'prop-types';
import { Link as InertiaLink } from '@inertiajs/react';
import { Box, Link as MuiLink } from '@mui/material';
import { RightArrowIcon } from '../assets/icons/icons';

const linkAriaProps = {
  'aria-hidden': 'true',
  focusable: 'false',
};

function Link({
  children,
  arrowIcon = true,
  arrowDirection = 'right',
  fullWidth,
  external,
  sx = {},
  innerSx = {},
  ...props
}) {
  return (
    <MuiLink
      component={external ? 'a' : InertiaLink}
      target={external ? '_blank' : '_self'}
      color="blue.500"
      {...props}
      sx={{
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        fontWeight: 'bold',
        '&:hover, &:focus': {
          color: 'blue.600',
        },
        '&:active': {
          color: 'blue.700',
        },
        ...sx,
      }}
    >
      {arrowDirection === 'right' && (
        <Box width={fullWidth ? '100%' : 'auto'} mr={fullWidth ? 'auto' : 2} sx={innerSx}>
          {children}
        </Box>
      )}
      {arrowIcon && (
        <Box sx={{ transform: `rotate(${arrowDirection === 'left' ? '180' : '0'}deg)` }}>
          <RightArrowIcon {...linkAriaProps} />
        </Box>
      )}
      {arrowDirection === 'left' && (
        <Box ml={2} sx={innerSx}>
          {children}
        </Box>
      )}
    </MuiLink>
  );
}

Link.propTypes = {
  arrowIcon: PropTypes.bool,
  arrowDirection: PropTypes.string,
  sx: PropTypes.object,
  innerSx: PropTypes.object,
  external: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
};

export default Link;
