import { Button as MuiButton } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const Button = forwardRef(({ onClick, children, sx = {}, ...props }, ref) => {
  return (
    <MuiButton
      ref={ref}
      onClick={onClick}
      sx={{
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '&:focus-visible': {
          outline: '1px solid',
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </MuiButton>
  );
});

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  sx: PropTypes.object,
  children: PropTypes.node,
};

// needed for arrow function declaration with forwardRef
// https://stackoverflow.com/questions/59716140/using-forwardref-with-proptypes-and-eslint
Button.displayName = 'Button';

export default Button;
