import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { PriceTagIcon, StarIconFilled } from '../assets/icons/icons';
import { formatAsThousands } from '../utils/formatters';
import Button from './Button';
import MobileProductCard from './MobileProductCard';
import PriceRankDollars from './PriceRankDollars';
import ProductBadge from './ProductBadge';
import ProductCompareButton from './Compare/ProductCompareButton';
import VisuallyHiddenText from './VisuallyHiddenText';

function ProductCard({ product, onClick }) {
  const {
    name,
    modelNumber,
    imageUrl,
    badges,
    regularPrice,
    salePrice,
    reviewAverage,
    reviewCount,
    isRebateEligible,
    rebate,
    priceRankNum,
    priceRankText,
    isEnergyStarMostEfficient,
  } = product;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (isSmallScreen) return <MobileProductCard product={product} onClick={onClick} />;

  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        color: 'inherit',
        textAlign: 'initial',
        textTransform: 'none',
        display: 'flex',
        width: '100%',
        border: '1px solid',
        borderColor: 'gray.200',
        transition: 'all 200ms ease',
        position: 'relative',
        '&:hover': {
          boxShadow: 3,
          bgcolor: 'common.white',
        },
      }}
    >
      <ProductCompareButton product={product} />
      <Box
        px={4.5}
        py={2.5}
        bgcolor="common.white"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box width={116} display="flex" justifyContent="center">
          <Box
            component="img"
            src={imageUrl}
            alt=""
            flexShrink={0}
            maxWidth={116}
            maxHeight={116}
            sx={{ objectFit: 'cover' }}
          />
        </Box>
      </Box>
      <Box
        display="flex"
        alignSelf="stretch"
        flexGrow={1}
        py={2.5}
        px={5}
        backgroundColor="common.white"
        borderLeft="1px solid"
        borderColor="gray.200"
        sx={{
          borderTopRightRadius: '4px',
          borderBottomRightRadius: '4px',
        }}
      >
        <Box display="flex" flexGrow={1} flexDirection="column">
          <Typography component="h3" sx={{ flexGrow: 1 }}>
            <Button
              onClick={onClick}
              sx={{
                flexDirection: 'column',
                textAlign: 'left',
                textTransform: 'none',
                alignItems: 'flex-start',
                color: 'gray.900',
                mb: 2,
                p: 0,
              }}
            >
              <Typography
                variant="h5"
                component="span"
                fontSize="1.25rem"
                fontWeight="bold"
                mb={0.75}
              >
                {name}
              </Typography>
              <Typography variant="body1" component="span" fontSize="0.75rem">
                {modelNumber}
              </Typography>
            </Button>
          </Typography>

          <Grid container spacing={1} mt={2}>
            {reviewAverage && (
              <Grid item xs={6}>
                <Typography variant="h6" component="span" display="flex" alignItems="center">
                  {reviewAverage} <StarIconFilled aria-label="stars" />
                  <Typography component="span" variant="h6" ml={0.5}>
                    ({formatAsThousands(reviewCount)} {reviewCount === 1 ? 'review' : 'reviews'})
                  </Typography>
                </Typography>
              </Grid>
            )}

            {isEnergyStarMostEfficient && (
              <Grid item xs={6}>
                <Typography variant="h6" component="span">
                  ENERGY STAR<sup>®</sup> Most Efficient
                </Typography>
              </Grid>
            )}

            <Grid item xs={6} display="flex" alignItems="center">
              <PriceRankDollars priceRank={priceRankNum} variant="h6" />
              <Typography variant="h6" component="span" ml={0.5}>
                {priceRankText} price
              </Typography>
            </Grid>

            {isRebateEligible && (
              <Grid item xs={6} display="flex" alignItems="center">
                <PriceTagIcon />
                <Typography variant="h6" component="span" color="blue.500" ml={0.5}>
                  {rebate ? rebate + ' Rebate' : 'Rebate Eligible'}
                </Typography>
              </Grid>
            )}

            <Grid item xs={6} display="flex" alignItems="center">
              <ProductCompareButton product={product} variant="text" />
            </Grid>
          </Grid>
        </Box>

        <Box display="flex" flexDirection="column" flexShrink={0}>
          <Box flexGrow={1} display="flex" justifyContent="flex-end">
            <Box display="flex" flexDirection="column" mb={1}>
              {!!badges.length &&
                badges.map((badge, idx) => (
                  <Box key={badge} alignSelf="flex-end" mb={idx === badges.length - 1 ? 0 : 0.5}>
                    <ProductBadge text={badge} />
                  </Box>
                ))}
            </Box>
          </Box>
          {!!regularPrice && regularPrice !== salePrice && (
            <>
              <VisuallyHiddenText>Regular price:</VisuallyHiddenText>
              <Typography
                variant="h5"
                component="del"
                color="gray.500"
                sx={{ alignSelf: 'flex-end' }}
              >
                {regularPrice}
              </Typography>
            </>
          )}
          <VisuallyHiddenText>Sale price:</VisuallyHiddenText>
          <Typography variant="h2" component="span" fontWeight="bold" textAlign="right">
            {salePrice}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.number,
    categoryId: PropTypes.number,
    name: PropTypes.string,
    modelNumber: PropTypes.string,
    imageUrl: PropTypes.string,
    badges: PropTypes.arrayOf(PropTypes.string),
    regularPrice: PropTypes.string,
    salePrice: PropTypes.string,
    reviewAverage: PropTypes.number,
    reviewCount: PropTypes.number,
    isRebateEligible: PropTypes.bool,
    rebate: PropTypes.string,
    priceRankNum: PropTypes.number,
    priceRankText: PropTypes.string,
    isEnergyStarMostEfficient: PropTypes.bool,
  }),
  onClick: PropTypes.func,
};

export default ProductCard;
