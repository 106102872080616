import { Box, FormControl, FormControlLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Button from './Button';
import Checkbox from './Checkbox';

function CheckboxFilter({ id, title, items, onChange, truncatable = true }) {
  const [itemsExpanded, setItemsExpanded] = useState(false);

  // if there are 8+ items, only show 6 by default
  // if there are exactly 7, it's better to show all 7 than to hide a single item behind a click
  const shouldTruncateItems = truncatable && items.length > 7;
  const truncatedItems = shouldTruncateItems ? items.slice(0, 6) : items;
  const displayItems = itemsExpanded ? items : truncatedItems;

  return (
    <Box mb={2}>
      <FormControl component="fieldset" sx={{ display: 'flex' }}>
        {title && (
          <legend>
            <Typography component="h3" fontSize="1rem" fontWeight="bold" py={2.5}>
              {title}
            </Typography>
          </legend>
        )}
        {displayItems.map(({ id: itemId, name, checked }) => (
          <FormControlLabel
            key={itemId}
            control={
              <Checkbox
                checked={checked}
                disableRipple
                ariaLabel={name}
                data-testid={itemId}
                onChange={(e) => onChange(id, itemId, e.target.checked)}
              />
            }
            label={name}
            sx={{ color: 'gray.500' }}
          />
        ))}
      </FormControl>
      {shouldTruncateItems && (
        <Button
          onClick={() => setItemsExpanded((prev) => !prev)}
          sx={{
            mt: 0.5,
            fontWeight: 'bold',
            fontSize: '1rem',
            textTransform: 'none',
            color: 'blue.300',
            p: 0,
          }}
        >
          {itemsExpanded ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </Box>
  );
}

CheckboxFilter.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      checked: PropTypes.bool,
    }),
  ),
  onChange: PropTypes.func,
  truncatable: PropTypes.bool,
};

export default CheckboxFilter;
