import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import Link from '../../components/Link';
import ResourceCard from '../../components/ResourceCard';

function TopResources({ resources, onOpenResourceDrawer }) {
  return (
    <>
      <Box
        width="100%"
        display="flex"
        flexDirection={{ xs: 'row', sm: 'column' }}
        overflow={{ xs: 'scroll', sm: 'auto' }}
        mb={{ xs: 2, sm: 0 }}
      >
        {resources.map((resource) => (
          <Box key={resource.id} mb={{ xs: 0, sm: 3 }} mr={{ xs: 2.5, sm: 0 }} minWidth={255}>
            <ResourceCard resource={resource} onClick={onOpenResourceDrawer(resource.id)} />
          </Box>
        ))}
      </Box>

      <Link href="/resources" ml={{ xs: 'none', sm: 'auto' }} fontSize="1.125rem" mt={2}>
        See All Resources
      </Link>
    </>
  );
}

TopResources.propTypes = {
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  onOpenResourceDrawer: PropTypes.func,
};

export default TopResources;
