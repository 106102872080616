import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CondensedProductCard from '../../components/CondensedProductCard';
import Link from '../../components/Link';

function TopProducts({ products, onOpenProductDrawer }) {
  return (
    <>
      <Box width="100%" display="flex" flexDirection="column">
        <Box mt={{ xs: 4, sm: 7 }} mb={{ xs: 4, sm: 5 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            overflow={{ xs: 'scroll', md: 'auto' }}
          >
            {products.map((product) => (
              <Box key={product.id} minWidth={{ xs: 255, md: 'auto' }} flex={1}>
                <CondensedProductCard product={product} onClick={onOpenProductDrawer(product.id)} />
              </Box>
            ))}
          </Box>
        </Box>

        <Link
          href="/products/portable_generators"
          ml={{ xs: 0, sm: 'auto' }}
          alignSelf={{ xs: 'center', sm: 'flex-start' }}
          fontSize="1.125rem"
        >
          Shop All Generators
        </Link>
      </Box>
    </>
  );
}

TopProducts.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  onOpenProductDrawer: PropTypes.func,
};

export default TopProducts;
