import React from 'react';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { router } from '@inertiajs/react';
import * as Pages from '../Pages/';
import axios from 'axios';
import Layout from '../layout/Layout';
import { ThemeProvider, CssBaseline } from '@mui/material';
import theme from '../config/theme';
import { keepPageParams } from '../utils/urlFunctions';
import Honeybadger from '@honeybadger-io/js';
import ReactGA from 'react-ga4';
import Homepage from '../Pages/Homepage';
import '~/styles/application.css';

Honeybadger.configure({
  apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY,
  environment: import.meta.env.VITE_RUBY_MODE,
  revision: 'main',
  debug: false,
});

document.addEventListener('DOMContentLoaded', () => {
  const app = document.getElementById('app');
  const root = createRoot(app);

  ReactGA.initialize('G-HWY998BL67', {
    nonce: document.querySelector('meta[name="csp-nonce"]').content,
  });

  createInertiaApp({
    resolve: (name) => {
      let Page = Pages[name];
      if (Page) {
        Page.layout = Page.layout === undefined ? Layout : Page.layout;
        return Page;
      } else {
        return Homepage;
      }
    },
    setup({ App, props }) {
      root.render(
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App initialPage={JSON.parse(app.dataset.page)} {...props} />
        </ThemeProvider>,
      );
    },
  });

  const csrfToken = document.querySelector('meta[name=csrf-token]')?.content;
  if (csrfToken) {
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken;
  }
});

router.on('navigate', (event) => {
  let newUrl = keepPageParams(event.detail.page.url);
  if (newUrl != window.pgePrevUrl) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      url: event.detail.page.url,
      event: 'pageview',
    });
    var ftGoal = new Image();
    ftGoal.src =
      'https://servedby.flashtalking.com/spot/1/5823;130525;9210/?spotName=PG_E_Marketplace_Homepage_activity&cachebuster=' +
      parseInt(Math.random() * 9e9);
  }
  window.pgePrevUrl = newUrl;
});
