import { usePage } from '@inertiajs/react';
import { Box, Typography } from '@mui/material';
import { times } from 'lodash';
import React, { useEffect, useState } from 'react';
import { CloseIcon } from '../assets/icons/icons';
import useAfterRenderEffect from '../hooks/useAfterRenderEffect';
import useLocalStorage from '../hooks/useLocalStorage';
import Button from './Button';
import DropdownInput from './DropdownInput';
import FeedbackScoreButton from './FeedbackScoreButton';
import ReactGA from 'react-ga4';

const PAGE_VISIT_THRESHOLD = 4;

const getDropdownScoreText = (i) => {
  if (i === 1) return `1 - Not Likely`;
  if (i === 10) return `10 - Very Likely`;
  return `${i}`;
};

export default function FeedbackBanner() {
  const { url } = usePage();
  const [prevUrl, setPrevUrl] = useState(url);

  const [hasDismissedBanner, setHasDismissedBanner] = useLocalStorage('hasDismissedFeedback', '');

  const [pagesVisited, setPagesVisited] = useState(1);
  const [showBanner, setShowBanner] = useState(false);
  const [selectedScore, setSelectedScore] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // show the banner after user visits 4 pages
  useAfterRenderEffect(() => {
    if (!showBanner && !hasDismissedBanner && url !== prevUrl) {
      if (pagesVisited >= PAGE_VISIT_THRESHOLD) {
        setShowBanner(true);
      } else {
        setPrevUrl(url);
        setPagesVisited((prev) => prev + 1);
      }
    }
  }, [url, prevUrl, pagesVisited, showBanner, hasDismissedBanner]);

  const handleCloseBanner = () => {
    setShowBanner(false);
    setHasDismissedBanner('true');
  };

  const handleSubmit = (value) => {
    setSelectedScore(value);
    setIsSubmitted(true);
    ReactGA.event({
      category: 'nps',
      action: 'submit',
      value,
    });
  };

  // hide banner a few seconds after submitting
  useEffect(() => {
    if (isSubmitted && showBanner) {
      const timeout = setTimeout(() => handleCloseBanner(), 2000);
      return () => clearTimeout(timeout);
    }
  }, [isSubmitted, showBanner]);

  if (!showBanner) {
    return null;
  }

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          width: '100%',
          overflow: 'hidden',
          transition: 'height 400ms ease',
        }}
        flexDirection={{ xs: 'column', md: 'row' }}
        height={{ xs: 120, sm: 90, lg: 78 }}
        bgcolor="common.white"
        boxShadow={10}
        display="flex"
        alignItems="center"
        justifyContent={isSubmitted ? 'center' : 'space-between'}
        px={{ xs: 4, xl: 8 }}
        py={1}
      >
        {isSubmitted ? (
          <Typography>Thank you for your feedback!</Typography>
        ) : (
          <>
            <Box flexShrink={1}>
              <Typography
                textAlign={{ xs: 'center', sm: 'left' }}
                color="grey.800"
                fontSize="0.875rem"
                mr={{ xs: 1, lg: 2 }}
                mb={{ xs: 1, lg: 0 }}
              >
                How likely are you to recommend PG&amp;E Energy Action Guide?
              </Typography>
            </Box>
            <Box display={{ xs: 'none', md: 'flex' }} alignItems="center" flexShrink={0}>
              <Typography
                fontSize={{ xs: '0.75rem', lg: '0.875rem' }}
                color="blue.500"
                textTransform="uppercase"
                mr={1}
              >
                Not Likely
              </Typography>
              <Box display="flex" gap="4px">
                {times(10, (i) => (
                  <FeedbackScoreButton
                    key={i}
                    score={i + 1}
                    onChangeScore={handleSubmit}
                    isSelected={selectedScore === i + 1}
                    maxScore={10}
                    lowLabel="Not likely"
                    highLabel="Very likely"
                  />
                ))}
              </Box>
              <Typography
                fontSize={{ xs: '0.75rem', lg: '0.875rem' }}
                color="blue.500"
                textTransform="uppercase"
                ml={1}
                mr={4}
              >
                Very Likely
              </Typography>
            </Box>

            <Box display={{ xs: 'flex', md: 'block' }} alignItems="center" flexShrink={0}>
              {/* dropdown instead of buttons for small screens */}
              <Box display={{ md: 'none' }} mr={2}>
                <DropdownInput
                  id="feedback-dropdown"
                  label=""
                  ariaLabel="Select feedback score (1 to 10)"
                  value={selectedScore === null ? '' : `${selectedScore}`}
                  onChange={(e) => handleSubmit(+e.target.value)}
                  options={times(10, (i) => ({
                    value: `${i + 1}`,
                    children: getDropdownScoreText(i + 1),
                  }))}
                  renderValue={(value) => value.replace(/\D/g, '')}
                  size="small"
                  sx={{
                    width: 75,
                  }}
                />
              </Box>
            </Box>
            <Button
              onClick={handleCloseBanner}
              sx={{ minWidth: 'auto', position: 'absolute', right: 0 }}
              aria-label="Close feedback banner"
            >
              <CloseIcon />
            </Button>
          </>
        )}
      </Box>
    </>
  );
}
