import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import Link from '../../components/Link';
import {
  HomeResiliencyIcon,
  SolarPowerIcon,
  HomeElectrificationIcon,
  ApplyForAssistanceIcon,
  EvChargerIcon,
  RightArrowIcon,
  RebateIcon,
} from '../../assets/icons/icons';

const StartingPoint = ({ url, title, icon, external = false }) => {
  return (
    <Link
      href={url}
      arrowIcon={false}
      external={external}
      fullWidth
      aria-label={`${title} - See More`}
      innerSx={{
        height: '100%',
      }}
    >
      <Box
        display="flex"
        height="100%"
        flexDirection={{ xs: 'row', md: 'column' }}
        alignItems="center"
        mr={{ xs: 0, md: 3 }}
        ml={{ xs: 0, md: 3 }}
        py={{ xs: 1.5, md: 0 }}
      >
        <Box height={{ xs: 40, md: 62 }} width={{ xs: 40, md: 62 }} mr={{ xs: 2.5, md: 0 }}>
          {icon}
        </Box>
        <Typography
          mt={{ xs: 0, md: 2 }}
          mb={{ xs: 0, md: 1 }}
          color="common.black"
          textAlign="center"
          variant="h4"
          fontWeight="bold"
          sx={{ flexGrow: { xs: 0, md: 1 } }}
        >
          {title}
        </Typography>
        <Box display="flex" alignItems="center" ml={{ xs: 'auto', md: 0 }}>
          <Typography
            display={{ xs: 'none', md: 'block' }}
            variant="body1"
            component="span"
            fontWeight="bold"
            mr={2}
            flexShrink={0}
          >
            See More
          </Typography>
          <Box flexShrink={0}>
            <RightArrowIcon />
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

StartingPoint.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
  url: PropTypes.string,
  external: PropTypes.bool,
};

function StartingPoints() {
  const ariaProps = {
    'aria-hidden': 'true',
    focusable: 'false',
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography
        variant="h2"
        fontWeight="bold"
        mb={8}
        textAlign="center"
        display={{ xs: 'none', md: 'block' }}
      >
        Popular starting points for the whole home.
      </Typography>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-evenly"
        width={{ xs: '100%', md: 'auto' }}
      >
        <StartingPoint
          title="Save with rebates"
          url="/recommendations?goals=rebates&tech=battery%2Cthermostat%2Cinduction%2Cheat_cool%2Cwaterheater%2Cgenerator&zip=&homeType="
          icon={<RebateIcon {...ariaProps} />}
        />
        <StartingPoint
          title="Electrify your home"
          url="https://www.pge.com/en/clean-energy/building-electrification.html"
          icon={<HomeElectrificationIcon {...ariaProps} />}
          external
        />
        <StartingPoint
          title="Charge your EV"
          url="/browse/ev_chargers"
          icon={<EvChargerIcon {...ariaProps} />}
        />
        <StartingPoint
          title="Generate solar power"
          url="/resources?filters=themes=generate_solar_power"
          icon={<SolarPowerIcon {...ariaProps} />}
        />
        <StartingPoint
          title="Prepare for outages"
          url="/resiliency"
          icon={<HomeResiliencyIcon {...ariaProps} />}
        />
        <StartingPoint
          title="Financial assistance"
          url="/resources?filters=themes=financial_assistance"
          icon={<ApplyForAssistanceIcon {...ariaProps} />}
        />
      </Box>
    </Box>
  );
}

export default StartingPoints;
