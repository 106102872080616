import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CompareFooter from '../../components/Compare/CompareFooter';
import FilterRail from '../../components/FilterRail';
import Link from '../../components/Link';
import MobileFilters from '../../components/MobileFilters';
import Container from '../../layout/Container';
import ProductList from './ProductList';
import ScrollToTop from '../../components/ScrollToTop';

function Products({
  heading,
  subhead,
  countLabel,
  filters_data: filtersData,
  products,
  category,
  sortType,
  sortOptions,
  pagination,
  backUrl,
  goGreen,
}) {
  return (
    <Box>
      {/* mobile top bar */}
      <Box
        display={{ xs: 'flex', md: 'none' }}
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="gray.200"
        py={2}
        px={{ xs: 2, sm: 3 }}
      >
        <Link
          href="/product-categories"
          arrowDirection="left"
          textTransform="uppercase"
          color="common.black"
        >
          <Typography variant="h4" component="p" fontWeight="bold" fontSize="0.875rem">
            Back
          </Typography>
        </Link>

        <MobileFilters filtersData={filtersData} variant="static" />
      </Box>

      <Container>
        <Box display="flex" flexDirection="column" pt={{ xs: 0, md: 6 }} pb={10}>
          <Box display={{ xs: 'none', md: 'block' }}>
            <Link
              href={backUrl}
              arrowDirection="left"
              textTransform="uppercase"
              color="common.black"
            >
              <Typography variant="h4" fontWeight="bold">
                Back to Top Results
              </Typography>
            </Link>

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={10}
              mt={{ xs: 4, md: 0 }}
            >
              <Typography display="flex" variant="h1" flexDirection="column">
                <Typography variant="h2" component="span" mb={2} textAlign="center">
                  {heading}
                </Typography>
                <Typography variant="h4" component="span" textAlign="center">
                  {subhead}
                </Typography>
              </Typography>
            </Box>
          </Box>

          <Grid container columnSpacing={4} mt={{ xs: 1, md: 0 }}>
            <Grid item xs={12} md={3} display={{ xs: 'none', md: 'flex' }}>
              <FilterRail filtersData={filtersData} />
            </Grid>

            <Grid item xs={12} md={9}>
              <ProductList
                category={category}
                countLabel={countLabel}
                products={products}
                sortType={sortType}
                sortOptions={sortOptions}
                pagination={pagination}
                goGreen={goGreen}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>

      <ScrollToTop />

      <CompareFooter />
    </Box>
  );
}

Products.propTypes = {
  heading: PropTypes.string,
  subhead: PropTypes.string,
  countLabel: PropTypes.string,
  products: PropTypes.array,
  category: PropTypes.shape({
    name: PropTypes.string,
    hasRebates: PropTypes.bool,
  }),
  filters_data: PropTypes.array,
  sortType: PropTypes.string,
  sortOptions: PropTypes.array,
  pagination: PropTypes.object,
  backUrl: PropTypes.string,
  goGreen: PropTypes.shape({
    showGoGreen: PropTypes.bool,
    props: PropTypes.object,
  }),
};

export default Products;
