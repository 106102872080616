import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { RightArrowIcon } from '../assets/icons/icons';

function CollapsibleFilter({ title, borderBottom, children }) {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Box borderTop="1px solid" borderBottom={borderBottom && '1px solid'} borderColor="gray.300">
      <Box
        component="button"
        width="100%"
        display="flex"
        onClick={() => setIsExpanded((prev) => !prev)}
        py={2.5}
        px={0}
        backgroundColor="transparent"
        border="none"
        textAlign="left"
        sx={{ cursor: 'pointer' }}
        aria-label={isExpanded ? `Close ${title} filter` : `Open ${title} filter`}
      >
        <Typography
          variant="h5"
          component="h3"
          fontSize="1rem"
          fontWeight="bold"
          flexGrow={1}
          color="gray.700"
        >
          {title}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          sx={{
            transform: `rotate(${isExpanded ? '90' : '270'}deg)`,
            transition: 'transform 200ms ease',
          }}
          flexShrink={0}
        >
          <RightArrowIcon />
        </Box>
      </Box>

      {isExpanded && children}
    </Box>
  );
}

CollapsibleFilter.propTypes = {
  title: PropTypes.string.isRequired,
  borderBottom: PropTypes.bool,
  children: PropTypes.node,
};

export default CollapsibleFilter;
