import CheckboxFilter from './CheckboxFilter';
import SliderFilter from './SliderFilter';
import PropTypes from 'prop-types';
import CollapsibleFilter from './CollapsibleFilter';

const CollapsibleFilterWrapper = ({ collapsible, title, children }) =>
  collapsible ? <CollapsibleFilter title={title}>{children}</CollapsibleFilter> : children;

CollapsibleFilterWrapper.propTypes = {
  collapsible: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
};

function Filter({
  filterData: {
    id,
    type,
    name,
    min,
    max,
    step,
    useDollars,
    value_min: valueMin,
    value_max: valueMax,
    aria_labels: ariaLabels,
    items,
    collapsible,
  },
  onCheckboxChange,
  onSliderChange,
  truncatable,
}) {
  return (
    <CollapsibleFilterWrapper collapsible={collapsible} title={name}>
      {type === 'slider' && (
        <SliderFilter
          title={collapsible ? '' : name}
          min={min}
          max={max}
          value={[valueMin, valueMax]}
          onChange={(value) => onSliderChange(id, value)}
          step={step}
          useDollars={useDollars}
          ariaLabels={ariaLabels}
        />
      )}

      {type === 'checkbox' && (
        <CheckboxFilter
          id={id}
          title={collapsible ? '' : name}
          items={items}
          onChange={onCheckboxChange}
          truncatable={truncatable}
        />
      )}
    </CollapsibleFilterWrapper>
  );
}

Filter.propTypes = {
  filterData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    useDollars: PropTypes.bool,
    value_min: PropTypes.number,
    value_max: PropTypes.number,
    aria_labels: PropTypes.arrayOf(PropTypes.string),
    items: PropTypes.array,
    collapsible: PropTypes.bool,
  }),
  onCheckboxChange: PropTypes.func,
  onSliderChange: PropTypes.func,
  truncatable: PropTypes.bool,
};

export default Filter;
