import { Box, Typography } from '@mui/material';

function NoGoals() {

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={8}>
      <Typography variant="h3" textAlign="center" mb={3}>
        No energy goals are currently selected
      </Typography>
      <Typography variant="h5">
        Please select a goal to view recommendations.
      </Typography>
    </Box>
  );

}

export default NoGoals;