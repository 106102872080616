import { createTheme } from '@mui/material/styles';
import DINProBold from '~/assets/fonts/DINProBold.ttf';
import DINProMedium from '~/assets/fonts/DINProMedium.ttf';
import DINProRegular from '~/assets/fonts/DINProRegular.ttf';

const ORANGE_100 = '#FEEDC7';
const ORANGE_300 = '#FFD992';
const ORANGE_500 = '#FBBB36';
const ORANGE_700 = '#E59902';
const ORANGE_900 = '#BF7E2A';

const GREEN_100 = '#E3EBD3';
const GREEN_300 = '#CFDBB7';
const GREEN_500 = '#97B068';
const GREEN_700 = '#749D25';

const BLUE_50 = '#F0F1F9';
const BLUE_100 = '#B8D7ED';
const BLUE_300 = '#0089C4';
const BLUE_400 = '#0071BD';
const BLUE_500 = '#07679B';
const BLUE_600 = '#005179';
const BLUE_700 = '#042037';
const BLUE_900 = '#031A2D';

const GRAY_50 = '#F6F6F6';
const GRAY_100 = '#F1F1F1';
const GRAY_200 = '#E0E0E0';
const GRAY_300 = '#CCCCCC';
const GRAY_400 = '#999FAA';
const GRAY_425 = '#818181';
const GRAY_450 = '#6F7585';
const GRAY_500 = '#666666';
const GRAY_700 = '#333333';
const GRAY_900 = '#111111';

const RED_500 = '#F44336';

const defaultTheme = createTheme();
const { breakpoints } = defaultTheme;

const theme = createTheme({
  palette: {
    primary: {
      main: BLUE_500,
    },
    secondary: {
      main: ORANGE_500,
    },
    gray: {
      50: GRAY_50,
      100: GRAY_100,
      200: GRAY_200,
      300: GRAY_300,
      400: GRAY_400,
      425: GRAY_425,
      450: GRAY_450,
      500: GRAY_500,
      700: GRAY_700,
      900: GRAY_900,
    },
    blue: {
      50: BLUE_50,
      100: BLUE_100,
      300: BLUE_300,
      400: BLUE_400,
      500: BLUE_500,
      600: BLUE_600,
      700: BLUE_700,
      900: BLUE_900,
    },
    orange: {
      100: ORANGE_100,
      300: ORANGE_300,
      500: ORANGE_500,
      700: ORANGE_700,
      900: ORANGE_900,
    },
    green: {
      100: GREEN_100,
      300: GREEN_300,
      500: GREEN_500,
      700: GREEN_700,
    },
    red: {
      500: RED_500,
    },
  },
  typography: {
    fontFamily: 'DIN Pro, sans-serif',
    h1: {
      fontSize: '2rem',
      fontFamily: 'DIN Pro',
      fontWeight: 400,
      [breakpoints.up('sm')]: {
        fontSize: '3.75rem',
      },
    },
    h2: {
      fontSize: '1.5rem',
      fontFamily: 'DIN Pro',
      fontWeight: 400,
      [breakpoints.up('sm')]: {
        fontSize: '2.25rem',
      },
    },
    h3: {
      fontSize: '1.125rem',
      fontFamily: 'DIN Pro',
      fontWeight: 400,
      [breakpoints.up('sm')]: {
        fontSize: '1.5rem',
      },
    },
    h4: {
      fontSize: '1rem',
      fontFamily: 'DIN Pro',
      fontWeight: 400,
      [breakpoints.up('sm')]: {
        fontSize: '1.125rem',
      },
    },
    h5: {
      fontSize: '0.875rem',
      fontFamily: 'DIN Pro',
      fontWeight: 400,
      [breakpoints.up('sm')]: {
        fontSize: '1rem',
      },
    },
    h6: {
      fontSize: '0.75rem',
      fontFamily: 'DIN Pro',
      fontWeight: 400,
      [breakpoints.up('sm')]: {
        fontSize: '0.875rem',
      },
    },
    body1: {
      fontFamily: 'DIN Pro',
      fontWeight: 400,
      fontSize: '1rem',
    },
    body2: {
      fontFamily: 'DIN Pro',
      fontWeight: 400,
      fontSize: '0.875rem',
    },
    body3: {
      fontFamily: 'DIN Pro',
      fontWeight: 400,
      fontSize: '0.75rem',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'DIN Pro';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('DIN Pro'), url(${DINProRegular}) format('truetype');
        }
        @font-face {
          font-family: 'DIN Pro';
          font-style: normal;
          font-display: swap;
          font-weight: bold;
          src: local('DIN Pro'), url(${DINProBold}) format('truetype');
        }
        @font-face {
          font-family: 'DIN Pro';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: local('DIN Pro'), url(${DINProMedium}) format('truetype');
        }

        body {
          color: ${GRAY_700}
        }
      `,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&:focus-visible': {
            outlineColor: BLUE_700,
          },
        },
      },
    },
  },
});

export default theme;
