import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function ProductDetailsSavings({ product }) {
  const { estimatedEnergySavings5Years, estimatedOperatingCost5Years } = product;

  return (
    <Box mt={5}>
      {(estimatedEnergySavings5Years || estimatedOperatingCost5Years) && (
        <Typography
          variant="h4"
          component="h3"
          fontWeight="bold"
          mb={2.5}
          display={{ xs: 'none', sm: 'block' }}
        >
          Estimated Savings
        </Typography>
      )}

      <Grid container spacing={2} mb={{ xs: 3, sm: 5 }}>
        {estimatedOperatingCost5Years && (
          <Grid item xs={12} sm={estimatedEnergySavings5Years ? 6 : 12}>
            <Box
              sx={{ backgroundColor: 'gray.100' }}
              py={2}
              px={3}
              height="100%"
              display="flex"
              flexDirection="column"
            >
              <Typography mb={1.125} fontWeight="medium">
                Estimated Operating Costs Over 5 years
              </Typography>
              <Typography variant="h2" component="span">
                {estimatedOperatingCost5Years}
              </Typography>
              <Typography mt={1.125} variant="body3">
                * energy cost to run
              </Typography>
            </Box>
          </Grid>
        )}

        {estimatedEnergySavings5Years && (
          <Grid item xs={12} sm={estimatedOperatingCost5Years ? 6 : 12}>
            <Box
              sx={{ backgroundColor: 'green.100' }}
              py={2}
              px={3}
              height="100%"
              display="flex"
              flexDirection="column"
            >
              <Typography mb={1.125} fontWeight="medium">
                Estimated Energy Cost Savings Over 5 Years
              </Typography>
              <Typography variant="h2" component="span" flexGrow={1}>
                {estimatedEnergySavings5Years}
              </Typography>
              <Typography mt={1.125} variant="body3">
                * compared to avg model of same size + class
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

ProductDetailsSavings.propTypes = {
  product: PropTypes.shape({
    estimatedOperatingCost5Years: PropTypes.string,
    estimatedEnergySavings5Years: PropTypes.string,
  }),
};

export default ProductDetailsSavings;
