import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import ErrorPageLayout from '../../layout/ErrorPageLayout';
import Link from '../../components/Link';
import Button from '../../components/Button';

const StyledLink = ({ href, children, withBorder = true }) => (
  <Link
    href={href}
    arrowIcon={false}
    sx={{
      px: 5,
      mb: { xs: 1.5, sm: 0 },
      borderRight: { xs: 'none', sm: withBorder && `1px solid` },
      borderColor: { xs: 'auto', sm: withBorder && 'gray.300' },
      fontSize: '1.125rem',
      textDecoration: 'none',
      fontWeight: 400,
    }}
  >
    {children}
  </Link>
);

StyledLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  withBorder: PropTypes.bool,
};

function ErrorPage({ error_code: errorCode, error_message: errorMessage }) {
  return (
    <Box
      px={2}
      mt={5}
      mb={7}
      display="flex"
      flexDirection="column"
      alignItems="center"
      maxWidth={800}
    >
      <Typography
        component="h1"
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        mb={5}
      >
        {errorCode && (
          <Typography
            component="span"
            variant="h4"
            fontWeight="bold"
            textTransform="uppercase"
            mb={2}
          >
            {errorCode} error
          </Typography>
        )}
        <Typography component="span" variant="h1" fontWeight="bold">
          {errorMessage}
        </Typography>
      </Typography>

      <Button
        onClick={() => window.history.back()}
        sx={{
          py: 2,
          px: 8,
          mb: 5,
          backgroundColor: 'blue.500',
          color: 'common.white',
          fontWeight: 'bold',
          fontSize: '0.875rem',
          textTransform: 'uppercase',
          borderRadius: 0,
          '&:hover, &:focus': {
            backgroundColor: 'blue.300',
          },
        }}
      >
        Go back
      </Button>

      <Typography component="p" variant="h3" mb={5}>
        Here are some helpful links
      </Typography>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems="center"
        justifyContent="center"
      >
        <StyledLink href="/">Home</StyledLink>
        <StyledLink href="/browse">Browse</StyledLink>
        <StyledLink href="/products">Products</StyledLink>
        <StyledLink href="/resources" withBorder={false}>
          Resources
        </StyledLink>
      </Box>
    </Box>
  );
}

ErrorPage.propTypes = {
  error_code: PropTypes.number,
  error_message: PropTypes.string,
};

ErrorPage.layout = ErrorPageLayout;

export default ErrorPage;
