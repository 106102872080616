import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import times from 'lodash/times';
import VisuallyHiddenText from './VisuallyHiddenText';

function PriceRankDollars({ priceRank, variant = 'h5' }) {
  return (
    <Box>
      <Box aria-hidden="true" display="flex">
        {times(4, (idx) => (
          <Typography
            key={idx}
            component="span"
            fontWeight={priceRank - 1 >= idx ? 'bold' : 'normal'}
            color={priceRank - 1 >= idx ? 'gray.700' : 'gray.450'}
            variant={variant}
          >
            $
          </Typography>
        ))}
      </Box>
      <VisuallyHiddenText>{`${priceRank} out of 4 dollar signs`}</VisuallyHiddenText>
    </Box>
  );
}

PriceRankDollars.propTypes = {
  priceRank: PropTypes.number,
  variant: PropTypes.string,
};

export default PriceRankDollars;
