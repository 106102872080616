import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { Box, Typography, Link as MuiLink, Button as MuiButton } from '@mui/material';
import { Link as InertiaLink, usePage } from '@inertiajs/react';
import logo from '../assets/images/logo.svg';
import SearchBar from '../components/SearchBar';
import SearchModal from '../components/SearchModal/SearchModal';
import { HamburgerIcon, MenuCloseIcon, RightArrowIcon, SearchIcon } from '../assets/icons/icons';
import MobileNavMenu from './MobileNavMenu';
import ProductCategoryMenu from './ProductCategoryMenu';
import Button from '../components/Button';

const Link = ({ href, highlighted, sx = {}, children }) => (
  <MuiLink
    component={InertiaLink}
    href={href}
    aria-current={highlighted ? 'page' : null}
    sx={{
      color: highlighted ? 'orange.500' : 'common.white',
      textDecoration: 'none',
      '& .MuiTypography-root': {
        fontWeight: highlighted && 'bold',
      },
      ...sx,
    }}
  >
    {children}
  </MuiLink>
);

Link.propTypes = {
  highlighted: PropTypes.bool,
  href: PropTypes.string,
  sx: PropTypes.object,
  children: PropTypes.node,
};

function Header() {
  const { url } = usePage();

  const page = url.split('?')[0].split('/')[1];

  const headerRef = useRef(null);
  const modalRef = useRef(null);
  const searchBarRef = useRef(null);
  const searchButtonRef = useRef(null);

  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const [productMenuAnchorEl, setProductMenuAnchorEl] = useState(null);
  const [mobileNavMenuAnchorEl, setMobileNavMenuAnchorEl] = useState(null);

  // close search modal and mobile nav menu on outside click
  useEffect(() => {
    const closeModalOnOutsideClick = (e) => {
      const isModalClick = modalRef.current && modalRef.current.contains(e.target);
      const isSearchBarClick = searchBarRef.current && searchBarRef.current.contains(e.target);
      const isSearchButtonClick =
        searchButtonRef.current && searchButtonRef.current.contains(e.target);

      if (!isModalClick && !isSearchBarClick && !isSearchButtonClick) {
        setSearchModalOpen(false);
      }
    };

    document.addEventListener('click', closeModalOnOutsideClick);

    return () => {
      document.removeEventListener('click', closeModalOnOutsideClick);
    };
  }, []);

  return (
    <Box position="relative" ref={headerRef}>
      <Box component="header" py={2} px={{ xs: 2, sm: 4 }} bgcolor="blue.700" color="common.white">
        <Box component="nav" display="flex" alignItems="center" aria-label="primary">
          <Box
            component="a"
            href="#main-content"
            sx={{
              marginRight: '1rem',
              position: 'fixed',
              transform: 'translateX(-200%)',
              transition: 'transform 0.3s',
              color: 'common.white',
              '&:focus': {
                position: 'static',
                transform: 'translateX(0)',
              },
            }}
          >
            Skip to main content
          </Box>

          <Box component={InertiaLink} href="/" display="flex">
            <Box
              component="img"
              height={49}
              width={49}
              src={logo}
              alt="PG&amp;E Logo - Energy Action Guide Home"
              sx={{ mr: 3 }}
            />
          </Box>
          <Link
            href="/"
            sx={{
              display: { xs: 'none', sm: 'flex' },
            }}
          >
            <Typography
              variant="h4"
              component="p"
              fontWeight="bold"
              fontSize="1.125rem"
              textTransform="uppercase"
              maxWidth="146px"
              mr={4}
            >
              PG&amp;E Energy Action Guide
            </Typography>
          </Link>

          <Box display={{ xs: 'none', md: 'block' }}>
            <SearchBar
              ref={searchBarRef}
              searchModalOpen={searchModalOpen}
              onFocus={() => setSearchModalOpen(true)}
            />
          </Box>

          <MuiButton
            ref={searchButtonRef}
            data-testid="mobile-search-button"
            aria-label="Search products and resources"
            aria-expanded={searchModalOpen ? 'true' : 'false'}
            onClick={() => setSearchModalOpen((prev) => !prev)}
            sx={{
              display: { xs: 'flex', md: 'none' },
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'orange.500',
              borderRadius: '100%',
              p: 1,
              m: 0,
              ml: 'auto',
              minWidth: 'initial',
              border: '1px solid',
              borderColor: 'orange.500',
              '&:hover': {
                bgcolor: 'orange.500',
              },
              '&:focus': {
                borderColor: 'common.white',
              },
            }}
          >
            <SearchIcon />
          </MuiButton>

          <SearchModal
            isOpen={searchModalOpen}
            ref={modalRef}
            onClose={() => setSearchModalOpen(false)}
          />

          <Box
            component="ul"
            ml="auto"
            display={{ xs: 'none', md: 'flex' }}
            alignItems="center"
            sx={{
              listStyle: 'none',
              paddingLeft: 0,
            }}
          >
            <Box component="li" ml={{ xs: 2, lg: 5 }}>
              <Button
                onClick={() => setProductMenuAnchorEl(headerRef.current)}
                aria-controls="product-category-menu"
                aria-label={
                  productMenuAnchorEl ? 'Close product category menu' : 'Open product category menu'
                }
                aria-expanded={productMenuAnchorEl ? 'true' : 'false'}
              >
                <Typography color="common.white">Products</Typography>
                <Box sx={{ transform: 'rotate(90deg)' }} ml={1.5} aria-hidden="true">
                  <RightArrowIcon fill="#FFF" />
                </Box>
              </Button>

              <ProductCategoryMenu
                anchorEl={productMenuAnchorEl}
                open={Boolean(productMenuAnchorEl)}
                onClose={() => setProductMenuAnchorEl(null)}
              />
            </Box>
            <Box component="li" ml={{ xs: 2, lg: 5 }}>
              <Link href="/resources" highlighted={page === 'resources'}>
                <Typography textTransform="uppercase">Resources</Typography>
              </Link>
            </Box>
            <Box component="li" ml={{ xs: 2, lg: 5 }}>
              <Link href="/business" highlighted={page === 'business'}>
                <Typography textTransform="uppercase">Businesses</Typography>
              </Link>
            </Box>
          </Box>

          <Box display={{ xs: 'block', md: 'none' }}>
            <MuiButton
              onClick={() => setMobileNavMenuAnchorEl(headerRef.current)}
              sx={{
                minWidth: 'auto',
                pl: 3,
                '&:focus-visible': {
                  outline: '1px solid',
                  outlineColor: 'primary.main',
                },
              }}
              aria-controls="mobile-nav-menu"
              aria-label={mobileNavMenuAnchorEl ? 'Close nav menu' : 'Open nav menu'}
              aria-expanded={mobileNavMenuAnchorEl ? 'true' : 'false'}
            >
              <Box display={mobileNavMenuAnchorEl ? 'flex' : 'none'} alignItems="center">
                <MenuCloseIcon />
              </Box>
              <Box display={mobileNavMenuAnchorEl ? 'none' : 'flex'} alignItems="center">
                <HamburgerIcon />
              </Box>
            </MuiButton>

            <MobileNavMenu
              open={Boolean(mobileNavMenuAnchorEl)}
              anchorEl={mobileNavMenuAnchorEl}
              onClose={() => setMobileNavMenuAnchorEl(null)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
