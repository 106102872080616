import { Box, Grid, Typography } from '@mui/material';
import Container from '../../layout/Container';
import ProductResults from './ProductResults';
import ResourceResults from './ResourceResults';
import FilterRail from '../../components/FilterRail';
import MobileFilters from '../../components/MobileFilters';
import useDetailsDrawer from '../../hooks/useDetailsDrawer';
import DetailsDrawer from '../../components/DetailsDrawer';
import NoGoals from '../../components/NoGoals';
import { router, usePage } from '@inertiajs/react';
import GoalTabs from './GoalTabs';
import Link from '../../components/Link';
import { replaceQueryParam } from '../../utils/urlFunctions';
import ScrollToTop from '../../components/ScrollToTop';

export default function WizardResults({
  allGoals,
  selectedGoals,
  goal,
  resources,
  productCategories,
  filtersData,
  backUrl,
  isEmailSubmitted,
}) {
  const { url } = usePage();

  const {
    isOpen: isResourceDrawerOpen,
    onOpen: onOpenResourceDrawer,
    onClose: onCloseResourceDrawer,
    detailItem: resource,
  } = useDetailsDrawer('resource', 'resource_id');

  const {
    isOpen: isProductDrawerOpen,
    onOpen: onOpenProductDrawer,
    onClose: onCloseProductDrawer,
    detailItem: product,
  } = useDetailsDrawer();

  const goalsFilterData = {
    id: 'goals',
    name: 'Goals',
    type: 'checkbox',
    collapsible: false,
    items: allGoals.map(({ handle, name }) => ({
      id: handle,
      name,
      checked: !!selectedGoals.find((selectedGoal) => selectedGoal.handle === handle),
    })),
  };

  const handleGoalsFilterChange = (filterId, itemId, checked) => {
    // either add goal ID to query param, or remove it
    const goalsUrlParam = checked
      ? [...selectedGoals.map(({ handle }) => handle), itemId].join(',')
      : selectedGoals
          .map(({ handle }) => handle)
          .filter((handle) => handle !== itemId)
          .join(',');

    const newUrl = replaceQueryParam(
      checked ? replaceQueryParam(url, 'goal', itemId) : url,
      'goals',
      goalsUrlParam,
    );
    router.visit(newUrl, {
      preserveScroll: true,
      preserveState: true,
    });
  };

  const standaloneFiltersData = [
    {
      filterData: goalsFilterData,
      onCheckboxChange: handleGoalsFilterChange,
      truncatable: false,
    },
  ];

  return (
    <>
      {/* mobile top bar */}
      <Box
        display={{ xs: 'flex', md: 'none' }}
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px solid"
        borderColor="gray.200"
        py={2}
        px={{ xs: 2, sm: 3 }}
      >
        <Link href={backUrl} arrowDirection="left" textTransform="uppercase" color="common.black">
          <Typography variant="h4" component="p" fontWeight="bold" fontSize="0.875rem">
            Back
          </Typography>
        </Link>

        <MobileFilters filtersData={filtersData} variant="static" />
      </Box>

      <Container>
        <Box
          pt={{ xs: 4, md: 6 }}
          mb={{ xs: 4, md: 7 }}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Link
            href={backUrl}
            arrowDirection="left"
            textTransform="uppercase"
            color="common.black"
            sx={{ alignSelf: 'flex-start', mb: 4, display: { xs: 'none', md: 'flex' } }}
          >
            <Typography variant="h4" fontWeight="bold">
              Back
            </Typography>
          </Link>
          <Typography component="h1" display="flex" flexDirection="column">
            <Typography
              variant="h4"
              fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
              component="span"
              fontWeight="bold"
              textTransform="uppercase"
              textAlign="center"
              mb={{ xs: 2, md: 2.5 }}
            >
              Top Products & resources
            </Typography>
            <Typography variant="h2" component="span" mb={2} textAlign="center">
              Recommendations tailored to help you reach your top energy goals.
            </Typography>
          </Typography>
          <Typography
            fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
            component="span"
            textAlign="center"
          >
            All products are{' '}
            <Typography
              fontSize={{ xs: '0.875rem', sm: '1.125rem' }}
              component="span"
              fontWeight="bold"
              color="blue.400"
            >
              ENERGY STAR<sup>®</sup>
            </Typography>{' '}
            certified where applicable.
          </Typography>
        </Box>

        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={3} display={{ xs: 'none', md: 'flex' }} sx={{ mt: 1.5 }}>
            <FilterRail filtersData={filtersData} standaloneFiltersData={standaloneFiltersData} />
          </Grid>

          <Grid item xs={12} md={9}>
            {!selectedGoals.length && <NoGoals />}
            {selectedGoals.length > 1 && (
              <Box mb={{ xs: 4, md: 7 }}>
                <GoalTabs goals={selectedGoals} selectedGoal={goal} />
              </Box>
            )}
            <ResourceResults
              resources={resources}
              onOpenResourceDrawer={onOpenResourceDrawer}
              isEmailSubmitted={isEmailSubmitted}
            />
            <Box my={5}>
              <ProductResults
                productCategories={productCategories}
                onOpenProductDrawer={onOpenProductDrawer}
              />
            </Box>
          </Grid>
        </Grid>

        <ScrollToTop />
      </Container>

      <DetailsDrawer
        isOpen={isResourceDrawerOpen}
        onClose={onCloseResourceDrawer}
        detailItem={resource}
        isProduct={false}
        onShowProduct={onOpenProductDrawer}
      />

      <DetailsDrawer
        isOpen={isProductDrawerOpen}
        onClose={onCloseProductDrawer}
        detailItem={product}
        onShowResource={onOpenResourceDrawer}
      />
    </>
  );
}
