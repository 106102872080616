// addQueryParam('/browse', 'category', 'ovens') -> '/browse?category=ovens'
export const addQueryParam = (url, param, value) => {
  const [baseUrl, params] = url.split('?');
  const urlSearchParams = new URLSearchParams(params);
  urlSearchParams.append(param, value);
  return baseUrl + `?${urlSearchParams.toString()}`;
};

// addQueryParams('/browse', [{value: 'category', param: 'ovens'}, {value: 'type', param: 'electric'}])
// -> '/browse?category=ovens&type=electric'
export const addQueryParams = (url, paramValuePairs) => {
  const [baseUrl, params] = url.split('?');
  const urlSearchParams = new URLSearchParams(params);
  paramValuePairs.forEach(({ param, value }) => {
    urlSearchParams.append(param, value);
  });
  return baseUrl + `?${urlSearchParams.toString()}`;
};

// removeQueryParam('/browse?category=ovens', 'category') -> '/browse'
export const removeQueryParam = (url, param) => {
  const [baseUrl, params] = url.split('?');
  const urlSearchParams = new URLSearchParams(params);
  urlSearchParams.delete(param);
  return baseUrl + (urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : '');
};

export const removeQueryParams = (url, removeParams) => {
  const [baseUrl, params] = url.split('?');
  const urlSearchParams = new URLSearchParams(params);
  removeParams.forEach((param) => {
    urlSearchParams.delete(param);
  });
  return baseUrl + (urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : '');
};

// replaceQueryParam('/browse?category=ovens', 'category', 'thermostats') -> '/browse?category=thermostats'
export const replaceQueryParam = (url, param, value) => {
  const [baseUrl, params] = url.split('?');
  const urlSearchParams = new URLSearchParams(params);
  urlSearchParams.set(param, value);
  return baseUrl + `?${urlSearchParams.toString()}`;
};

export const getQueryParam = (url, param) => {
  const params = url.split('?')[1];
  const urlSearchParams = new URLSearchParams(params);
  return urlSearchParams.get(param);
};

export const keepPageParams = (url) => {
  const significantParams = ['page', 'product_id', 'resource_id'];
  const [baseUrl, params] = url.split('?');
  const urlSearchParams = new URLSearchParams(params);
  Array.from(urlSearchParams.entries()).forEach(([key, value]) => {
    if (key == 'page' && value == '1') {
      urlSearchParams.delete(key);
    }
    if (!significantParams.includes(key)) {
      urlSearchParams.delete(key);
    }
  });
  return baseUrl + (urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : '');
};
