import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import ResourceCard from '../ResourceCard';

function ProductDetailsResource({ resource, onDrawerClose, onShowResource }) {
  const handleResourceClick = (e) => {
    // close currently open drawer in favor of a new one to feature the resource
    onDrawerClose();

    const drawerHandler = onShowResource(resource.id);
    drawerHandler(e);
  };

  return (
    <>
      <Box mb={{ xs: 3, sm: 6 }}>
        <ResourceCard resource={resource} onClick={handleResourceClick} condensed />
      </Box>
    </>
  );
}

ProductDetailsResource.propTypes = {
  resource: PropTypes.object,
  onDrawerClose: PropTypes.func,
  onShowResource: PropTypes.func,
};

export default ProductDetailsResource;
