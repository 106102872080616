import { router, usePage } from '@inertiajs/react';
import Tabs from '../../components/Tabs';
import VisuallyHiddenText from '../../components/VisuallyHiddenText';
import useAriaLiveStatus from '../../hooks/useAriaLiveStatus';
import { replaceQueryParam } from '../../utils/urlFunctions';

export default function GoalTabs({ goals, selectedGoal }) {
  const { url } = usePage();

  const [ariaLiveStatus, setAriaLiveStatus] = useAriaLiveStatus();

  const handleChange = (goalIdx) => {
    setAriaLiveStatus(`Showing products and resources for ${goals[goalIdx].name}`);

    const newGoal = (goals[goalIdx] || goals[0]).handle;
    const newUrl = replaceQueryParam(url, 'goal', newGoal);
    router.visit(newUrl, {
      preserveScroll: true,
      preserveState: true,
    });
  };

  return (
    <>
      <VisuallyHiddenText aria-live="polite" role="status">
        {ariaLiveStatus}
      </VisuallyHiddenText>
      <Tabs
        items={goals}
        activeItemIdx={goals.findIndex(({ handle }) => handle === selectedGoal.handle)}
        onChange={handleChange}
        ariaLabel="Customer goals"
        nextAriaLabel="Next goal"
        prevAriaLabel="Previous goals"
      />
    </>
  );
}
