import PropTypes from 'prop-types';
import { Box, Chip } from '@mui/material';
import { Head, usePage } from '@inertiajs/react';
import Footer from './Footer';
import ErrorBoundary from '../components/ErrorBoundary';
import ErrorPageHeader from './ErrorPageHeader';

const ErrorPageLayout = ({ children }) => {
  const {
    props: { deployed_branch_name: deployedBranchName, title, meta = [] },
  } = usePage();

  return (
    <>
      {deployedBranchName && (
        <Box
          sx={{
            position: 'fixed',
            right: '50%',
            bottom: '10px',
            transform: 'translateX(50%)',
            zIndex: 1,
          }}
        >
          <Chip
            label={`Deployed branch: ${deployedBranchName}`}
            size="small"
            sx={{
              backgroundColor: '#6893D4',
            }}
          />
        </Box>
      )}
      <Head>
        {[
          title && <title key={title}>{title}</title>,
          ...meta.map(({ name, content }) => <meta key={name} name={name} content={content} />),
        ].filter((tag) => tag)}
      </Head>

      <Box display="flex" flexDirection="column" minHeight="100vh">
        <ErrorPageHeader />
        <Box
          component="main"
          id="main-content"
          tabIndex="-1"
          flexGrow={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            background: {
              xs: 'auto',
              md: `linear-gradient(-50deg, #f1f1f1 20%, transparent 20%), linear-gradient(50deg, #f1f1f1 20%, transparent 20%)`,
            },
          }}
        >
          <ErrorBoundary>{children}</ErrorBoundary>
        </Box>
        <Footer />
      </Box>
    </>
  );
};

ErrorPageLayout.propTypes = {
  children: PropTypes.node,
};

// eslint-disable-next-line react/display-name
export default (page) => <ErrorPageLayout>{page}</ErrorPageLayout>;
