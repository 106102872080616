import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export default function ToggleGroup({ options, selectedOptions, handleChange }) {
  return (
    <ToggleButtonGroup
      value={selectedOptions}
      onChange={handleChange}
      aria-label="Energy technologies"
      sx={{
        display: 'grid',
        gridTemplateColumns: { xs: 'repeat(2, minmax(0, 1fr))', sm: 'repeat(3, minmax(0, 1fr))' },
        gap: 2.5,
      }}
    >
      {options.map(({ handle, name }) => (
        <ToggleButton
          key={handle}
          value={handle}
          aria-label={name}
          sx={{
            fontSize: { xs: '0.75rem', sm: '0.875rem' },
            borderRadius: 0,
            border: '1px solid',
            borderColor: 'blue.500',
            color: 'blue.500',
            fontWeight: 'bold',
            '&:focus-visible': {
              outline: '1px solid',
              outlineOffset: '2px',
            },
            // get past default styles specificity
            '&.MuiToggleButtonGroup-grouped:not(:last-of-type),&.MuiToggleButtonGroup-grouped:last-of-type':
              {
                borderLeftColor: { xs: 'blue.500', sm: 'blue.500' },
              },
            '&.Mui-selected': {
              backgroundColor: 'blue.500',
              color: 'common.white',
              '&:hover': {
                backgroundColor: 'blue.500',
              },
            },
          }}
        >
          {name}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
