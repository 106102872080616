import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import useDetailsDrawer from '../../hooks/useDetailsDrawer';
import Container from '../../layout/Container';
import TopProducts from './TopProducts';
import TopResources from './TopResources';
import DetailsDrawer from '../../components/DetailsDrawer';

function TopResults({ products, resources }) {
  const {
    isOpen: isResourceDrawerOpen,
    onOpen: onOpenResourceDrawer,
    onClose: onCloseResourceDrawer,
    detailItem: resource,
  } = useDetailsDrawer('resource', 'resource_id');

  const {
    isOpen: isProductDrawerOpen,
    onOpen: onOpenProductDrawer,
    onClose: onCloseProductDrawer,
    detailItem: product,
  } = useDetailsDrawer();

  return (
    <>
      <Box backgroundColor="gray.100">
        <Container>
          <Box py={{ xs: 4, sm: 9 }} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h2" fontWeight="bold" mb={{ xs: 0, sm: 2 }} textAlign="center">
              Be prepared with these top generators
            </Typography>

            <TopProducts products={products} onOpenProductDrawer={onOpenProductDrawer} />

            <Typography
              variant="h2"
              fontWeight="bold"
              mt={{ xs: 4, sm: 10 }}
              mb={{ xs: 4, sm: 7 }}
              textAlign="center"
            >
              Resources that help you stay safe
            </Typography>

            <TopResources resources={resources} onOpenResourceDrawer={onOpenResourceDrawer} />
          </Box>
        </Container>
      </Box>

      <DetailsDrawer
        isOpen={isResourceDrawerOpen}
        onClose={onCloseResourceDrawer}
        detailItem={resource}
        isProduct={false}
        onShowProduct={onOpenProductDrawer}
      />

      <DetailsDrawer
        isOpen={isProductDrawerOpen}
        onClose={onCloseProductDrawer}
        detailItem={product}
        onShowResource={onOpenResourceDrawer}
      />
    </>
  );
}

TopResults.propTypes = {
  products: PropTypes.array,
  resources: PropTypes.array,
};

export default TopResults;
