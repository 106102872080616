import { usePage, router } from '@inertiajs/react';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { replaceQueryParam } from '../../utils/urlFunctions';
import CondensedProductCard from '../CondensedProductCard';

function ProductDetailsSimilarProducts({ product, wrapperRef }) {
  const { similarProducts: similarProducts } = product;
  const { url } = usePage();

  const handleSimilarProductClick = (newProductId) => {
    const newUrl = replaceQueryParam(url, 'product_id', newProductId);

    router.visit(newUrl, {
      preserveScroll: true,
      preserveState: true,
      // scroll to top of drawer element for better UX
      onSuccess: () => wrapperRef.current.scrollIntoView(),
    });
  };

  return (
    <Box>
      <Typography variant="h4" component="h3" fontWeight="bold" mb={2.5}>
        Similar products
      </Typography>
      <Grid container spacing={1}>
        {similarProducts.map((similarProduct) => (
          <Grid item xs={12} sm={6} key={similarProduct.id}>
            <CondensedProductCard
              product={similarProduct}
              onClick={() => handleSimilarProductClick(similarProduct.id)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

ProductDetailsSimilarProducts.propTypes = {
  product: PropTypes.shape({
    similarProducts: PropTypes.array,
  }),
  wrapperRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
};

export default ProductDetailsSimilarProducts;
