import PropTypes from 'prop-types';
import { Link as InertiaLink, usePage } from '@inertiajs/react';
import { Box, Typography } from '@mui/material';
import { getQueryParam } from '../../utils/urlFunctions';

function NoResults({ handleLinkClick }) {
  const { url } = usePage();
  const searchQuery = getQueryParam(url, 'search');

  return (
    <Box mb={3}>
      <Typography variant="h3" mb={1}>
        No results found for &quot;{searchQuery}&quot;
      </Typography>
      <Typography variant="h5">
        Please try a more general term or browse by{' '}
        <InertiaLink href="/products" onClick={handleLinkClick}>
          products
        </InertiaLink>{' '}
        or{' '}
        <InertiaLink href="/resources" onClick={handleLinkClick}>
          resources
        </InertiaLink>
        .
      </Typography>
    </Box>
  );
}

NoResults.propTypes = {
  handleLinkClick: PropTypes.func,
};

export default NoResults;
