import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Button from './Button';
import Imgix from 'react-imgix';
import { RightArrowIcon } from '../assets/icons/icons';

function ResourceCard({ resource, condensed, onClick }) {
  if (!resource) return null;

  const {
    header,
    shortDescription,
    baseImgixUrl,
    imgixOpts,
    resourceCardCta,
    theme,
    resourceTypes,
  } = resource;

  const muiTheme = useTheme();
  const isXSmallScreen = useMediaQuery(muiTheme.breakpoints.down('sm'));
  const knownDimensions = isXSmallScreen
    ? { h: 153, w: 600 - 32, ar: '568:153' }
    : { w: 189, h: 158, ar: '189:158' };
  const mergedParams = { ...imgixOpts, ...knownDimensions, dpr: window.devicePixelRatio };

  return (
    <Box
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        padding: 0,
        color: 'inherit',
        textAlign: 'initial',
        textTransform: 'none',
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        width: '100%',
        height: { xs: '100%', sm: 'auto' },
        border: '1px solid',
        borderColor: 'gray.200',
        transition: 'all 200ms ease',
        outlineOffset: -1,
        '&:hover': {
          boxShadow: 3,
        },
      }}
    >
      <Box
        component={Imgix}
        src={baseImgixUrl}
        sizes="(min-width: 600px) 189px, calc(100vw-32px)"
        imgixParams={mergedParams}
        disableSrcSet={false}
        htmlAttributes={{ alt: '', role: 'presentation', 'aria-hidden': true }}
        flexShrink={0}
        alignSelf="stretch"
        width={isXSmallScreen ? '100%' : 189}
        height={isXSmallScreen ? 153 : 'auto'}
        minHeight={{ sm: '100%' }}
        maxHeight={{ xs: 153, sm: 'none' }}
        sx={{
          objectFit: 'cover',
        }}
      />
      <Box
        display="flex"
        flexGrow={1}
        py={2.5}
        px={{ xs: 2.5, sm: 5 }}
        backgroundColor="common.white"
        alignSelf="stretch"
        borderRadius="4px"
      >
        <Box display="flex" flexGrow={1} flexDirection="column">
          <Box display="flex" flexGrow={1} mb={3}>
            <Box flexGrow={1}>
              <Typography
                variant="body3"
                display={{ xs: 'block', md: condensed ? 'block' : 'none' }}
                component="p"
                textTransform="uppercase"
                mb={1}
              >
                {theme}
              </Typography>

              <Typography component="h3">
                <Button
                  onClick={onClick}
                  aria-label={`${header} - ${shortDescription} (opens details dialog)`}
                  sx={{
                    p: 0,
                    flexDirection: 'column',
                    textAlign: 'left',
                    textTransform: 'none',
                    alignItems: 'flex-start',
                    color: 'gray.900',
                  }}
                >
                  <Typography
                    variant="h5"
                    component="span"
                    fontSize="1rem"
                    fontWeight="bold"
                    mb={{ xs: 1, sm: 0.75 }}
                  >
                    {header}
                  </Typography>
                  <Typography
                    variant="body3"
                    fontSize="0.875rem"
                    lineHeight="20px"
                    component="span"
                  >
                    {shortDescription}
                  </Typography>
                </Button>
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
          >
            <Box display={{ xs: 'none', md: condensed ? 'none' : 'block' }} mr={1}>
              <Typography variant="body3" component="p" textTransform="uppercase">
                {theme}
                <Typography component="span" variant="body2" mx={1} color="gray.300">
                  |
                </Typography>
                {resourceTypes}
              </Typography>
            </Box>

            <Button
              onClick={onClick}
              variant="text"
              aria-label={`${header} - ${resourceCardCta}`}
              sx={{
                fontSize: '1rem',
                fontWeight: 'bold',
                flexShrink: 0,
                p: 0,
                lineHeight: '16px',
                textTransform: 'none',
                justifyContent: 'flex-start',
                width: { xs: '100%', sm: 'auto' },
                ml: { md: condensed ? 0 : 'auto' },
                mt: { xs: 2, sm: 0 },
              }}
            >
              {resourceCardCta}
              <Box ml={1.5}>
                <RightArrowIcon aria-hidden="true" focusable={false} />
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

ResourceCard.propTypes = {
  resource: PropTypes.shape({
    id: PropTypes.number,
    header: PropTypes.string,
    shortDescription: PropTypes.string,
    baseImgixUrl: PropTypes.string,
    imgixOpts: PropTypes.object,
    info: PropTypes.arrayOf(PropTypes.string),
    resourceCardCta: PropTypes.string,
    actionUrl: PropTypes.string,
    theme: PropTypes.string,
    resourceTypes: PropTypes.string,
  }),
  condensed: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default ResourceCard;
